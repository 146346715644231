import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { first } from 'rxjs/operators';
import { ImageService } from '../../../_services/image.service';
import { AlertService, UserService } from '../../../_services';
import { User } from '../../../_models';
import { Image } from '../../../_models/image';
import {AESEncryptDecryptService} from '../../../_services/aesencrypt-decrypt.service';
import { Constants } from '../../../constants/constants';
import {UtilsService} from '../../../_services/utils.service';

@Component({
  selector: 'app-upload-image-dialog',
  templateUrl: './upload-image-dialog.component.html',
  styleUrls: ['./upload-image-dialog.component.scss']
})

export class UploadImageDialogComponent implements OnInit {


  constructor(
    private dialogRef: MatDialogRef<UploadImageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private imageService: ImageService,
    private utilsService: UtilsService,
    private _AESEncryptDecryptService: AESEncryptDecryptService,
    private alertService: AlertService) {
    this.currentUser = this.utilsService.getCurrentUser();
    this.image = new Image();
    this.image.merchantId = this.currentUser.merchantId;
    this.image.isCategory = data.isCategory;
    this.image.cloverDataName = data.name;
    this.image.cloverDataId = data.id;
    this.selectionError = false;
  }

  currentUser: User;
  imageForm: FormGroup;
  submitted = false;
  selectionError = false;
  localMerchantId: any;
  storedMerchantId: string;
  storedCloverToken: any;
  dataType: string;
  errorMsg = '';
  uploadedFileName = '';
  image: Image;
   resizebase64 = require('resize-base64');

   Constants = Constants;
  data: {
    objectType: 'Item' | 'Category',
    objectId: string,
    objectName: string
  };

  // square
  url = '';

  ngOnInit() {
   this.dataType = this.image.isCategory ? 'Category' : 'Item';
  }

  async save() {
    this.submitted = true;
    this.selectionError = false;
    this.errorMsg = '';

    let files;
    if ((<HTMLInputElement>document.getElementById('myFile'))) {
      files = (<HTMLInputElement>document.getElementById('myFile')).files[0];
    }
    const url = (<HTMLInputElement>document.getElementById('image-url')).value;


    if (url.length < 3 && !files) {
      //    this.alertService.error("No image file selected. Click Cancel to exit");
      this.selectionError = true;
      this.errorMsg = 'Image not selected';
      return;
    }

    this.image.imageData = url;

    if (files) {
      await this.getBase64(files).then((data) =>  this.image.imageData = data.toString());
    }

    this.imageService.updateImage(this.image)
      .pipe(first())
      .subscribe(
        data => {
          this.alertService.success('Image Uploaded', true);
        },
        error => {
          this.alertService.error(error);
        });

    this.dialogRef.close({
      returnImage: this.image.imageData
    });
  }

  async getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = err => reject(err);
    });
  }

  async delete() {
    this.submitted = true;
    this.selectionError = false;

    this.image.imageData = '';
    this.imageService.deleteByCloverDataId(this.image.cloverDataId)
      .pipe(first())
      .subscribe(
        data => {
          this.alertService.success('Image Deleted', true);
          this.image.imageData = '';
          this.dialogRef.close({
            image: this.image.imageData
          });
        },
        error => {
          this.alertService.error(error);
        });
  }

   generateImage() {
    this.submitted = true;
    this.imageService.generateAndSaveImage(this.image.cloverDataName, this.image.isCategory, this.image.cloverDataId).subscribe((response: any) => {
      this.alertService.success('Image Generated', true);
      this.dialogRef.close({
        returnImage: response?.imageData
      });
    });
  }
  close() {
    this.dialogRef.close();
  }

  async saveSquare() {
    this.submitted = true;
    this.selectionError = false;
    this.errorMsg = '';

    const files = (<HTMLInputElement>document.getElementById('myFile')).files[0];
    const url = (<HTMLInputElement>document.getElementById('image-url')).value;


    if (files == null && url.length < 3) {
      this.selectionError = true;
      this.errorMsg = 'Image not selected';
      return;
    }

    const image: any = {
      cloverDataId: this.image.cloverDataId ,
      cloverDataName: this.image.cloverDataId ,
      isCategory: this.image.isCategory ,
      imageData: ''
    };
    if (files) {
      await this.getBase64(files).then((data) => {
        image.imageData = data.toString();
      });
    } else {
      image.imageData = url;
    }

    this.imageService.updateImage(image)
        .pipe(first())
        .subscribe(
            () => {
              this.alertService.success('Image Uploaded', true);
            },
            error => {
              this.alertService.error(error);
            });

    this.dialogRef.close({
      returnImage: image.imageData
    });
  }

  async deleteSquare() {
    this.imageService.delete(this.data.objectId).subscribe(() => {
      this.alertService.success('Image Deleted', true);
      this.dialogRef.close({
        returnImage: ''
      });
    });
  }

  setFileName() {
     this.uploadedFileName = (<HTMLInputElement>document.getElementById('myFile')).files[0].name;
  }
}

<!-- <script src="https://cdn.freecodecamp.org/testable-projects-fcc/v1/bundle.js">
</script> -->
<header id="header">
    <div class="logo-toggle-flex">
        <div class="logo">
            <img id="header-img" src="../../../assets/default-logo.png" alt="DineNGo logo" title="DineNGo">
            <!--            <span class="logo-text">A Driven Software Solution</span>-->
        </div>
        <a [matMenuTriggerFor]="menu"
           id="menu-toggle"
           class="menu-toggle"><span class="fas fa-bars fa-lg"></span>
        </a>
    </div>
    <div class="menu-container" id="menu-toggle">
        <!--            <button mat-icon-button [matMenuTriggerFor]="menu" class="menu-button">-->
        <!--                <span class="fas fa-bars fa-lg"></span>-->
        <!--            </button>-->

        <mat-menu #menu="matMenu">
            <button mat-menu-item routerLink="/login" *ngIf="!currentUser" >
                <mat-icon>login</mat-icon>
                <span>Login</span>
            </button>
            <button mat-menu-item  routerLink="/register" *ngIf="!currentUser" >
                <mat-icon>app_registration</mat-icon>
                <span>Register</span>
            </button>
            <button mat-menu-item  routerLink="/sidebar/admin/dashboard" *ngIf="currentUser && currentUser.role.includes('Admin')" >
                <mat-icon>dashboard</mat-icon>
                <span>Dashboard</span>
            </button>
            <button mat-menu-item  routerLink="/select-role" *ngIf="currentUser && currentUser.role.length > 1" >
                <mat-icon>supervisor_account</mat-icon>
                <span>Other Roles</span>
            </button>
        </mat-menu>
    </div>
    <nav class="nav-bar-wrapper" id="nav-bar">
<!--        <a href="#main-menu-toggle"-->
<!--           id="main-menu-close"-->
<!--           class="menu-close"><span class="fas fa-times fa-lg"></span>-->
<!--        </a>-->
        <ul class="menu-wrapper" id="menu-wrapper">
            <li><a routerLink="/register" class="nav-link" *ngIf="!currentUser">Register</a></li>
            <li><a routerLink="/login" class="nav-link login-button" *ngIf="!currentUser">Login</a></li>
            <li><a routerLink="/sidebar/admin/dashboard" class="nav-link login-button" *ngIf="currentUser?.role.includes('Admin')">Dashboard</a></li>
            <li><a routerLink="/select-role" class="nav-link login-button" *ngIf="currentUser?.role.length > 1">Dashboard</a></li>
        </ul>
    </nav>
</header>
<main>

    <!--  CTA  -->
    <section class="cta-img" id="cta-img">
        <div class="cta-banner">
            <div class="cta-wrapper">
                <div #container class="image-carousel-container" [ngStyle]="{width: 400+'px'}">
                    <ng-image-slider #nav [images]="imageObject" [infinite]="true" [manageImageRatio]=false
                                     [autoSlide]="5"
                                     [animationSpeed]="0.1"
                                     [imageSize]="{width: container.style.width, height: container.style.height, space: 0}"
                                     slideImage="1"></ng-image-slider>
                </div>
                <div class="header">Revolutionize your dining experience</div>
                <div class="subHeader">Transform your dining service, enhance efficiency, and increase profits with our
                    innovative solution!
                </div>
                <ul class="item-type">
                    <li class="list-item-main list-item">0% Commission</li>
                    <li class="list-item-main list-item">Marketing & Promotions</li>
                    <li class="list-item-main list-item">Serverless Ordering</li>
<!--                    <li class="list-item-main list-item">Self Fulfilled Delivery</li>-->
                    <li class="list-item-main list-item">Order History and Analytics</li>
                    <li class="list-item-main list-item">Unlimited Kiosks, QR Codes & Table Ordering Devices</li>
                </ul>
                <br>
                <!--                <div href="/appmarket/apps/17Y5YKPG131ZM" target="_blank" rel="noopener noreferrer" class="clover-listing-button"><img src="../../../assets/clover-app-market-button.svg" alt="Install From Clover App Market"/></div>-->
                <a target="_blank" href="https://drivensoftwaresolutions.com/contact/" class="quote">Schedule Demo</a>
            </div><!--   end cta-wrapper     -->
        </div><!--   end cta-banner     -->
    </section>

    <!--  Products  -->
    <section class="products" id="products">
        <br>
        <h2>Features</h2>
        <div class="products-wrap">
            <div class="product-item">
                <img class="beam-img" src="../../../assets/qr-icon.png" width="80" alt="QR Code" title="QR Code">
                <div class="product-description">
                    <div class="product-label">QR Code & Website Ordering</div>
                    <hr class="hr-line">
                    <ul class="item-type">
                        <li class="list-item">0% Commission</li>
                        <li class="list-item">Accept Payment</li>
                        <li class="list-item">Ordering Website</li>
                        <li class="list-item">Self Fulfilled Delivery</li>
                    </ul>
                </div>
                <!--                <a href="javascript:void(0)">More</a>-->
            </div>
            <div class="product-item">
                <img class="bolt-img" src="../../../assets/kiosk-icon2.png" width="80" alt="Bolts" title="Kiosk">
                <div class="product-description">
                    <div class="product-label">Kiosks & Table Ordering</div>
                    <hr class="hr-line">
                    <ul class="item-type">
                        <li class="list-item">Turn Any Device Into A Kiosk</li>
                        <li class="list-item">Enable/Disable Payment From Kiosk</li>
                        <li class="list-item">Games at The Table</li>
                    </ul>
                </div>
                <!--                <a href="javascript:void(0)">More</a>-->
            </div>
            <div class="product-item">
                <img class="bar-img" src="../../../assets/rewards.png" width="80" alt="Bars" title="Promotions">
                <div class="product-description">
                    <div class="product-label">Rewards & Marketing</div>
                    <hr class="hr-line">
                    <ul class="item-type">
                        <li class="list-item">Loyalty Rewards</li>
                        <li class="list-item">Auto Redeem Rewards on Kiosk</li>
                        <li class="list-item">Send Customers Promotional Texts</li>
                    </ul>
                </div>
                <!--                <a href="javascript:void(0)">More</a>-->
            </div>
            <div class="product-item">
                <img class="compass-img" src="../../../assets/managing-icon.png" width="80" alt="Design" title="Design">
                <div class="product-description">
                    <div class="product-label">Manager & Servers</div>
                    <hr class="hr-line">
                    <ul class="item-type">
                        <li class="list-item">Manage Tables From Any Device</li>
                        <li class="list-item">Sales Analytics</li>
                        <li class="list-item">Assign Tables to Servers</li>
                        <li class="list-item">Mult-theme Support</li>
                        <li class="list-item">Manage All Orders, Inventory & more</li>
                    </ul>
                </div>
                <!--                <a href="javascript:void(0)">More</a>-->
            </div>
        </div>
    </section>
    <!--  About  -->
    <section class="about" id="about">
        <div class="about-text">
            <br>
            <h2 class="driven-payments-text">Driven Payments</h2>
            <br>
            <div class="about-wrapper">
                <img src="../../../assets/clover-station-promo.jpg" alt="hard hats">
                <div class="p-text">
                    <div class="helvetica driven-payments-text">Unlock Savings With Driven Payments</div>
                    <br>
                    <div class="helvetica">We Partnered with Driven Payment to offer DineNGo Members Payment Processing
                        at a wholesale rate.
                    </div>
                    <br>
                    <ul class="item-type helvetica">
                        <li class="list-item">Rates as low as 0%</li>
                        <li class="list-item">Cash Discounting (Dual Pricing)</li>
                        <li class="list-item">Free and Low Cost Device Options</li>
                        <li class="list-item">Free Kiosk</li>
                        <li class="list-item">Manage All Orders, Inventory & more</li>
                    </ul>
                    <a target="_blank" class="driven-payments-button" href="https://drivenpayments.com">Learn More</a>
                </div>
                <div class="acc-logo">
                    <img src="https://www.cardconnect.com/content/dam/firstdata/card-connect/en/images/home-page/card-connect-logo.png"
                         alt="SCCBE accreditation">
                    <img src="https://www.paysafe.com/typo3conf/ext/theme/Resources/Public/Template/Main/dist/resources/assets/images/b2b-logos/logo-paysafe.svg">
                    <img src="https://cloverstatic.com/web/apps/cloverdotcom/release::v2.114.0-36fe5f40454a1/assets/media/clover-logo.5637c88fda21055b797e300e16140c95.svg"
                         alt="ISO accreditation">
                    <img src="https://www.fiserv.com/content/dam/fiserv-ent/final-files/digital-graphics/fiserv-com-graphics/fiserv-logo-orange-ff6600.svg"
                         alt="ISO accreditation">
                </div>
            </div>
        </div>
    </section>
    <!-- Clients-video   -->
    <section class="clients-video" id="clients-video">
        <div class="customers">
            <br><br>
            <h3>Our Customers</h3>
            <div class="client-logo-wrapper">
                <img class="partner-logo-img"
                     src="https://drivenpayments.com/wp-content/uploads/2024/08/sultana-logo.webp" width="130">
                <img class="partner-logo-img"
                     src="https://drivenpayments.com/wp-content/uploads/2024/08/snowflake-logo-1.webp" width="130">
                <img class="partner-logo-img"
                     src="https://drivenpayments.com/wp-content/uploads/2024/08/murrays-logoi.webp" width="130">
                <img class="partner-logo-img"
                     src="https://drivenpayments.com/wp-content/uploads/2024/08/mazdio-logo.webp" width="130">
                <!--                <img src="https://drivenpayments.com/wp-content/uploads/2024/08/tamaasha-logo.webp" width="130">-->
            </div>
        </div>
        <div class="quote-wrapper" *ngIf="selectedTestimonial">
            <blockquote>
                <div class="testimonial-text">{{selectedTestimonial.testimonial}}</div>
            </blockquote>
            <cite>
                -{{selectedTestimonial.name}}
            </cite>
        </div>
    </section>

    <!--  Contact  -->
    <section class="contact" id="contact">
        <div class="contact-wrapper">
            <div>
                <br>
                <div class="client-logo-wrapper">
                    <a class="doc-button" target="_blank" href="https://drivensoftwaresolutions.com/docs/">View Documentation</a>
                </div>
            </div>
            <div class="quote-wrapper">
                <a class="contact-button" target="_blank" href="https://drivensoftwaresolutions.com/contact/">Contact Us</a>
            </div>
        </div><!--  end contact-wrapper  -->
    </section>

</main>

<footer>
    <div id="footer">© 2024 Copyright: <a target="_blank" href="https://drivensoftwaresolutions.com" style="text-transform: none;color: white">Driven Software Solutions</a> || A Driven Software Solution</div>
</footer>

<div class="parent">
    <form class="element text-center border border-light p-5">
        <div class="login-items-container">

            <img class="i1"
                 src="{{currentUser.orgSettings?.image ? currentUser.orgSettings.image : './assets/default-logo.png' }}"
                 alt="logo"/>
            <div class="info-label">Select A Role To Continue:</div>
            <mat-form-field>
                <mat-select name="roleSelect" placeholder="Select Role" [(ngModel)]="roleSelect"
                            (selectionChange)="getSelectedRole($event.value)">
                    <mat-option *ngFor="let item of items" [value]="item">{{item}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </form>
</div>

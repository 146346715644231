import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {User} from '../_models';
import {environment} from '../../environments/environment';
import {QRModel} from '../_models/qrDevice';
import {OrgSetting} from '../_models/orgSetting';
import {Reward} from '../_models/reward';
import {TakePayment} from '../_models/takePayment';
import {AESEncryptDecryptService} from './aesencrypt-decrypt.service';
import { Constants } from '../constants/constants';

@Injectable({
  providedIn: 'root'
})
export class QrDeviceService {
  constructor(private http: HttpClient, private _AESEncryptDecryptService: AESEncryptDecryptService) { }

  register(user: QRModel) {
    return this.http.post<QRModel>(`${environment.apiUrl}/qrDevices/save`, user);
  }

  getOrCreateQrDevice(user: QRModel, altHash) {
    return this.http.post<QRModel>(`${environment.apiUrl}/qrDevices/getOrCreateQrDevice`, {user, altHash});
  }

  getOrCreateJukeboxLogin(user: QRModel, altHash) {
    return this.http.post<QRModel>(`${environment.apiUrl}/qrDevices/getOrCreateJukeboxLogin`, {user, altHash});
  }

  updateQRDevice(currentUser: User) {
    const paymentRequestUser = new User();
    paymentRequestUser.token = currentUser.token;
    paymentRequestUser.firstName = currentUser.firstName;
    paymentRequestUser.role = [Constants.QR_ORDER];
    paymentRequestUser.orgSettings = new OrgSetting();
    paymentRequestUser.orgSettings.rewards = new Reward();
    paymentRequestUser.orgSettings.rewards.rewardsEnabled = false;
    paymentRequestUser.orgSettings.orgUuid = currentUser.orgSettings.orgUuid;
    paymentRequestUser.merchantId = currentUser.orgSettings.merchantId;

    const paymentSettings = new TakePayment();
    paymentRequestUser.takePayment = paymentSettings;
    paymentRequestUser.takePayment.paymentRequired = true;
    paymentRequestUser.takePayment.devicePaymentEnabled = true;
    paymentRequestUser.takePayment.creditCardPayment = true;

    const qrData = new QRModel();
    qrData.username = currentUser.username;
    qrData.orgUuid = currentUser.orgSettings.orgUuid;
    qrData.attachedUserId = currentUser._id;
    return this.http.post<QRModel>(`${environment.apiUrl}/qrDevices/save`, qrData);
  }

  getByUsername(username) {
    return this.http.post(`${environment.apiUrl}/qrDevices/getByUsername`, {username: username});
  }

  delete(id: string) {
    return this.http.delete(`${environment.apiUrl}/qrDevices/${id}`);
  }
}

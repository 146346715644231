import {Item} from '../_models/Item';
import {Modifier} from '../_models/modifier';
import * as uuid from 'uuid';

export class CartItem {
  uid: string;
  item: Item;
  selectedModifiers: Modifier[];
  note: string;
  quantity: number;

  constructor(item: Item, selectedModifiers: Modifier[], note: string, quantity: number) {
    this.uid = uuid.v4();
    this.item = item;
    this.selectedModifiers = selectedModifiers;
    this.note = note;
    this.quantity = quantity;
  }
}

<!--<h2 mat-dialog-title  class="title-header">{{description}}</h2>-->
<mat-dialog-content>
    <div class="dialog-options-container">
        <mat-button-toggle-group [(ngModel)]="popupType" aria-label="Font Style">
            <mat-button-toggle value="link">Manual Login</mat-button-toggle>
            <mat-button-toggle value="qr">Login with QR Code</mat-button-toggle>
        </mat-button-toggle-group>
<!--      <p>Chosen value is {{popupType}}</p>-->
        <div class="error-message">{{errorMessage}}</div>
    </div>
    <div class="text-container-qr" *ngIf="popupType === 'qr'">
        <h1 class="header-title">Instructions</h1>
        <p>To Login: Scan the QR code with the device you would like to login.</p><br>
        <app-qr-generator [height]="200" [width]="200" [qrUrl]="siteAutoLoginURL" [title]="('orderConfirmation.paymentQrTitle' | translate)"></app-qr-generator>
    </div>
    <div class="text-container" *ngIf="popupType === 'link'">
        <h1 class="header-title">Jukebox Manual Login</h1>
        <div class="creds-container">
            <div class="general-padding">Auto login URL: <span class="link creds" (click)="goToLink(siteAutoLoginURL)">{{siteAutoLoginURL}}</span></div>
            <div class="general-padding">URL: <span class="link creds" (click)="goToLink(siteURL)">{{siteURL}}</span></div>
            <div class="general-padding">Username: <span class="creds">{{tableUsername}}</span> </div>
            <div class="general-padding">One Time Password: <span class="creds"> &nbsp; {{tablePassword}} &nbsp; &nbsp; <b style="font-weight: bold;">OR</b>  &nbsp; &nbsp; Admin's Password</span>
                &nbsp; &nbsp;
                <a class="password-reset" *ngIf="false" alt="password Reset"><mat-icon mat-icon class="delete-icon">lock_reset</mat-icon></a>
            </div>
        </div>
    </div>
</mat-dialog-content>
  <div class="button-container">
    <button mdbBtn [disabled]="loading"  *ngIf="popupType !== 'settings'"  color="info" block="true" class="my-4 action-button" (click)="submit()">{{button_text}}</button>
      <button mdbBtn [disabled]="loading"  *ngIf="popupType === 'settings'" block="true" class="my-4 action-button orange-button" (click)="getOrCreateTableLogin(true)">Update QR</button>

  </div>

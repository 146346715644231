import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SharedModule} from '../shared/shared.module';
import {RouterModule, Routes} from '@angular/router';
import {CloverAuthRouterComponent} from './clover-auth-router.component';
import {SquareAuthRouterComponent} from "./square-auth-router/square-auth-router.component";
import {UberAuthRouterComponent} from "./uber-auth-router/uber-auth-router.component";

const routes: Routes = [
  {
    path: 'cloverAuth',
    component: CloverAuthRouterComponent
  },
  {
    path: 'squareAuth',
    component: SquareAuthRouterComponent
  },
  {
    path: 'uberAuth',
    component: UberAuthRouterComponent
  }
];

@NgModule({
  declarations: [CloverAuthRouterComponent, SquareAuthRouterComponent,UberAuthRouterComponent],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(routes)
  ],
  exports: [CloverAuthRouterComponent, SquareAuthRouterComponent, UberAuthRouterComponent]
})
export class CloverAuthRouterModule { }

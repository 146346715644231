<div class="parent">
    <form class="element text-center border border-light p-5">
        <div class="login-items-container">
            <img class="i1"
                 src="{{currentUser.orgSettings?.image ? currentUser.orgSettings.image : './assets/default-logo.png' }}"
                 alt="logo"/>
            <div class="form-field-container">
                <div class="dialog-options-container">
                    <div class="select-table-text">Select A Table To Assign</div>

                    <mat-form-field>
                        <mat-select name="roleSelect" placeholder="Select Table"
                                    (selectionChange)="onIncludeTableSelect($event.value)">
                            <mat-optgroup [label]="'MAIN DINING'">
                                <mat-option *ngFor="let table of orgTables" [value]="table.name">{{table.name}}
                                </mat-option>
                            </mat-optgroup>
                            <mat-optgroup *ngFor="let layout of currentUser.orgSettings.additionalLayouts | keyvalue"
                                          [label]="layout.key">
                                <mat-option *ngFor="let table of layout.value"
                                            [value]="table.name">{{table.name.split(layout.key + ' - ')[1].toLocaleLowerCase()}}
                                </mat-option>
                            </mat-optgroup>
                        </mat-select>
                    </mat-form-field>
                    <!--        <ng-multiselect-dropdown-->
                    <!--                [placeholder]="'Select Table'"-->
                    <!--                [settings]="tablesDropdownSettings"-->
                    <!--                [data]="orgTables"-->
                    <!--                (onSelect)="onIncludeTableSelect($event)">-->
                    <!--        </ng-multiselect-dropdown>-->
                </div>
            </div>
        </div>
    </form>
</div>

<h2 mat-dialog-title>{{description}}</h2>
<mat-dialog-content>
  <div class="dialog-options-container">
    <p>{{message}}</p>
    <img class="notification-image" src="{{imgSrc}}"/>
    <div style="padding: 12px 0; font-weight: bold">Sending Payment to Device <span *ngIf="false" style="color: #F88D5E; font-weight: bold">{{time}}</span></div>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <div class="button-container">
    <button mdbBtn *ngIf="time === 0" [disabled]="retryPaymentDisabled" color="info" block="true" class="my-4 right-margin-set" (click)="resendPaymentToDevice()">{{ getTimerValue()}}</button>
    <button mdbBtn *ngIf="selectedRole === Constants.KIOSK" color="danger" block="true" class="my-4" (click)="closeAndCancelOrder()">Cancel Order</button>
    <button mdbBtn *ngIf="selectedRole !== Constants.KIOSK" color="danger" block="true" class="my-4" (click)="closeAndCancelOrder()">Cancel</button>
  </div>
</mat-dialog-actions>

import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {first} from 'rxjs/operators';
import {AlertService, UserService} from '../../_services';
import {Constants} from 'src/app/constants/constants';


@Component({
    selector: 'app-register-menu',
    templateUrl: 'register.component.html',
    styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private userService: UserService,
        private alertService: AlertService) {
    }

    get f() {
        return this.registerForm.controls;
    }

    registerForm: FormGroup;
    displayForm: FormGroup;
    loading = false;
    submitted = false;
    uniqueOrgIdent = '';
    profileImage: string;
    uniqueOrgError = false;

    // convenience getter for easy access to paymentForm fields
    platform = Constants.CLOVER;

    ngOnInit() {
        this.registerForm = this.formBuilder.group({
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            organization: ['', Validators.required],
            uniqueOrgIdent: ['', Validators.required],
            platform: [Constants.CLOVER, Validators.required],
            phoneNumber: ['', Validators.required],
            username: ['', [Validators.required, Validators.pattern('(?:[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])')]],
            email: ['', [Validators.required, Validators.pattern('(?:[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])')]],
            password: ['', [Validators.required, Validators.minLength(6)]],
            imageData: ['']
        });
        this.displayForm = this.formBuilder.group({
            uoi: ['']
        });
    }

    async getImageUrl() {
        const files = (<HTMLInputElement>document.getElementById('myFile')).files[0];
        await this.getBase64(files).then((data) => {
            this.registerForm.controls['imageData'].setValue(data.toString());
            this.profileImage = data.toString();
        });
    }

    async onSubmit() {
        this.submitted = true;

        this.registerForm.controls['email'].setValue(this.registerForm.controls['username'].value.toLowerCase());
        this.registerForm.controls['uniqueOrgIdent'].setValue(this.registerForm.controls['uniqueOrgIdent'].value.toLowerCase());

        this.registerForm.controls['platform'].setValue(this.platform);


        if (!this.uniqueOrgIdent || this.uniqueOrgIdent?.length <= 5) {
            this.uniqueOrgError = true;
        }

        // stop here if paymentForm is invalid
        if (this.registerForm.invalid) {
            return;
        }

        const files = (<HTMLInputElement>document.getElementById('myFile')).files[0];

        if (files) {
            await this.getBase64(files).then((data) => this.registerForm.controls['imageData'].setValue(data.toString()));
        }
        this.loading = true;
        this.userService.accountRegistration(this.registerForm.value)
            .pipe(first())
            .subscribe(
                data => {
                    this.alertService.success('Registration successful', true);
                    this.router.navigate(['/login']);
                },
                error => {
                    // console.log('here '+error);
                    this.alertService.error(error);
                    this.loading = false;
                });
    }

    uniqueOrgChange() {
        this.uniqueOrgError = false;
        const newString = this.uniqueOrgIdent.replace('\@', '');
        const baseString = newString.replace('\.com', '');
        this.uniqueOrgIdent = '@' + baseString + '.com';
        if (baseString.length > 0) {
            this.registerForm.controls['uniqueOrgIdent'].setValue(this.uniqueOrgIdent);
            this.displayForm.controls['uoi'].setValue('kiosk@' + this.uniqueOrgIdent);
        } else {
            this.registerForm.controls['uniqueOrgIdent'].setValue('');
            this.displayForm.controls['uoi'].setValue('');
        }
    }

    onlyAlphabets(e) {
        const charCode = e?.which;

        if (!e) {
            return true;
        }

        if ((charCode > 64 && charCode < 91) || (charCode > 96 && charCode < 123) || charCode === 8 || charCode === 42) {
            return true;
        } else {
            return false;
        }

    }

    async getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = err => reject(err);
        });
    }
}

import { Modification } from './modification';
import {LineItemReferenceForItem} from './lineItemReferenceForItem';
import {Category} from "./category";
import {SquareMoney} from "./squareMoney";
import {LineItemModifier} from "./lineItemModifier";
import {CartItem} from "./cartItem";

export class LineItem {

  id: string;
  cartId: string;     // used while deleting items in cart
  item: LineItemReferenceForItem;  //references the item id
  name: string;
  alternateName: string;
  price: number;  // if not empty its price of a single unit
  priceWithModifiers: number; // used for reporting
  priceWithModifiersAndItemAndOrderDiscounts: number; //Price of item after adding all modifications and subtracting all line item and order level discounts. This is used only for reporting.
  quantity: number; //Unit quantity if this line item is priced by quantity, or null if the item is not priced by quantity.
  unitName: string; //eg oz, lb etc or null
  itemCode: string;
  note: string;
  discounts: any[];
  printed: boolean; //True if this line item has been printed out on an order printer at least once already.
  createdTime: number;
  orderClientCreatedTime: number;
  categories: Category[];

 // discounts: any[]; //list of line items discounts
  modifications:   Modification[] ;
  image: string;
  orderRef:any;
  newModifications: Modification[];

  //Square Properties
  uid: string;
  catalog_object_id: string;
  item_type: string;
  variation_name: string;
  base_price_money: SquareMoney;
  modifiers: LineItemModifier[];
  gross_sales_money: SquareMoney;
  total_tax_money: SquareMoney;
  total_money: SquareMoney;
  metadata: { [key: string]: string };
  isTaxable: boolean;


  constructor(cartItem?: CartItem) {
    this.uid = cartItem?.uid;
    this.catalog_object_id = cartItem?.item.variationId;
    this.quantity = cartItem?.quantity;
    this.note = cartItem?.note;
    this.modifiers = [];
    cartItem?.selectedModifiers.forEach(mod => this.modifiers.push(new LineItemModifier(mod)));
  }
}

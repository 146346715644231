import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {Rating} from "../_models/rating";

@Injectable({ providedIn: 'root' })
export class RatingService {

  ratings: Observable<Rating[]>;

  constructor(private http: HttpClient) { }

  getAll() {
      const response = this.http.get<Rating[]>(`${environment.apiUrl}/ratings/getAll`);
      this.ratings = response;
      return response;
  }

  getByOrgUuId(orgUuid: string) {// TODO
    return this.http.get(`${environment.apiUrl}/ratings/getByOrgAUuid/${orgUuid}`);
  }

  addRating(rating: Rating) {
      return this.http.post(`${environment.apiUrl}/ratings/create`, rating);
  }

  delete(id: string) {
    return this.http.delete(`${environment.apiUrl}/rating/${id}`);
  }
}


<h2 mat-dialog-title class="title">{{description}}</h2>
<mat-dialog-content>
  <div class="dialog-options-container">
    <app-qr-generator [height]="250" [width]="250" [qrUrl]="qrUrl"></app-qr-generator>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end" *ngIf="currentUser.orgSettings.accountType === Constants.CLOVER">
  <div class="button-container">
    <button *ngIf="false" [disabled]="buttonDisabled" mdbBtn color="info" block="true" class="my-4 example-full-width" (click)="startOver()">Start Over<i class="material-icons">restart_alt</i></button>
    <button [disabled]="buttonDisabled" mdbBtn color="danger" block="true" class="my-4 example-full-width edit-order-button" (click)="closeAndCancelOrder()">Cancel & Return To Cart</button></div>
</mat-dialog-actions>
<mat-dialog-actions align="end" *ngIf="currentUser.orgSettings.accountType === Constants.SQUARE">
  <div class="button-container">
    <button *ngIf="selectedRole !== 'Admin' && selectedRole !== 'Manager' && selectedRole !== 'Server'" [disabled]="buttonDisabled" mdbBtn color="danger" block="true" class="my-4 example-full-width" (click)="closeAndCancelOrderSquare()">Cancel Order</button>
    <button *ngIf="selectedRole === 'Admin' || selectedRole === 'Manager' || selectedRole === 'Server'" [disabled]="buttonDisabled" mdbBtn color="danger" block="true" class="my-4 example-full-width" (click)="closeAndCancelOrderSquare()">Cancel Order</button>
  </div>
</mat-dialog-actions>

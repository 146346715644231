import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {IDropdownSettings} from 'ng-multiselect-dropdown';
import {Item} from 'src/app/_models/Item';
import {CloverApiService} from '../../../_services/cloverApi.service';
import {SquareApiService} from '../../../_services/square-api.service';


@Component({
    selector: 'app-notification-popup',
    templateUrl: './select-delivery-fee-item-popup.html',
    styleUrls: ['./select-delivery-fee-item-popup.scss']
})
export class SelectDeliveryFeeItemPopupComponent implements OnInit {

    description: string;
    message: string;
    items: Item[] = [];
    deliveryFeeItem: Item;
    tablesDropdownSettings: IDropdownSettings = {
        singleSelection: true,
        idField: '_id',
        textField: 'name',
        itemsShowLimit: 3,
        allowSearchFilter: true,
        enableCheckAll: false
    };

    constructor(private dialogRef: MatDialogRef<SelectDeliveryFeeItemPopupComponent>, private cloverApiService: CloverApiService,
                private squareApiService: SquareApiService, @Inject(MAT_DIALOG_DATA) data) {
        this.description = data.title;
        this.message = data.message;
    }

    ngOnInit() {
        this.getAllItemsWithImages();
    }

    private getAllItemsWithImages() {
        this.cloverApiService.getAllItems(1000, 0).subscribe(items => {
            this.items = items;
        });
    }

    close() {
        this.dialogRef.close();
    }

    sendItem() {
        this.dialogRef.close({
            selectedItem: this.deliveryFeeItem,
        });
    }
}

<h2 mat-dialog-title>{{description}}</h2>
<mat-dialog-content>
  <div class="dialog-options-container">
    <p>{{message}}</p>
    <img class="notification-image" src="../../../../assets/square_images.jpeg"/>
    <div style="padding: 12px 0; font-weight: bold">Sending Payment to Device: <span style="color: #F88D5E; font-weight: bold">{{time}}</span></div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <div class="button-container">
    <button mdbBtn color="danger" block="true" class="my-4" (click)="closeAndCancelOrder()">Cancel</button>
  </div>
</mat-dialog-actions>

<h2 mat-dialog-title>{{description}}</h2>
<mat-dialog-content>
  <div class="dialog-options-container">
    <div class="error-message">{{errorMessage}}</div>
    <input matInput class="name-input" placeholder="New Tab Name" [(ngModel)]="inputValue">
  </div>
</mat-dialog-content>
  <div class="button-container">
    <button mdbBtn color="danger" block="true" class="my-4 mr-2 action-button" (click)="close()">Cancel</button>
    <button mdbBtn [disabled]="loading"  color="info" block="true" class="my-4 action-button" (click)="submit()">{{button_text}}</button>
  </div>

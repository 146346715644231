<div class="navigation-container orange-background">
    <div class="navigation-details-panel"
         [ngClass]="{'sidebar-adjust-header': (selectedRole ===Constants.MANAGER  || selectedRole === Constants.SERVER ||selectedRole === 'Admin' || selectedRole ===Constants.TABLE) }">
        <i class="material-icons undo-icon" (click)="undoBump()">
            undo
        </i>
        <div>
            <h2 *ngIf="!customerDisplay">{{'orderUser.title' | translate }}</h2>
            <img class="image-logo" *ngIf="customerDisplay"
                 src="{{currentUser.orgSettings.image ? currentUser.orgSettings.image : './assets/default-logo.png'}}">
        </div>

        <button mat-icon-button [matMenuTriggerFor]="menu" class="menu-button">
            <mat-icon>more_vert</mat-icon>
        </button>

        <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="refreshData()">
                <mat-icon>refresh</mat-icon>
                <span>Refresh</span>
            </button>
            <button mat-menu-item (click)="customerModeChange()">
                <mat-icon>display_settings</mat-icon>
                <span>{{showCustomerModeText()}}</span>
            </button>
            <button mat-menu-item (click)="logout()">
                <mat-icon>logout</mat-icon>
                <span>Logout</span>
            </button>
        </mat-menu>
    </div>
    <alert></alert>
</div>
<br>
<div class="example-action-buttons" *ngIf="!customerDisplay && !loading">
    <div class="filter-dropdown">
        <ng-multiselect-dropdown
                class="dropdown-filter-selector"
                [placeholder]="'Filter by categories'"
                [settings]="excludedDropdownSettings"
                [data]="categories"
                [(ngModel)]='filteredCategories'
                (onDeSelect)="onFilterCategoryDeSelect($event)"
                (onSelect)="onFilterCategorySelect($event)"
        >
            <ng-template #optionsTemplate let-item let-option="option" let-id="id"
                         let-isSelected="isSelected">
            </ng-template>
        </ng-multiselect-dropdown>
    </div>
    <div>
        <mat-button-toggle-group #group="matButtonToggleGroup">
            <mat-button-toggle disabled="{{cardView}}" value="right" aria-label="Text align right"
                               (click)="accordion.openAll()">
                <i class="material-icons expand-icon">expand</i>
            </mat-button-toggle>
            <mat-button-toggle disabled="{{cardView}}" value="justify"
                               aria-label="Text align justify" (click)="accordion.closeAll()">
                <i class="material-icons unfold-less-icon">unfold_less</i>
            </mat-button-toggle>
            <mat-button-toggle value="left" aria-label="Text align left" (click)="setCardView(true)">
                <i class="material-icons">view_module</i>
            </mat-button-toggle>
            <mat-button-toggle value="center" aria-label="Text align center"
                               (click)="setCardView(false)">
                <i class="material-icons">view_headline</i>
            </mat-button-toggle>
        </mat-button-toggle-group>
    </div>
</div>
<section class="h-100" style="background-color: #f4f5f5;">
    <div class="container" [ngClass]="{'container-cardview': cardView}" style="position: relative; overflow-y: scroll;">
        <div class="row d-flex justify-content-center h-100">
            <div class="other-container">
                <div class="loader-container" *ngIf="loading && orders?.length === 0">
                    <img class="loading-gif" src="../../../../../assets/Coffeeloader.svg" alt="loading">
                </div>
                <div class="d-flex justify-content-center align-items-center mb-4"
                     *ngIf="orders?.length == 0 && !customerDisplay">
                    <br><br>
                    <h3 class="fw-normal mb-0 text-black" style="font-weight:bold; font-size: 1.25rem;"> No Orders
                        Found</h3>
                    <div>
                    </div>
                </div>
                <div *ngIf="customerDisplay && cardView && !loading" class="customer-view-title">
                    <div class="customer-view-title-ready">Now Serving</div>
                    <div class="customer-view-title-in-progress">In Progress</div>
                </div>
                <div [ngStyle]="{'margin-top': cardviewMarginTop(),'display': displayforListView()}">
                    <div class="ready-orders-container" *ngIf="currentUser.orgSettings.accountType === Constants.CLOVER && (customerDisplay && cardView && !loading)">
                        <div class="ready-orders" *ngFor="let order of socketService.bumpedOrders.reverse()"
                             (click)="completeAndHideOrder(order)"
                             [hidden]="order.hidden === 'true' || order.hidden === true">{{order.title ? titleUpdatefilter(order.title) : order.id}}
                        </div>
                    </div>
                    <div class="ready-orders-container" *ngIf="currentUser.orgSettings.accountType === Constants.SQUARE && (customerDisplay && cardView && !loading) ">
                        <div class="ready-orders" *ngFor="let order of socketService.OrdersSubject$ | async"
                             (click)="changeOrderFulfillmentSquare(order, Constants.COMPLETED)"
                             [hidden]="(order.state === Constants.CANCELED || order.state === Constants.DRAFT || order.metadata?.fulfilment_state === Constants.COMPLETED || !order.metadata?.fulfilment_state)">{{order.reference_id ? titleUpdatefilter(order.reference_id) : order.id}}
                        </div>
                    </div>
                    <div class="card-container"
                         [ngClass]="{'card-container-showReadyOrdersMode': customerDisplay && cardView, 'scroll-adjust': !customerDisplay && cardView}"
                         *ngIf="cardView">
                        <mat-card fxFlexAlign="stretch" class="example-card"
                                  [hidden]="currentUser.orgSettings.accountType === Constants.SQUARE && ( order.state === Constants.DRAFT || order.state === Constants.CANCELED || order.metadata?.fulfilment_state === Constants.COMPLETED  ||order.metadata?.fulfilment_state === Constants.READY)"
                                  [ngClass]="{'example-card-showReadyOrdersMode': customerDisplay && cardView}"
                                  *ngFor="let order of socketService.OrdersSubject$ | async">
                            <mat-card-header style="display: flex;">
                                <!--                            <div mat-card-avatar class="example-header-image"></div>-->
                                <mat-card-title
                                        *ngIf="currentUser.orgSettings.accountType === Constants.CLOVER">{{order.title ? titleUpdatefilter(order.title) : order.id}}</mat-card-title>
                                <mat-card-subtitle class="ordertype-text"
                                        *ngIf="currentUser.orgSettings.accountType === Constants.CLOVER && order.orderType?.label" [ngStyle]="{'color': setOrderTypeColor(order)}">{{order.orderType?.label.toUpperCase()}}</mat-card-subtitle>

                                <mat-card-title
                                        *ngIf="currentUser.orgSettings.accountType === Constants.SQUARE">{{order.reference_id ? titleUpdatefilter(order.reference_id) : order.id}}</mat-card-title>
                                <mat-card-subtitle
                                        *ngIf="currentUser.orgSettings.accountType === Constants.CLOVER">{{order.modifiedTime | date:'EEEE, h:mm:ss a'}}</mat-card-subtitle>
                                <mat-card-title
                                        *ngIf="currentUser.orgSettings.accountType === Constants.SQUARE">{{order.metadata?.fulfilment_state}}</mat-card-title>
                                <mat-card-subtitle
                                        *ngIf="currentUser.orgSettings.accountType === Constants.SQUARE">{{order.updated_at | date:'EEEE, h:mm:ss a'}}</mat-card-subtitle>

                                <mat-card-subtitle *ngIf="currentUser.orgSettings.accountType === Constants.CLOVER">
                                    <b *ngIf="currentUser.orgSettings.accountType === Constants.CLOVER">{{(order.total / 100).toFixed(2) | currency}}</b>
                                    <b *ngIf="currentUser.orgSettings.accountType === Constants.SQUARE">{{(order.total_money.amount / 100).toFixed(2) | currency}}</b>
                                </mat-card-subtitle>
                            </mat-card-header>
                            <!--                        <img mat-card-image src="https://material.angular.io/assets/img/examples/shiba2.jpg" alt="Photo of a Shiba Inu">-->
                            <mat-card-content *ngIf="currentUser.orgSettings.accountType === Constants.CLOVER">
                                <div class="line-items-container" *ngFor="let lineItem of order.lineItems">
                                    <div class="line-items-card">
                                        <div class="line-item-name-card">{{lineItem.name}}</div>
                                        <div>
                                            <!--<b>{{lineItem.priceWithModifiers ? (lineItem.priceWithModifiers / 100).toFixed(2) : (lineItem.price / 100).toFixed(2) | currency}}</b>-->
                                        </div>
                                        <div class="line-item-time">
                                            <b>{{lineItem.createdTime | date:'h:mm:ss a'}}</b>
                                        </div>
                                    </div>
                                    <div class="" *ngFor="let modifier of lineItem.modifications">
                                        <div class="modifier">
                                            <div class="line-item-modifier-card">{{modifier.name}}</div>
                                            <div class="line-item-price" *ngIf="(modifier.amount / 100).toFixed(2) > 0">
                                                <b>{{(modifier.amount / 100).toFixed(2) | currency}}</b></div>
                                        </div>
                                    </div>
                                    <div *ngIf="lineItem.note">
                                        <div class="modifier">
                                            <div class="line-item-modifier-card">{{lineItem.note}}</div>
                                        </div>
                                    </div>
                                </div>
                            </mat-card-content>

                            <mat-card-content *ngIf="currentUser.orgSettings.accountType === Constants.SQUARE">
                                <div class="line-items-container" *ngFor="let lineItem of order.line_items">
                                    <div class="line-items-card">
                                        <div class="line-item-name-card">{{lineItem.name}}</div>
                                        <div>
                                            <!--<b>{{lineItem.priceWithModifiers ? (lineItem.priceWithModifiers / 100).toFixed(2) : (lineItem.price / 100).toFixed(2) | currency}}</b>-->
                                        </div>
                                        <div class="line-item-time">
                                            <b>{{lineItem.createdTime | date:'h:mm:ss a'}}</b>
                                        </div>
                                    </div>
                                    <div class="" *ngFor="let modifier of lineItem.modifiers">
                                        <div class="modifier">
                                            <div class="line-item-modifier-card">{{modifier.name}}</div>
                                            <div class="line-item-price"
                                                 *ngIf="(modifier.total_price_money.amount / 100).toFixed(2) > 0">
                                                <b>{{(modifier.total_price_money.amount / 100).toFixed(2) | currency}}</b>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="lineItem.note">
                                        <div class="modifier">
                                            <div class="line-item-modifier-card">{{lineItem.note}}</div>
                                        </div>
                                    </div>
                                </div>
                            </mat-card-content>
                            <mat-card-actions>
                                <button mat-button class="bump-button-card align-self-end" (click)="bumpOrder(order)">
                                    <i class="material-icons">remove_done</i>
                                </button>
                            </mat-card-actions>
                        </mat-card>
                    </div>
                    <mat-accordion *ngIf="!cardView" class="example-headers-align" multi>
                        <mat-expansion-panel *ngFor="let item of orders">
                            <mat-expansion-panel-header>
                                <mat-panel-title class="expansion-title-container">
                                    <button mat-button class="bump-button" (click)="bumpOrder(item)">
                                        <i class="material-icons">remove_done</i>
                                    </button>
                                    <div class="itemTitle">{{item.title ? item.title : item.id}}
                                    </div>
                                </mat-panel-title>
                                <mat-panel-description>
                                    <div class="">
                                        <h5 class="itemBody"><b>{{(item.total / 100).toFixed(2) | currency}}</b></h5>
                                    </div>
                                    <div class="">
                                        <h6 class="itemBody"><b>{{item.modifiedTime | date:'shortTime'}}</b></h6>
                                    </div>
                                    <div class="">
                                        <h5 class="mb-0" style="word-wrap: normal">
                                            <b>{{item.state === 'locked' ? "Paid" : item.state }}</b></h5>
                                    </div>
                                </mat-panel-description>
                            </mat-expansion-panel-header>

                            <div class="line-items-container" *ngFor="let lineItem of item.lineItems">
                                <div class="line-items">
                                    <div class="line-item-name">{{lineItem.name}}</div>
                                    <div class="line-item-price">
                                        <b>{{lineItem.priceWithModifiers ? (lineItem.priceWithModifiers / 100).toFixed(2) : (lineItem.price / 100).toFixed(2) | currency}}</b>
                                    </div>
                                    <div class="line-item-time">
                                        <b>{{lineItem.createdTime | date:'short'}}</b>
                                    </div>
                                </div>
                                <div class="" *ngFor="let modifier of lineItem.modifications">
                                    <div class="modifier">
                                        <div class="line-item-modifier">{{modifier.name}}</div>
                                        <div class="line-item-price" *ngIf="(modifier.amount / 100).toFixed(2) > 0">
                                            <b>{{(modifier.amount / 100).toFixed(2) | currency}}</b></div>
                                    </div>
                                </div>
                                <div *ngIf="lineItem.note">
                                    <div class="modifier">
                                        <div class="line-item-modifier">{{lineItem.note}}</div>
                                    </div>
                                </div>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                    <br><br>
                </div>
            </div>
        </div>
    </div>
</section>



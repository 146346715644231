export class TakePayment {
    devicePaymentEnabled: boolean;
    cloverConnectorPayment: boolean;
    scanToPayPayment: boolean;
    cloverDeviceId: string;
    cloverDeviceSerial: string;
    deviceAlias: string;
    merchantId: string;
    paymentRequired: any;
    creditCardPayment: boolean;
}


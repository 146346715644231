<h2 mat-dialog-title>{{description}}</h2>
<mat-dialog-content>
  <div class="dialog-options-container">
    <p>{{message}} {{code}}</p>
  </div>
  <div class="dialog-options-container">
    <p>Code Expires in {{time | formatTime}}</p>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <div class="form-group button-container">
    <a block="true" class="my-4 margin" color="danger" (click)="close()" mdbBtn>Cancel</a>
    <button [disabled]="retryPaymentDisabled" block="true" class="my-4" color="info" mdbBtn (click)="createDeviceCode()">{{ 'button.GenerateNewCode' | translate }}</button>
  </div>
</mat-dialog-actions>

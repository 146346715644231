import {SquareMoney} from './squareMoney';
import {Modifier} from './modifier';

export class LineItemModifier {
  uid: string;
  base_price_money: SquareMoney;
  total_price_money: SquareMoney;
  name: string;
  catalog_object_id: string;
  quantity: string;

  constructor(mod: Modifier) {
    this.catalog_object_id = mod.id;
  }
}

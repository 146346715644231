import {isNil} from 'lodash';
import {Constants} from '../constants/constants';
import {AESEncryptDecryptService} from '../_services/aesencrypt-decrypt.service';
import {Injectable} from '@angular/core';
import {User} from '../_models';

@Injectable({
    providedIn: 'root'
})
export class UtilsService {
    constructor(private _AESEncryptDecryptService: AESEncryptDecryptService) {

    }

    public getCurrentUser() {
        return this.getCurrentUserFromSession();
    }

    private getCurrentUserFromSession() {
        if (localStorage.getItem(Constants.CURRENT_USER) && !isNil(this._AESEncryptDecryptService.decrypt(localStorage.getItem(Constants.CURRENT_USER)))) {
            const user = JSON.parse(this._AESEncryptDecryptService.decrypt(localStorage.getItem(Constants.CURRENT_USER)));
            user.orgSettings = JSON.parse(this._AESEncryptDecryptService.decrypt(localStorage.getItem(Constants.ORG_SETTINGS)));
            user.selectedRole = this._AESEncryptDecryptService.decrypt(localStorage.getItem(Constants.SELECTED_ROLE));
            return user;
        } else if (!isNil(sessionStorage.getItem(Constants.CURRENT_USER))) {
            const user = JSON.parse(this._AESEncryptDecryptService.decrypt(sessionStorage.getItem(Constants.CURRENT_USER)));
            user.orgSettings = JSON.parse(this._AESEncryptDecryptService.decrypt(sessionStorage.getItem(Constants.ORG_SETTINGS)));
            user.selectedRole = this._AESEncryptDecryptService.decrypt(sessionStorage.getItem(Constants.SELECTED_ROLE));
            return user;
        }
    }

    private getSelectedRoleFromSession() {
        if (this._AESEncryptDecryptService.decrypt(localStorage.getItem(Constants.SELECTED_ROLE))) {
            return JSON.parse(this._AESEncryptDecryptService.decrypt(localStorage.getItem(Constants.SELECTED_ROLE)));
        } else if (isNil(sessionStorage.getItem(Constants.SELECTED_ROLE))) {
            return JSON.parse(this._AESEncryptDecryptService.decrypt(sessionStorage.getItem(Constants.SELECTED_ROLE)));
        }
    }

    public setCurrentUser(user: User, role?) {
        return this.setCurrentUserToSession(user);
        if (role) {
            this.setCurrentUserAndRoleToSession(user, role);
        }
    }

    private setCurrentUserToSession(user: User) {
        localStorage.setItem(Constants.ORG_SETTINGS, this._AESEncryptDecryptService.encrypt(JSON.stringify(user.orgSettings)));
        user.orgSettings = null;
        localStorage.setItem(Constants.CURRENT_USER, this._AESEncryptDecryptService.encrypt(JSON.stringify(user)));
        localStorage.setItem(Constants.SELECTED_ROLE, this._AESEncryptDecryptService.encrypt(user.role[0]));
    }


    private setCurrentUserAndRoleToSession(user: User, role: string) {
        localStorage.setItem(Constants.ORG_SETTINGS, this._AESEncryptDecryptService.encrypt(JSON.stringify(user.orgSettings)));
        user.orgSettings = null;
        localStorage.setItem(Constants.CURRENT_USER, this._AESEncryptDecryptService.encrypt(JSON.stringify(user)));
        localStorage.setItem(Constants.SELECTED_ROLE, this._AESEncryptDecryptService.encrypt(role));
    }

    public setSelectedRole(role: string) {
        this.setRoleToSession(role);
    }

    private setRoleToSession(role: string) {
        localStorage.setItem('selectedRole', this._AESEncryptDecryptService.encrypt(role));
    }
}

<!--<h2 mat-dialog-title  class="title-header">{{description}}</h2>-->
<mat-dialog-content>
    <h1 class="header-title">Instructions</h1>
    <br>
    <div class="dialog-options-container">
        <mat-button-toggle-group [(ngModel)]="popupType" aria-label="Font Style">
            <mat-button-toggle value="link">Link</mat-button-toggle>
            <mat-button-toggle value="qr">QR Code</mat-button-toggle>
            <mat-button-toggle value="settings">Settings</mat-button-toggle>
        </mat-button-toggle-group>
        <!--      <p>Chosen value is {{popupType}}</p>-->
        <div class="error-message">{{errorMessage}}</div>
    </div>
    <div class="text-container" *ngIf="popupType === 'settings'">
        <form class="example-form">
            <div class="select-container">
                <mat-slide-toggle [ngModelOptions]="{standalone: true}" [(ngModel)]="paymentEnabled">Payment
                    Enabled: {{paymentEnabled}}</mat-slide-toggle>
            </div>
            <div class="toggle-selector">
                <ng-multiselect-dropdown
                        [placeholder]="'Excluded Categories'"
                        [settings]="excludedDropdownSettings"
                        [data]="cloverCategories"
                        [ngModelOptions]="{standalone: true}"
                        [(ngModel)]='selectedCategories'
                        (onDeSelect)="onExcludeCategoryDeSelect($event)"
                        (onSelect)="onExcludeCategorySelect($event)"
                        multiple>
                    <ng-template #optionsTemplate let-item let-option="option" let-id="id"
                                 let-isSelected="isSelected">
                        <!--                    {{getItems[id].item_text}}-->
                    </ng-template>
                </ng-multiselect-dropdown>
                <br><br><br>
            </div>
        </form>
    </div>
    <div class="text-container" *ngIf="popupType === 'link'">
        <div style="font-family: 'Helvetica', 'Arial', sans-serif;line-height: 20px;font-weight:bold">Choose
            instructions based on you operating system below. If you use a kiosk app, use
            the auto login to automatically log in to table kiosk on start. The URL and login details can also be found
            below:
        </div>
        <br>
        <div class="link-container">
            <div class="general-padding">Windows: <span class="link"
                                                        (click)="goToLink('https://learn.microsoft.com/en-us/windows/configuration/kiosk-single-app')">Click Here</span>
            </div>
            <div class="general-padding">Android: <span class="link"
                                                        (click)="goToLink('https://medium.com/@kanikasinha47/how-to-lock-android-tablet-or-phone-to-one-app-9aebe073b519#:~:text=Open%20the%20app%20you%20want%20to%20pin.&text=Swipe%20up%20on%20the%20app,until%20the%20screen%20is%20unpinned.')">Click Here</span>
            </div>
            <div class="general-padding">IOS: <span class="link"
                                                    (click)="goToLink('https://www.hexnode.com/blogs/what-is-ios-kiosk-mode/')">Click Here</span>
            </div>
        </div>
        <br>
        <div class="note"> Note: You may use either the one time code or Admin's password for table login. If you want
            to login automatically, replace the password in the autologin url to the admins account password.
        </div>
        <div class="table-selector-container">
            <div style="color: black;font-weight:bold">For Specific Table Auto-Login URL: &nbsp; &nbsp;
            </div>
            <mat-form-field>
                <mat-select name="roleSelect" placeholder="Select Table"
                            (selectionChange)="addTableToAutologin($event.value)">
                    <mat-optgroup [label]="'MAIN DINING'">
                        <mat-option *ngFor="let table of tables" [value]="table.name">{{table.name}}
                        </mat-option>
                    </mat-optgroup>
                    <mat-optgroup *ngFor="let layout of currentUser.orgSettings.additionalLayouts | keyvalue"
                                  [label]="layout.key">
                        <mat-option *ngFor="let table of layout.value"
                                    [value]="table.name">{{table.name.split(layout.key + ' - ')[1].toLocaleLowerCase()}}
                        </mat-option>
                    </mat-optgroup>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="creds-container">
            <div class="general-padding">Auto login URL: <span class="link creds"
                                                               (click)="goToLink(siteAutoLoginURL)">{{siteAutoLoginURL}}</span>
            </div>
            <div class="general-padding">URL: <span class="link creds" (click)="goToLink(siteURL)">{{siteURL}}</span>
            </div>
            <div class="general-padding">Username: <span class="creds">{{tableUsername}}</span></div>
            <div class="general-padding">One Time Password: <span class="creds"> &nbsp; {{tablePassword}} &nbsp; &nbsp;
                 <a class="password-reset" *ngIf="false" alt="password Reset" (click)="resetPassword()">
                    <mat-icon mat-icon class="delete-icon">lock_reset</mat-icon>
                </a> &nbsp; &nbsp;
                <b style="font-weight: bold;">OR</b>  &nbsp; &nbsp; Admin's Password</span>
                &nbsp; &nbsp;
            </div>
        </div>
    </div>
    <div class="text-container-qr" *ngIf="popupType === 'qr'">
        <div style="color: black;font-weight: bold">Scan the QR code with the device you would like to login.</div><br>
        <div class="table-selector-container">
            <div style="color: black;margin-left: 65px">For Table Specific Login:
            </div>&nbsp; &nbsp;
            <mat-form-field>
                <mat-select name="roleSelect" placeholder="Select Table"
                            (selectionChange)="addTableToAutologin($event.value)">
                    <mat-optgroup [label]="'MAIN DINING'">
                        <mat-option *ngFor="let table of tables" [value]="table.name">{{table.name}}
                        </mat-option>
                    </mat-optgroup>
                    <mat-optgroup *ngFor="let layout of currentUser.orgSettings.additionalLayouts | keyvalue"
                                  [label]="layout.key">
                        <mat-option *ngFor="let table of layout.value"
                                    [value]="table.name">{{table.name.split(layout.key + ' - ')[1].toLocaleLowerCase()}}
                        </mat-option>
                    </mat-optgroup>
                </mat-select>
            </mat-form-field>
        </div>
        <app-qr-generator [height]="200" [width]="200" [qrUrl]="qrUrl"
                          [title]="('orderConfirmation.paymentQrTitle' | translate)"></app-qr-generator>
    </div>
</mat-dialog-content>
<div class="button-container">
    <button mdbBtn [disabled]="loading" *ngIf="popupType !== 'settings'" color="info" block="true"
            class="my-4 action-button" (click)="submit()">{{button_text}}</button>
    <button mdbBtn [disabled]="loading" *ngIf="popupType === 'settings'" block="true"
            class="my-4 action-button orange-button" (click)="getOrCreateTableLogin(true)">Update QR
    </button>

</div>

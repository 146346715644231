import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {User} from '../../../_models';
import 'rxjs-compat/add/observable/timer';
import {SquarePayServiceService} from '../../../_services/square-pay-service.service';
import {Router} from '@angular/router';
import {AESEncryptDecryptService} from '../../../_services/aesencrypt-decrypt.service';
import { CartServiceSquare } from 'src/app/_models/cart.service.square';
import { UtilsService } from 'src/app/_services/utils.service';

@Component({
  selector: 'app-notification-popup',
  templateUrl: './notification-payment-required-popup.html',
  styleUrls: ['./notification-payment-required-popup.scss']
})
export class NotificationPaymentRequiredPopupComponent implements OnInit {

  description: string;
  message: string;
  qrUrl: string;
  payLinkId: string;
  currentUser: User;
  buttonDisabled = false;
  private selectedRole: string;

  constructor(private dialogRef: MatDialogRef<NotificationPaymentRequiredPopupComponent>, private squarePayService: SquarePayServiceService,
              private cartService: CartServiceSquare, @Inject(MAT_DIALOG_DATA) data, private router: Router,
              private utilsService: UtilsService, private _AESEncryptDecryptService: AESEncryptDecryptService) {
    this.description = data.title;
    this.message = data.message;
    this.qrUrl = data.qrUrl;
    this.payLinkId = data.payLinkId;
    this.currentUser = utilsService.getCurrentUser();
    this.selectedRole = this.currentUser?.selectedRole;

  }

  ngOnInit() {
  }

  closeAndCancelOrder() {
    if (this.currentUser.role.includes('Table') && this.router.url.includes('/orderHistory')) {
      this.dialogRef.close();
    } else if (this.currentUser.role.includes('Kiosk') || (this.currentUser.role.includes('Table') && !this.router.url.includes('/orderHistory') )) {
      this.buttonDisabled = true;
      this.squarePayService.deletePaymentLink(this.payLinkId).subscribe(response => {
        console.log('Deleted Payment Link ' + response);
        this.dialogRef.close();
      }, error => {
        console.log(error);
        this.buttonDisabled = false;
      });
    } else if (this.currentUser.role.includes('Admin') || this.currentUser.role.includes('Manager')) {
      this.dialogRef.close();
    }
  }
}

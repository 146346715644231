import { Component, OnInit } from '@angular/core';
import {User} from '../_models';
import {environment} from '../../environments/environment';
import {AESEncryptDecryptService} from '../_services/aesencrypt-decrypt.service';
import {QrDeviceService} from '../_services/qrDevice.service';
import {QRModel} from '../_models/qrDevice';
import {Constants} from '../constants/constants';
import {UtilsService} from '../_services/utils.service';

@Component({
  selector: 'app-qr-order',
  templateUrl: './qr-order.component.html',
  styleUrls: ['./qr-order.component.scss']
})
export class QrOrderComponent implements OnInit {
  readonly Constants = Constants;
  currentUser: User;
  selectedRole: any;
  qrUrl: string;
  constructor(private _AESEncryptDecryptService: AESEncryptDecryptService, private qrService: QrDeviceService,
              private utilsService: UtilsService,) {
  }

  ngOnInit(): void {
    this.currentUser = this.utilsService.getCurrentUser();
    this.selectedRole = this.currentUser?.selectedRole;
    const qrDevice = new User();
    qrDevice.token = this.currentUser.token;
    this.currentUser.role = [Constants.QR_ORDER];
    qrDevice.uuid = this.currentUser.uuid;
    qrDevice.username = this.currentUser.firstName + ' ' ;
    qrDevice.excludedCategories = this.currentUser.excludedCategories;
    qrDevice.takePayment = this.currentUser.takePayment;
    qrDevice.receiptView = false;
    qrDevice.orgSettings = this.currentUser.orgSettings;
    qrDevice.orgUuid = this.currentUser.orgUuid ? this.currentUser.orgUuid : this.currentUser._id;
    const qrData = new QRModel();
    qrData.username = this.currentUser.username;
    qrData.orgUuid = qrDevice.orgUuid;
    qrData.attachedUserId = this.currentUser._id;

    this.qrService.register(qrData).subscribe(response => {
      this.qrUrl =  `${environment.qrOrderURL}qr=` + encodeURIComponent(this._AESEncryptDecryptService.encrypt(this.currentUser.username));
    },
      error => {
      throw new Error(error);
      });

  }

}

import { Injectable } from '@angular/core';
import {Customer} from '../_models/customer';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {retry, shareReplay} from 'rxjs/operators';
import {AESEncryptDecryptService} from './aesencrypt-decrypt.service';
import { Order } from '../_models/order';
import {User} from '../_models';
import {UtilsService} from './utils.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerRewardsService {

  customer: Observable<Customer>;
  currentUser: User;
  merchant_id: string;
  cloverToken: string;
  CACHE_SIZE = 1;


  constructor(private http: HttpClient, private _AESEncryptDecryptService: AESEncryptDecryptService, private utilsService: UtilsService) {
  }

  customerAuth(number, password) {
    return this.http.post<Customer>(`${environment.apiUrl}/customers/customerAuth`, {number, code: password});
  }

  checkin(number: number, name: string) {
    this.setupCurrentUser();

    this.customer = this.http.post<Customer>(`${environment.apiUrl}/customers/checkin`, { 'number': number, 'name': name, sendSignupText: this.currentUser.orgSettings.rewards.rewardsEnabled })
          .pipe( shareReplay(this.CACHE_SIZE), retry(3) );
      return this.customer;
  }

  private setupCurrentUser() {
      this.currentUser = this.utilsService.getCurrentUser();
  }

  sendPromotionalText(numbers: string[], text: string, media_urls: string) {
    return this.http.post<any>(`${environment.apiUrl}/textMessages/sendPromotionalText`, { 'numbers': numbers, 'text': text, 'media_urls': media_urls});
  }

  getTextCount() {
      return  this.http.get<any>(`${environment.apiUrl}/textMessages/getTextCount`);
  }

  checkinAndGetCoupons(number: number, name: string, merchantId: string) {
      this.customer = this.http.post<Customer>(`${environment.apiUrl}/customers/checkinAndGetCoupons`, { 'number': number, 'name': name, 'merchantId': merchantId})
          .pipe( shareReplay(this.CACHE_SIZE), retry(3) );
      return this.customer;
  }

  getCustomerRewards(number: number, name: string) {
    this.setupCurrentUser();
    this.customer = this.http.post<Customer>(`${environment.apiUrl}/customers/getCustomerRewards`,
          { 'number': number, 'name': name, sendSignupText: this.currentUser.orgSettings.rewards.rewardsEnabled}).pipe( shareReplay(this.CACHE_SIZE), retry(3) );
      return this.customer;
  }

  redeem(number: Number, order_Id, discount, orderTotal, afterVisits) {
    const order = new Order();
    order.id = order_Id;
    order.state = 'open';
    order.total = orderTotal;
    const body = {
      // 'merchant_id': localStorage.getItem('merchant_id'),
      'merchant_id': this.merchant_id,
      'token': this.cloverToken,
      'orderId': order_Id,
      'orderTotal': orderTotal,
      'discount': discount,
      'afterVisits': afterVisits,
      'order': order
    };
    return this.http.post<Customer>(`${environment.apiUrl}/customers/redeem/${number}`, body);
  }

  manualRedeem(number: Number, discount, updatedPoints?) {
    const currentUser = this.utilsService.getCurrentUser();
    const body = {
      merchant_id: currentUser.orgSettings.merchantId ? currentUser.orgSettings.merchantId : currentUser.merchantId,
      number: number,
      discount: discount,
      pointsForManualRedeem: updatedPoints,
      afterVisits: currentUser.orgSettings.rewards.afterVisits
    };
    return this.http.post<Customer>(`${environment.apiUrl}/customers/manualRedeem/${number}`, body);
  }

  registerWithPromoLink(number: Number, customer) {
    return this.http.put<Customer>(`${environment.apiUrl}/customers/updateCustomer/${number}`, customer);
  }

  redeemPaidOrder(number: Number, discount, afterVisits) {
    const order = new Order();
    order.state = 'open';
    const body = {
      'discount': discount,
      'afterVisits': afterVisits
    };
    return this.http.post(`${environment.apiUrl}/customers/redeemPaidOrder/${number}`, body);
  }

  delete(id: string) {
    return this.http.delete(`${environment.apiUrl}/rating/${id}`);
  }

}

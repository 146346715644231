import { Injectable } from '@angular/core';
import { HttpClient } from '../../../node_modules/@angular/common/http';
import { environment } from '../../environments/environment';
import { Constants } from '../constants/constants';
import { AESEncryptDecryptService } from './aesencrypt-decrypt.service';
import {User} from '../_models';
import {UtilsService} from './utils.service';

@Injectable({
  providedIn: 'root'
})
export class AtmoicOrderService {
  currentUser: any;
  Clover_Token: any;

  constructor(private http: HttpClient, private _AESEncryptDecryptService: AESEncryptDecryptService, private utilsService: UtilsService) { }

  atomicOrderCheckoutV3(paymentPayload, TitleRequired, title, discount?) {
    let orderType;
    if (localStorage.getItem('orderType')) {
      orderType = localStorage.getItem('orderType');
    } else if (this.utilsService.getCurrentUser().selectedRole === 'Admin' || this.utilsService.getCurrentUser().selectedRole === Constants.MANAGER || this.utilsService.getCurrentUser().selectedRole === Constants.TABLE || this.utilsService.getCurrentUser().selectedRole === Constants.SERVER || this.utilsService.getCurrentUser().selectedRole === Constants.QR_ORDER) {
      orderType = Constants.DINE_IN;
    } else {
      orderType = 'DineNGo';
    }
    return this.http.post<any>(`${environment.apiUrl}/cloverOrders/atomicOrder`, { order: paymentPayload, TitleRequired: TitleRequired, title: title, discount: discount, orderType: orderType });
  }

  updateAtomicOrder(order, newItems, disablePrint?) {
    order.lineItems.push(...newItems);
    const currentUser = this.getCurrentUser();
    const data = {
      'orderCart': order,
      'orderId': order.id,
    };
    return this.http.post<any>(`${environment.apiUrl}/cloverOrders/updateAtomicOrder`, { order: data, disablePrint: disablePrint, newItems: newItems, v1Print: currentUser.orgSettings.featuresEnabled.v1Print ? currentUser.orgSettings.featuresEnabled.v1Print : false });
  }

  getCurrentUser() {
    let currentUser: User;
    if (localStorage.getItem('currentUser')) {
      currentUser = this.utilsService.getCurrentUser();
    } else if (sessionStorage.getItem('currentUser')) {
      currentUser = this.utilsService.getCurrentUser();
    }
   return currentUser;
  }

  GetSingleOrderById(order_id) {
    return this.http.post<any>(`${environment.apiUrl}/cloverOrders/getOrder`, {orderId: order_id});
  }

  getOrderByTableName(tablename) {
    return this.http.get<any>(`${environment.apiUrl}/cloverOrders/getOrderByTableName/${tablename}`);
  }

  getOpenOrdersList(tablename) {
    return this.http.get<any>(`${environment.apiUrl}/cloverOrders/getOpenOrderList/${tablename}`);
  }

  getRecentOpenOrdersList(filterOpen: boolean) {
    return this.http.get<any>(`${environment.apiUrl}/cloverOrders/getRecentOpenOrdersList/${filterOpen}`);
  }

  getAuthToken(userId) {
    return this.http.post<any>(`${environment.apiUrl}/clientCredentials/getCloverToken`, {userId});
  }

  getMerchantId(userId) {
    return this.http.get<any>(`${environment.apiUrl}/clientCredentials/getMerchantId`);
  }

  getApiKey() {

    const requestOptions = {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        authorization: `Bearer ${this.Clover_Token.cloverToken}`,
        'Access-Control-Allow-Headers': '*'
      },
      //   redirect: 'follow'
    };

    return fetch('https://scl-sandbox.dev.clover.com/pakms/apikey', requestOptions);
    //   .then(response => response.text())
    //   .then(result => console.log(result))
    //   .catch(error => console.log('error', error));
  }
}

import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {User} from '../../../_models';
import {AESEncryptDecryptService} from '../../../_services/aesencrypt-decrypt.service';
import {AlertService, UserService} from '../../../_services';
import {Router} from '@angular/router';
import {CustomerRewardsService} from '../../../_services/customerRewards.service';
import {NotifierService} from 'angular-notifier';

@Component({
  selector: 'app-notification-popup',
  templateUrl: './text-promotion-popup.html',
  styleUrls: ['./text-promotion-popup.component.scss']
})
export class TextPromotionPopupComponent implements OnInit {

  currentUser: User;
  numbers = [];
  loading = false;
  maxTexts = 2000;
  sentTextCount = 0;
  promotionalText = '';

  constructor(private dialogRef: MatDialogRef<TextPromotionPopupComponent>, private customerService: CustomerRewardsService,
              private alertService: AlertService, private userService: UserService, private router: Router,
              private notifier: NotifierService, private _AESEncryptDecryptService: AESEncryptDecryptService,
              @Inject(MAT_DIALOG_DATA) data) {
    this.numbers = data.numbers;
  }

  ngOnInit() {
    this.getMerchantTextCount();
  }


  close() {
    this.dialogRef.close();
  }

  submit() {
    this.loading = true;
    this.customerService.sendPromotionalText(this.numbers, this.promotionalText, '').subscribe(response => {
      this.showNotification('success', 'Promotion Successfully Sent');
      this.dialogRef.close();
    });
  }

  getMerchantTextCount() {
    this.customerService.getTextCount().subscribe(count => {
      this.sentTextCount = count?.messagesSent;
      // if ((this.sentTextCount + this.numbers.length) > this.maxTexts) {
      //   this.dialogRef.close();
      //   this.notifier.notify('warning', 'Monthly Limit Reached');
      // }
    });
  }

  onChange($event) {
    this.promotionalText = $event;
  }

  public showNotification = (type: string, message: string): void => {
    this.notifier.notify(type, message);
  }
}

import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as uuid from 'uuid';
import {Observable} from 'rxjs';
import {takeWhile} from 'rxjs/operators';
import {User} from "../../_models";
import {SquareApiService} from "../../_services/square-api.service";
import {AESEncryptDecryptService} from "../../_services/aesencrypt-decrypt.service";
import {UtilsService} from '../../_services/utils.service';

@Component({
  selector: 'app-notification-popup',
  templateUrl: './square-device-pairing-popup.component.html',
  styleUrls: ['./square-device-pairing-popup.component.scss']
})
export class SquareDevicePairingPopupComponent implements OnInit {

  description: string;
  message: string;
  currentUser: User;
  retryPaymentDisabled = true;
  time: number;
  code: string;
  date: Date;

  constructor(private dialogRef: MatDialogRef<SquareDevicePairingPopupComponent>, private utilsService: UtilsService, private squareApi: SquareApiService,
              @Inject(MAT_DIALOG_DATA) data, private _AESEncryptDecryptService: AESEncryptDecryptService) {
    this.currentUser = utilsService.getCurrentUser();
    this.description = data.description;
    this.message = data.message;
    this.code = data.code;
  }

  ngOnInit() {
    this.disableRetryButton(300);
  }

  createDeviceCode() {
    this.retryPaymentDisabled = true;
    const request = {
      idempotency_key: uuid.v4(),
      device_code: {
        name: 'Counter 1',
        product_type: 'TERMINAL_API',
        location_id: this.currentUser.locationId
      }
    };

    this.squareApi.createDeviceCode(request).subscribe((response) => {
      this.disableRetryButton(300);
      this.code = response.device_code.code;
    });
  }

  private disableRetryButton(seconds) {
    this.time = seconds;
    const timer = Observable.timer(0, 1000);
    timer.pipe(takeWhile(val => this.time > 0)).subscribe(t => {
      this.time--;
    });
    setTimeout(() => {
      this.retryPaymentDisabled = false;
      this.code = '';
    }, (seconds - 1) * 1000);
  }

  close() {
    this.dialogRef.close({event: 'Cancel'});
  }
}


// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  cloverAuthUrl: 'https://sandbox.dev.clover.com/oauth/v2/authorize',
  cloverApiUrl: 'https://sandbox.dev.clover.com',
  squareAuthUrl: 'https://connect.squareupsandbox.com/oauth2/authorize',
  squarePermissions: 'ITEMS_READ+MERCHANT_PROFILE_READ+ORDERS_WRITE+ORDERS_READ+PAYMENTS_WRITE+DEVICE_CREDENTIAL_MANAGEMENT+PAYMENTS_WRITE_ADDITIONAL_RECIPIENTS+PAYMENTS_WRITE_IN_PERSON+EMPLOYEES_READ+PAYMENTS_READ+CUSTOMERS_WRITE',
  client_id: '7HPEPVBTZGDCP',
  clientSecret: '2d6e8a94-c46f-c605-ac69-728192f1afec',
  clientIdSquare: 'sandbox-sq0idb-GqEM0ljcNRXO8WKzTP9tHQ',
  docs_url: 'https://drivensoftwaresolutions.com/index.php/knowledge-base/',
  cloverConnectorAppId: 'PVZY6TBHX5JSE.7HPEPVBTZGDCP',
  state: '82201dd8d83d23cc8a48caf52b',
  //apiUrl: '/api',
  apiUrl: 'https://grubgrab.net/api',
  //apiUrl: 'http://localhost:4000/api',
  websiteSubdomainUrl: '.grubgrab.net/website',
  scanToPayUrl: 'https://www.clover.com/scan-to-pay/',
  cryptoSecret: 'CloverServer Driven Software Solutions LLC 4049969713-2d6e8a94-c46f-c605-ac69-728192f1afec',
  VAPID_PUBLIC_KEY: 'BFz1U2xd_ofkIHF3FoYX_9WPpG0N1KrcE4DavzssgyRvncfKMJaBN_6m9JEGQTlmJ873dhny0-0msHBTlLQC2Ps', // Vapid Key for Push notification
  apiServerUrl: '',
  base_url: '',
  // qrOrderURL: 'https://grubgrab.net/cloverAuth/cloverAuth?',
  qrOrderURL: 'http://localhost:4200/cloverAuth/cloverAuth?',
  uberAuthUrl: 'https://login.uber.com/oauth/v2/authorize',
  uberClientId: 'CpgXhP7vUAFPGh6rEYHA9ufpOkxXWWlp',
  uberClientSecret: 'JV379e2MbE3Uu23Wmg_wkytQ5JYEZryv8cmkESkT',
  uberScopes: 'eats.store.orders.read',
  uberRedirectUrl: 'https://dine-n-go-dev.herokuapp.com/cloverAuth/uberAuth'
};


import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {SharedModule} from '../../../shared/shared.module';
import {OrderUserComponent} from './order-user.component';
import {AuthGuard} from '../../../_guards';
import { MatMenuModule } from '@angular/material/menu';

const routes: Routes = [
  {
    path: 'orderUser',
    component: OrderUserComponent,
    canActivate: [AuthGuard]
  },
];


@NgModule({
  declarations: [OrderUserComponent],
  imports: [
    CommonModule,
    SharedModule,
    MatMenuModule,
    RouterModule.forChild(routes)
  ]
})
export class OrderUserModule { }

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './login';
import {RegisterComponent} from './register';
import {LogoutComponent} from './logout/logout.component';
import {MDBBootstrapModule} from 'angular-bootstrap-md';
import { RoleSelectComponent } from './role-select/role-select.component';
import {ResetPasswordComponent} from './reset-password/reset-password.component';
import {NgOtpInputModule} from 'ng-otp-input';
import {AlertModule} from 'ngx-bootstrap/alert';
import {MatInputModule} from '@angular/material/input';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatButtonModule} from '@angular/material/button';
import {MatSelectModule} from '@angular/material/select';
import {MatRadioModule} from '@angular/material/radio';
import {MatCardModule} from '@angular/material/card';
import {CustomerSignupFormComponent} from '../customer/customer-signup-form/customer-signup-form.component';

const materialModules = [
  MatToolbarModule,
  MatInputModule,
  MatButtonModule,
  MatSelectModule,
  MatRadioModule,
  MatCardModule,
  ReactiveFormsModule,
  FormsModule
];

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'customer/signup',
    component: CustomerSignupFormComponent
  },
  {
    path: 'register',
    component: RegisterComponent
  },
  {
    path: 'logout',
    component: LogoutComponent
  },
  {
    path: 'select-role',
    component: RoleSelectComponent
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent
  },
];


@NgModule({
  declarations: [ResetPasswordComponent, CustomerSignupFormComponent],
  imports: [
    CommonModule,
    FormsModule,
      ...materialModules,
    MDBBootstrapModule.forRoot(),
    RouterModule.forChild(routes),
    NgOtpInputModule,
    AlertModule,
    MatInputModule
  ],
  exports:  [FormsModule],
})
export class LoginModule { }


import {Component, Inject, Input, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {User} from '../../../_models';

@Component({
  selector: 'app-notification-popup',
  templateUrl: './qr-pay-popup.component.html',
  styleUrls: ['./qr-pay-popup.component.scss']
})
export class QrPayPopupComponent implements OnInit {

  description: string;
  message: string;
  currentUser: User;
  qrUrl = '';
  title = '';
  imageSrc = '';
  height = 500;
  width = 500;
  colorLight  = '#ffffffff';
  colorDark = '#000000ff';
  orderFinished = false;


  constructor(private dialogRef: MatDialogRef<QrPayPopupComponent>,
              @Inject(MAT_DIALOG_DATA) data) {
    this.description = data.title;
    this.message = data.message;
    this.qrUrl = data.qrUrl;
    this.imageSrc = data.imageSrc;
  }

  ngOnInit() {
  }

  close() {
    this.orderFinished = true;
    this.dialogRef.close({});
  }
}


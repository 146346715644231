import {TakePayment} from './takePayment';
import {OrgSetting} from './orgSetting';

export class QRModel {
  username: string;
  orgUuid: string;
  attachedUserId: string;
  data: string;
  update: boolean;
  token: string;
  orgSettings: OrgSetting;
  merchantId: string;
  accountType: string;
  role: string;
  takePayment: TakePayment;
  excludedCategories: string[];

}

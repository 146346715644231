<h2 mat-dialog-title style="text-align: center">{{message}}</h2>
<p mat-dialog-title class="info-container">{{description}}</p>
<mat-dialog-content>
  <div class="dialog-options-container">
    <select class="custom-select" [(ngModel)]="deliveryFeeItem">
      <option *ngFor="let item of items ;" [ngValue]="item">
        {{item.name}}
      </option>
    </select>

  </div>
</mat-dialog-content>
  <div class="button-container">
    <button mdbBtn color="info" block="true" class="my-4 mr-2 action-button" (click)="sendItem()">Select</button>
  </div>

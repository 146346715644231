<div (click)="close()" class="dialog-close-button-container">
  <span class="material-icons dialog-close-button">cancel</span>
</div>
<div class="main-container-form">
  <div class="close-dialog-btn">
    <h1 mat-dialog-title>
      <h2> Updating Image for {{ dataType }}: <span style="color: #FF8C46" >{{ image.cloverDataName }}</span></h2>
    </h1>
  <mat-error *ngIf="selectionError">{{ errorMsg }}</mat-error>

  <!-- <mat-dialog-content [formGroup]="image-paymentForm"> -->
  <mat-dialog-content>
  <br>
      <div class="info-content"><h5> Enter URL of image or click the upload button to upload your own. </h5></div>

    <mat-form-field class="example-full-width">
      <input type="url" matInput placeholder="Image Url" id="image-url" pattern="https://*">
    </mat-form-field>
    <div class="or-text"> or </div>
    <div class="form-group">
      <div class="upload-btn-wrapper" *ngIf="false">
        <button (click)="selectImage.click()" [disabled]="submitted" class="btn-upload">
          <span *ngIf="!uploadedFileName">{{ 'Upload' | translate }}</span>
          <span *ngIf="uploadedFileName">{{ uploadedFileName }}</span>
        </button>
        <input #selectImage type="file" name="myfile" id="myFile" accept="image/*" (change)="setFileName()" />
      </div>
      <button class="btn-upload" [disabled]="submitted" (click)="generateImage()">Generate AI Image</button>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <div class="button-container">
      <!-- <button (click)="close()" mdbBtn color="danger" block="true" class="my-4 right-margin-set">{{ 'button.cancel' | translate }}</button> -->
      <button [disabled]="submitted" (click)="delete()" mdbBtn color="danger" block="true" class="my-4 right-margin-set">{{ 'Delete Image' | translate }}</button>
      <button [disabled]="submitted"  *ngIf="currentUser.orgSettings.accountType === Constants.CLOVER" (click)="save()" mdbBtn color="info" block="true" class="my-4">{{ 'Update Image' | translate }}</button>
      <button [disabled]="submitted" *ngIf="currentUser.orgSettings.accountType === Constants.SQUARE" (click)="saveSquare()" mdbBtn color="info" block="true" class="my-4">{{ 'Update Image' | translate }}</button>

    </div>
  </mat-dialog-actions>
</div>

<h2 mat-dialog-title class="title">Text Message Promotions</h2>
<mat-dialog-content>
  <div class="dialog-options-container">
<!--    <div class="error-message">{{errorMessage}}</div>-->
    <textarea type="text" class='name-input' id='amountEntry' placeholder="Enter Promotional Text" [ngModel]="promotionalText" (ngModelChange)="onChange($event)"></textarea>
  </div>
</mat-dialog-content>
  <div class="button-container">
    <button mdbBtn color="danger" block="true" class="my-4 mr-2 action-button" (click)="close()">Cancel</button>
    <button mdbBtn [disabled]="loading"  color="info" block="true" class="my-4 action-button" (click)="submit()">{{ 'button.submit' | translate }}</button>
  </div>

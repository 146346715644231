import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { OrgTable } from 'src/app/_models/orgTable';
import { AESEncryptDecryptService } from 'src/app/_services/aesencrypt-decrypt.service';
import {IDropdownSettings} from 'ng-multiselect-dropdown';
import {User} from '../../../_models';
import {Constants} from '../../../constants/constants';
import { UtilsService } from 'src/app/_services/utils.service';

@Component({
  selector: 'app-role-select',
  templateUrl: './table-setup.component.html',
  styleUrls: ['./table-setup.component.scss'],
})
export class TableSetupComponent implements OnInit {
  orgTables: OrgTable[] = [];
  tablesDropdownSettings: IDropdownSettings = {
    singleSelection: true,
    idField: '_id',
    textField: 'name',
    itemsShowLimit: 3,
    allowSearchFilter: true,
    enableCheckAll: false
  };
  currentUser: User;
  private selectedRole: string;
  constructor(
    private formBuilder: FormBuilder,
    private _AESEncryptDecryptService: AESEncryptDecryptService,
    private router: Router,
    private utilsService: UtilsService
  ) {
    if (this._AESEncryptDecryptService.decrypt(localStorage.getItem('currentUser'))) {
      this.currentUser = utilsService.getCurrentUser();
      this.selectedRole = this.currentUser?.selectedRole;
    }
  }


  private routeToProperComponent() {
    localStorage.setItem('CartItem', this._AESEncryptDecryptService.encrypt(JSON.stringify([])));
    this.router.navigate(['sidebar/order']).then(() => {
      // window.location.reload();
    });
  }

  onIncludeTableSelect(value) {
    let selectedTable;
    Object.keys( this.currentUser.orgSettings.additionalLayouts).forEach(key => {
      if (!selectedTable) {
        selectedTable = this.currentUser.orgSettings.additionalLayouts[key].filter(table => table.name.toLowerCase()
            === value.toLowerCase())[0];
      }
    });
      // selectedTable = this.currentUser.orgSettings.additionalLayouts[key].filter(table => table.name === value)[0];
    if (!selectedTable) {
      selectedTable = this.orgTables.filter(table => table.name === value)[0];
    }
    this.currentUser.firstName = selectedTable.name;
    this.currentUser.username = selectedTable.name;
    this.currentUser.position = selectedTable.position;
    this.currentUser._id = selectedTable._id;
    localStorage.setItem('table', selectedTable.name.split(' ').join('').toLowerCase());
    localStorage.setItem('currentUser', this._AESEncryptDecryptService.encrypt(JSON.stringify(this.currentUser)));
    this.routeToProperComponent();
  }


  ngOnInit(): void {
    this.orgTables = this.currentUser.orgSettings.tables;
    if (localStorage.getItem('table')) {
      let selectedTable = this.orgTables.filter(table => (table.name.split(' ').join('').toLowerCase()
          === localStorage.getItem('table')) || (table.name.split(' ').join('').toLowerCase().replace('table', '') === localStorage.getItem('table')))[0];
      if (!selectedTable) {
        Object.entries(this.currentUser.orgSettings.additionalLayouts).forEach(([key, value]) => {
          if ((value as any[]).length > 0) {
            selectedTable = (value as any[]).filter(table => (table.name.split(' ').join('').toLowerCase()
                === localStorage.getItem('table')) || (table.name.split(' ').join('').toLowerCase().replace('table', '')
                === localStorage.getItem('table')))[0];
          }
        });
      }
      this.currentUser.firstName = selectedTable.name;
      this.currentUser.username = selectedTable.name;
      this.currentUser.position = selectedTable.position;
      this.currentUser._id = selectedTable._id;
      localStorage.setItem('currentUser', this._AESEncryptDecryptService.encrypt(JSON.stringify(this.currentUser)));
      this.routeToProperComponent();
    }
  }
}

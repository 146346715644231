<div class="navigation-container orange-background">
    <div class="navigation-details-panel" [ngClass]="{'sidebar-adjust-header': (selectedRole === Constants.MANAGER  || selectedRole === Constants.SERVER || selectedRole === 'Admin' ||selectedRole === Constants.TABLE) }">
        <div
                class="payment-status-container">{{this.currentUser.takePayment.deviceAlias.toString() | titlecase}}
            Status:
            <span *ngIf="isCloverConnectedPayment()" class="material-icons connected-icon">check_circle
                </span>
            <span *ngIf="!(isCloverConnectedPayment())" class="material-icons disconnected-icon">cancel</span>
        </div>
        <div>
            <h2>Pay For An Order</h2>
        </div>
        <div class="rewards-container">
        <span>
        <i class="material-icons logout-icon" (click)="logout()">
            logout
        </i>
        </span>
            <div class="search-input">
                <!--                <mat-form-field *ngIf="items" class="search-bar" appearance="outline">-->
                <!--                    <mat-label>Search Items</mat-label>-->
                <!--                    <input type="text" matInput [formControl]="searchedItem" [matAutocomplete]="auto">-->
                <!--                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn"-->
                <!--                                      (optionSelected)='openOptions($event.option.value)'>-->
                <!--                        <mat-option *ngFor="let item of searchedItems | async" [value]="item">-->
                <!--                            {{item.name}}-->
                <!--                        </mat-option>-->
                <!--                    </mat-autocomplete>-->
                <!--                </mat-form-field>-->
            </div>
        </div>
    </div>
    <alert></alert>
</div>
<section class="h-100" style="background-color: #f7f7f7;" *ngIf="lineItems">
    <div class="container h-100 py-5" style="position: relative;
              overflow-y: scroll;">
        <div class="row d-flex justify-content-center h-100">
            <div class="col-10">
                <div class="loader-container" *ngIf="loading">
                    <img class="loading-gif" src="../../../../../assets/Coffeeloader.svg" alt="loading">
                </div>
                <div class="d-flex justify-content-center align-items-center mb-4" *ngIf="lineItems.length > 0">
                    <div>
                        <!-- <p class="mb-0"><span class="text-muted">Sort by:</span> <a href="#!" class="text-body">price <i
                              class="fas fa-angle-down mt-1"></i></a></p> -->
                    </div>
                </div>
                <div class="d-flex justify-content-center align-items-center mb-4" *ngIf="lineItems.length == 0">
                    <br><br>
                    <h3 class="fw-normal mb-0 text-black" style="font-weight:bold; font-size: 1.25rem;" *ngIf="!loading">No Open Orders
                        Found</h3>
                    <div>
                    </div>
                </div>
                <div class="card rounded-3 mb-4" *ngFor="let item of lineItems">
                    <div class="card-body p-4">
                        <div class="row d-flex  align-items-center">
                            <div class="item-title-container">
                                <p class="itemTitle">{{item.title? item.title : item.id}}</p>
                            </div>
                            <div class="col-md-2 col-lg-2 col-xl-2">
                                <h5 class="mb-0">{{(item.total / 100).toFixed(2) | currency}}</h5>
                            </div>

                            <div class="">
                                <button type="button" class="btn btn-outline-warning btn-md ms-3 payment-button"
                                        (click)="scanToPay(item)">Scan To Pay (QR)
                                </button>
                            </div>
                            <div class="" *ngIf="(isCloverConnectedPayment)">
                                <button type="button" class="btn btn-outline-warning btn-md ms-3 payment-button"
                                        (click)="cloverPayV2(item)">Clover
                                </button>
                            </div>
                            <div class="">
<!--                                <button type="button" class="btn btn-outline-warning btn-lg ms-3" style="padding: 0"-->
<!--                                        (click)="applePay(item, $event)"><img class="apple-pay-image"-->
<!--                                                                              src="assets/apple-pay-logo.jpeg">-->
<!--                                </button>-->
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>

<div class="d-flex justify-content-between align-items-center mb-4" *ngIf="!lineItems">
    <h3 class="fw-normal mb-0 text-black">There Is No Orders</h3>
    <div>
        <!-- <p class="mb-0"><span class="text-muted">Sort by:</span> <a href="#!" class="text-body">price <i
              class="fas fa-angle-down mt-1"></i></a></p> -->
    </div>
</div>

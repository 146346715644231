<div>
  <div mat-dialog-title class="dialog-header">
    <h2>{{description}}</h2>
    <div class="close-btn" (click)="close()">
      &times;
    </div>
  </div>
  <mat-dialog-content>
    <mat-error *ngIf="selectionError">Please select the required options</mat-error>
    <div class="selectors">
      <img class="pic" src="./assets/gray.png">
      <span class="pics"> NOT Selected  </span>
      <img class="pic" src="./assets/orange.png">
      <span class="pics"> Selected </span>
    </div>
    <br>
    <div *ngIf="!modifiers_loading">
      <div *ngFor="let modifierList of item.modifiers; let i = index" class="col-width mb-3 ml-4">
        <h3 class="header1">{{modifierList.name}}
          <span>
            {{ getSelectedMessage(modifierList) }}
          </span>
        </h3>
        <br>
        <div class="row main-container-square">
          <mat-button-toggle-group [multiple]="modifierList.selectionType === 'MULTIPLE'">
            <mat-button-toggle [name]="modifier.id" *ngFor="let modifier of modifierList.modifiers"
                               [checked]="modifier.selected"
                               (change)="onModifierChange(modifier, modifierList)"
                               [disabled]="modIsDisabled(modifier, modifierList)" [disableRipple]="true">
              {{modifier.name}} &nbsp;&nbsp;&nbsp; + ${{modifier.price / 100}}
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
    </div>
    <h3 class="header2">Custom Message</h3>
    <div class="dialog-options-container">
      <textarea id="custom-message-input"
                [(ngModel)]="customMessage"
                style="width: 100%;
                height: 100px;
                background: #fff4ee;
                border-radius: 15px;
                padding: 15px;">
      </textarea>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <div class="w-100 d-flex justify-content-center">
      <button mat-raised-button class="qty-btn" (click)="quantity = quantity <= 0 ? 0 : quantity - 1">
        <mat-icon>remove</mat-icon>
      </button>
      <input type="text" class="qty-input" [(ngModel)]="quantity">
      <button mat-raised-button class="qty-btn" (click)="quantity = quantity + 1">
        <mat-icon>add</mat-icon>
      </button>
    </div>
    <div class="d-flex">
      <button mat-raised-button block="true" class="my-4 order-btn" [disabled]="orderBtnDisabled()"
              (click)="save()">{{ 'items.order' | translate }}</button>
    </div>
  </mat-dialog-actions>
</div>

import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CloverOrderService} from '../../../_services/cloverOrder.service';
import {User} from '../../../_models';
import {AESEncryptDecryptService} from '../../../_services/aesencrypt-decrypt.service';
import {AlertService, UserService} from '../../../_services';
import {Router} from '@angular/router';
import {Discount} from '../../../_models/discount';

@Component({
  selector: 'app-notification-popup',
  templateUrl: './custom-discount-input-popup.html',
  styleUrls: ['./custom-discount-input-popup.component.scss']
})
export class CustomDiscountInputPopupComponent implements OnInit {

  description: string;
  message: string;
  currentUser: User;
  currentTableName: string;
  errorMessage: string;
  selectedRole: string;
  loading = false;
  assignedTables = false;
  inputValue: string | null = '';
  button_text = 'Submit';
  selectedState = 'amount';


  constructor(private dialogRef: MatDialogRef<CustomDiscountInputPopupComponent>, private cloverOrderService: CloverOrderService,
              private alertService: AlertService, private userService: UserService, private router: Router,
              private _AESEncryptDecryptService: AESEncryptDecryptService,
              @Inject(MAT_DIALOG_DATA) data) {
    this.description = data.title;
    this.message = data.message;
    this.button_text = data.button_text;
    this.currentTableName = data.currentTableName;
  }

  ngOnInit() {
  }


  close() {
    this.dialogRef.close();
  }

  submit() {
    if (Number(this.inputValue) > 0) {
      const discount = new Discount();
      discount.name = 'Employee Added Discount';
      if (this.selectedState === 'amount') {
        discount.amount = Number(this.inputValue.replace('.', '')) * (-1);
      } else if (this.selectedState === 'percent') {
        discount.percentage = Number(this.inputValue);
      }
      this.dialogRef.close({
        discount: discount,
      });
    } else {
      this.errorMessage = 'Please Enter a Valid ' + this.selectedState;
    }
  }

  onChange($event) {
    console.log($event.value);
    this.selectedState = $event.value;
  }

  modelChange(event: any) {
    // if (parseFloat(event) === 0) {
    //   this.inputValue = '0.00';
    //   return;
    // }
    // convert to float to remove leading zeros
    const value = event ? parseFloat(event.replace('.', '')).toString() : '';
    if (value) {
      // check if the length is greater than 2 only then split to two sides of the decimal
      if (value.length > 2) {
        // split on two sides of the decimal, pad the right side alone with two decimals if necessary
        this.inputValue = value
            ? value.slice(0, -2) + '.' + value.slice(-2).padStart(2, '0')
            : value;
      } else {
        // here no need to split, so we just need to focus on right side of decimal
        // get the right side and pad with zero if needed to two decimals
        this.inputValue =
            value != '0' && value ? '.' + value.slice(-2).padStart(2, '0') : null;
      }
    } else {
      this.inputValue = '';
    }
  }

}

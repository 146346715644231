import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {ClientCredentials} from '../_models/clientCredentials';
import {Item} from '../_models/Item';
import {Observable} from 'rxjs';
import {retry, shareReplay} from 'rxjs/operators';
import {AESEncryptDecryptService} from './aesencrypt-decrypt.service';
import {MerchantSettings} from '../_models/merchantSettings';
import {ServiceCharge} from '../_models/serviceCharge';
import {Category} from '../_models/category';
import {AuthenticationService} from './authentication.service';

@Injectable({providedIn: 'root'})
export class CloverApiService {
    cloverItemsWithImages: Observable<Item[]>;
    mostSoldItems: Observable<any>;
    cloverItems: Observable<Item[]>;
    itemsWithImages: Observable<Item[]>;
    cloverCategoriesWithImagesAndItems: Observable<any>;
    cloverCategoriesWithImages: Observable<Category[]>;
    modifierGroups: Observable<any[]>;
    CACHE_SIZE = 1;

    constructor(private http: HttpClient, private _AESEncryptDecryptService: AESEncryptDecryptService, private authenticationService: AuthenticationService) {
    }

    SaveClientCredentials(body: ClientCredentials) {
        return this.http.post<any>(`${environment.apiUrl}/clientCredentials/saveClientCredentials`, body);
    }


    getAllCategories() {
        const body = {};
        if (!this.cloverCategoriesWithImages) {
            this.cloverCategoriesWithImages = this.http.get<Category[]>(`${environment.apiUrl}/cloverApi/categoriesWithImages`)
                .pipe(shareReplay(this.CACHE_SIZE), retry(3));
        }
        return this.cloverCategoriesWithImages;
    }

    getMostSoldItems() {
        const body = {};

        if (!this.mostSoldItems) {
            this.mostSoldItems = this.http.post<any>(`${environment.apiUrl}/cloverApi/getMostSoldItems`, body)
                .pipe(shareReplay(this.CACHE_SIZE), retry(3));
        }
        return this.mostSoldItems;
    }

    getCategoriesWithImagesAndItems() {
        const body = {};

        this.cloverCategoriesWithImagesAndItems = this.http.post<[Category[], Item[]]>(`${environment.apiUrl}/cloverApi/categoriesWithImagesAndItems`, body)
            .pipe(shareReplay(this.CACHE_SIZE), retry(3));
        return this.cloverCategoriesWithImagesAndItems;
    }


    getAllItems(limit, offset) {
        const body = {};
        if (!this.cloverItemsWithImages || (limit && offset)) {
            let url = `${environment.apiUrl}/cloverApi/itemsWithImages`;

            if (limit && offset) {
                url = url + '?limit=' + limit + '&offset=' + offset;
            }
            this.cloverItemsWithImages = this.http.get<Item[]>(url)
                .pipe(shareReplay(this.CACHE_SIZE), retry(3));
        }
        return this.cloverItemsWithImages;
    }

    getAllItemsWithoutImages() {
        const body = {};

        if (!this.cloverItemsWithImages) {
            this.cloverItems = this.http.post<Item[]>(`${environment.apiUrl}/cloverApi/items`, body)
                .pipe(shareReplay(this.CACHE_SIZE), retry(3));
        }
        return this.cloverItems;
    }

    getAllItemsSomeWithImages() {
        const body = {};
        return this.http.post<any>(`${environment.apiUrl}/cloverApi/itemsSomeWithImages`, body)
            .pipe(shareReplay(this.CACHE_SIZE));
    }


    getAllItemsByCategoryId(id: string) {
        return this.http.get<any>(`${environment.apiUrl}/cloverApi/itemsByCategoryWithImages/` + id);
    }

    getItemByItemId(id: string) {


        const body = {
            'item_id': id
        };
        return this.http.post<any>(`${environment.apiUrl}/cloverApi/itemByItemId`, body);
    }

    getAllEmployees() {

        return this.http.get<any[]>(`${environment.apiUrl}/cloverApi/getAllEmployees`);
    }

    getAllCustomers() {
        return this.http.get<any[]>(`${environment.apiUrl}/cloverApi/getAllCustomers`);
    }

    getAllCategoriesByItemId(id: string) {
        const body = {
            'item_id': id
        };
        return this.http.post<any>(`${environment.apiUrl}/cloverApi/categoriesByItems`, body);
    }

    getTaxRate() {
        // console.log('in CloverApiService getAllCategoriesByItemID'); //*MES*

        const body = {};
        return this.http.post<any>(`${environment.apiUrl}/cloverApi/getTaxRate`, body);
    }

    getDefaultServiceCharge() {
        return this.http.get<ServiceCharge>(`${environment.apiUrl}/cloverApi/getDefaultServiceCharge`);
    }

    getMerchantSetttings() {
        return this.http.get<MerchantSettings>(`${environment.apiUrl}/cloverApi/merchantSettings`);
    }


    getModifierGroupsByItemId(id: string) {
        return this.http.get<any>(`${environment.apiUrl}/cloverApi/modifierGroupsByItemId?id=` + id);
    }

    getModifierGroups() {
        if (!this.modifierGroups) {
            this.modifierGroups = this.http.post<any[]>(`${environment.apiUrl}/cloverApi/modifierGroups`, {})
                .pipe(shareReplay(this.CACHE_SIZE), retry(3));
        }
        return this.modifierGroups;
    }

    initKioskOnDevice(deviceId: string, deviceSerial: string, firePrint?: boolean) {
        const body = {
            'firePrint': firePrint ? firePrint : '',
            'deviceSerial': deviceSerial,
            'deviceId': deviceId,
        };
        return this.http.post<any>(`${environment.apiUrl}/cloverOrders/initKioskOnDevice`, body);
    }

    getAllModifiersByGroupId(id: string) {
        const body = {
            'modifiersGroupId': id
        };
        return this.http.post<any>(`${environment.apiUrl}/cloverApi/modifiersByGroupId`, body);
    }


}

<div class="parent">
    <form class="element text-center border border-light p-5">
        <img class="i1" src="./assets/default-logo.png" alt="logo"/>
        <div *ngIf="!codeVerified"  class="title">One Time Passcode Sent to: +1{{number}}</div>
        <div *ngIf="!codeVerified"  class="enter-code-text">Enter the code Below</div>
        <div *ngIf="codeVerified"  class="enter-code-text">Enter New Password</div>
        <ng-otp-input *ngIf="!codeVerified" (onInputChange)="onOtpChange($event)" [config]="{length:5}"></ng-otp-input>
        <div *ngIf="errorMessage" class="is-invalid">{{errorMessage}}</div>
        <input *ngIf="codeVerified" type="password" class="form-control mb-4 center-text" placeholder="Password" [(ngModel)]="password" [ngModelOptions]="{standalone: true}"/>
        <input *ngIf="codeVerified" type="password" class="form-control mb-4" placeholder="Confirm Password" [(ngModel)]="passwordConfirm" [ngModelOptions]="{standalone: true}"/>
        <button *ngIf="showOtpComponent" mdbBtn color="info" block="true" [disabled]="resendCodeDisabled" (click)="regenerateOTC()" data-cy="submit-button"
                class="my-4 button">Resend Code</button>
        <button *ngIf="codeVerified" mdbBtn color="info" block="true" (click)="resetPassword()" data-cy="submit-button"
                class="my-4 button">Reset Password</button>
    </form>
</div>

import {Component, OnInit} from '@angular/core';
import * as _ from 'lodash';
import {Router} from '@angular/router';
import { User } from 'src/app/_models';
import {AESEncryptDecryptService} from '../../_services/aesencrypt-decrypt.service';
import {Constants} from '../../constants/constants';
import { Location } from '@angular/common';
import {AuthenticationService} from '../../_services';
import {UtilsService} from '../../_services/utils.service';


@Component({
    selector: 'app-clover-auth-router',
    templateUrl: './square-auth-router.component.html',
    styleUrls: ['./square-auth-router.component.css']
})
export class SquareAuthRouterComponent implements OnInit {

    currentUser: User;
    private selectedRole: string;

    constructor(private router: Router, private location: Location, private _AESEncryptDecryptService: AESEncryptDecryptService,
                private authenticationService: AuthenticationService, private utilsService: UtilsService) {
        this.currentUser = utilsService.getCurrentUser();
        this.selectedRole = this.currentUser?.selectedRole;
    }


    ngOnInit() {
        const urlString = window.location.href;
        const url = new URL(urlString);
        const urlCode = url.searchParams.get('code');

        if (!_.isNil(urlCode)) {
            this.initialRouterPropertiesSet(url);
        } else if (url.searchParams.get('user')) {
            this.processLoggedInUrlUser(url);
        } else {
            this.router.navigate(['/login']);
        }
    }

    processLoggedInUrlUser(url) {
        if (url.searchParams.get('user')) {
            this.currentUser = JSON.parse(this._AESEncryptDecryptService.decrypt(url.searchParams.get('user')));
            if (this.currentUser) {
                localStorage.setItem(Constants.CURRENT_USER, url.searchParams.get('user'));
                this.routeToProperComponent();
            }
        }
    }

    initialRouterPropertiesSet(url) {
        const urlCode = url.searchParams.get('code');

        if (!_.isNil(urlCode)) {
            this.authenticationService.squareLogin(urlCode)
                .subscribe(
                    (user) => {
                        this.currentUser = user;
                        this.selectedRole = this.currentUser.role[0];
                        this.routeToProperComponent();
                    },
                    error => {
                        console.log(error);
                        this.deleteQueryParameterFromCurrentRoute();
                        this.router.navigate(['/login']);
                        alert(error);
                    });
        } else {
            this.deleteQueryParameterFromCurrentRoute();
            this.router.navigate(['/login']);
        }
    }

    private routeToProperComponent() {
        this.deleteQueryParameterFromCurrentRoute();
        if (this.currentUser.role.length > 1) {
            this.router.navigate(['/select-role']);
        } else if ((this.selectedRole === 'Admin'  && !this.currentUser.dashboardDisabled)) {
            this.router.navigate(['/sidebar/admin/dashboard']);
        } else if (this.selectedRole === 'Manager' || this.selectedRole === 'Server' || this.currentUser.dashboardDisabled) {
            this.router.navigate(['sidebar/admin/waiterMenu/serverOrder']).then(() => {
                // window.location.reload();
            });
        } else if (this.selectedRole === 'Table') {
            this.router.navigate(['/sidebar/order']).then(() => {
                // window.location.reload();
            });
        } else if (this.selectedRole === 'PaymentsDisplay') {
            this.router.navigate(['/paymentUser/paymentUser']).then(() => {
                // window.location.reload();
            });
        } else if (this.selectedRole === 'OrdersDisplay') {
            this.router.navigate(['/orderUser/orderUser']).then(() => {
                // window.location.reload();
            });
        } else if (this.selectedRole === 'Kiosk') {
            this.router.navigate(['/kiosk']).then(() => {
                // window.location.reload();
            });
        } else if (this.selectedRole === 'QROrder') {
            this.router.navigate(['/QROrder']).then(() => {
                // window.location.reload();
            });
        } else {
            this.router.navigate(['/login'], {queryParamsHandling: 'preserve'}).then(() => {
                window.location.reload();
            });
        }
    }

    deleteQueryParameterFromCurrentRoute() {
        this.location.replaceState('./squareAuth');
    }
}

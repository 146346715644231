<h2 mat-dialog-title>{{description}}</h2>
<mat-dialog-content>
  <div class="dialog-options-container">
    <app-qr-generator [height]="200" [width]="200" [qrUrl]="qrUrl"></app-qr-generator>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <div class="button-container">
    <button [disabled]="buttonDisabled" mdbBtn color="danger" block="true" class="my-4 example-full-width" (click)="closeAndCancelOrder()">Cancel Order</button></div>
</mat-dialog-actions>

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {environment} from '../../environments/environment';
import { Reservation } from '../_models/reservation';

@Injectable({ providedIn: 'root' })
export class ReservationService {

  constructor(private http: HttpClient) {
  }

  getAll() {
    return this.http.get<Reservation[]>(`${environment.apiUrl}/reservations/getAll`);
  }
  getReservationForTable(tableId) {
    return this.http.get<Reservation>(`${environment.apiUrl}/reservations/getReservationForTable/`+tableId);
  }

  createReservation(reservation: Reservation) {
    return this.http.post(`${environment.apiUrl}/reservations/create`, reservation);
  }

  lockTableToOrder(reservation: Reservation) {
    return this.http.post(`${environment.apiUrl}/reservations/lockTableOrder`, reservation);
  }

  update(reservation: Reservation) {
    return this.http.post(`${environment.apiUrl}/reservations/update`, reservation);
  }

  delete(id: string) {
    return this.http.delete(`${environment.apiUrl}/reservations/${id}`);
  }

}


<!-- Default paymentForm login -->
<div class="parent">
    <form [formGroup]="loginForm" class="element text-center border border-light p-5">
        <div class="login-items-container">
            <i class="material-icons arrow-back-icon back-icon" routerLink="/home">arrow_back</i>
            <img class="i1" src="./assets/default-logo.png" alt="logo"/>
            <!--    <p class="h4 mb-4">SIGN IN</p>-->

            <!-- Email -->
            <input formControlName="username" type="email" class="form-control mb-4"
                   placeholder="E-mail"
                   [ngClass]="{ 'is-invalid': (submitted && f.username.errors) || passwordResetEmailMissing }"/>
            <div class="error-message" *ngIf="errorMessage">{{errorMessage}}</div>
            <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                <div *ngIf="f.username.errors.required">Username is required</div>
            </div>
            <div *ngIf="passwordResetEmailMissing" class="invalid-feedback">
                <div>Enter the Email or Phone Number</div>
            </div>
            <!-- Password -->
            <input formControlName="password" type="password" class="form-control mb-4"
                   placeholder="Password"
                   [ngClass]="{ 'is-invalid': submitted && f.password.errors }"/>
            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                <div *ngIf="f.password.errors.required">Password is required</div>
            </div>
            <alert></alert>

            <!-- Sign in button -->
            <button mdbBtn block="true" class="login-button my-4 button-flex" type="submit" [disabled]="loading" (click)="onSubmit()">Sign in
                <mat-spinner class="spinner-loader" diameter="20" *ngIf="loading"></mat-spinner>
            </button>

            <div class="reset-button-container">
                <p><a class="btn-link" (click)="resetPassword()">Forgot password?</a></p> <div class="password-reset-or">|</div>
                <p><a [routerLink]="['/register']" class="btn-link">Register (New User)</a></p>
            </div>
            <div class="hr">
                <span> OR </span>
            </div>
            <button mdbBtn  block="true" class="clover-login-button my-4 button-flex" type="submit" [disabled]="loading" (click)="reauthenticateRouter(Constants.CLOVER)">Sign in with Clover
                <mat-spinner class="spinner-loader" diameter="20" *ngIf="loading"></mat-spinner>
            </button>

            <button mdbBtn  block="true" class="square-login-button my-4 button-flex" type="submit" [disabled]="true" title="Coming Soon" (click)="reauthenticateRouter(Constants.SQUARE)">Sign in with Square
                <mat-spinner class="spinner-loader" diameter="20" *ngIf="loading"></mat-spinner>
            </button>
            <!-- Register -->
            <!-- Social login
            <p>or sign in with:</p>

            <a type="button" class="light-blue-text mx-2">
               <i class="fab fa-facebook-f"></i>
              <mdb-icon fab icon="facebook"></mdb-icon>
            </a>
            <a type="button" class="light-blue-text mx-2">
               <i class="fab fa-twitter"></i>
              <mdb-icon fab icon="twitter"></mdb-icon>
            </a>
            <a type="button" class="light-blue-text mx-2">
               <i class="fab fa-linkedin-in"></i>
              <mdb-icon fab icon="linkedin"></mdb-icon>
            </a>
            <a type="button" class="light-blue-text mx-2">
               <i class="fab fa-github"></i>
              <mdb-icon fab icon="github"></mdb-icon>
            </a>
            -->
        </div>
    </form>
    <!-- Default paymentForm login -->
</div>

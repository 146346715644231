<div class="navigation-container orange-background">
    <div class="navigation-details-panel" [ngClass]="{'sidebar-adjust-header': (selectedRole ===Constants.MANAGER  || this.selectedRole === Constants.SERVER ||selectedRole === 'Admin' || selectedRole ===Constants.TABLE) }">
        <div>
        </div>
        <div>
            <h2>Scan to Order</h2>
        </div>
        <div class="rewards-container">
        <span>
        <span class="material-icons logout-icon" routerLink="/logout">logout</span>
        </span>
            <div class="search-input">
            </div>
        </div>
    </div>
    <alert></alert>
</div>

<div class="parent">
  <div class="element text-center border border-light">
    <div class="qrcodeImage">
      <qrcode
        [qrdata]="qrUrl"
        [allowEmptyString]="true"
        [attr.ariaLabel]="'QR Code image with the following content...'"
        [cssClass]="'center'"
        [colorDark]="'#000000ff'"
        [colorLight]="'#ffffffff'"
        [elementType]="'canvas'"
        [errorCorrectionLevel]="'L'"
        [attr.imageSrc]="currentUser.orgSettings.image ? currentUser.orgSettings.image : './assets/default-logo.png'"
        [attr.imageHeight]="75"
        [attr.imageWidth]="75"
        [margin]="4"
        [scale]="1"
        [title]="'Scan To Order'"
        [width]="300"
      ></qrcode>
    </div>
    <div class="message">Scan To Begin Order</div>
  </div>
</div>
<div>
</div>

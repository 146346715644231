<h2 mat-dialog-title>{{description}}</h2>
<mat-dialog-content>
  <div class="dialog-options-container">
    <p>{{message}}</p>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <div class="button-container">
    <button mdbBtn color="danger" block="true" class="my-4 right-margin-set" (click)="close()">Cancel</button>
    <button mdbBtn color="info" block="true" class="my-4" (click)="confirm()">Confirm</button>
  </div>
</mat-dialog-actions>

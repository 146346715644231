import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CloverOrderService} from '../../../_services/cloverOrder.service';
import {User} from '../../../_models';
import {AESEncryptDecryptService} from '../../../_services/aesencrypt-decrypt.service';
import {CartService} from '../../../_services/cart.service';
import {Observable} from "rxjs";
import {takeWhile} from 'rxjs/operators';
import {CloverApiService} from "../../../_services/cloverApi.service";
import {IDropdownSettings} from "ng-multiselect-dropdown";
import * as _ from "lodash";
import {AlertService, UserService} from "../../../_services";
import {Router} from "@angular/router";


@Component({
  selector: 'app-notification-popup',
  templateUrl: './custom-input-popup.html',
  styleUrls: ['./custom-input-popup.component.scss']
})
export class CustomInputPopupComponent implements OnInit {

  description: string;
  message: string;
  currentUser: User;
  currentTableName: string;
  errorMessage: string;
  selectedRole: string;
  loading = false;
  assignedTables = false;
  inputValue = '';
  button_text = 'Submit'


  constructor(private dialogRef: MatDialogRef<CustomInputPopupComponent>, private cloverOrderService: CloverOrderService,
              private alertService: AlertService, private userService: UserService, private router: Router,
              private _AESEncryptDecryptService: AESEncryptDecryptService, @Inject(MAT_DIALOG_DATA) data) {
    this.description = data.title;
    this.message = data.message;
    this.button_text = data.button_text;
    this.currentTableName = data.currentTableName;
  }

  ngOnInit() {
  }


  close() {
    this.dialogRef.close();
  }

  submit() {
    this.dialogRef.close({
      customerName: this.inputValue,
    });
  }
}

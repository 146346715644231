import {LineItem} from './lineItem';
import {SquareMoney} from './squareMoney';
import * as moment from 'moment';

export class Order {
  id: string;
  currency: string;
  employee: {id: string};
  customers: any[];
  state: string;
  status: string;
  paymentState: string;
  total: number;
  title: string;
  payType: string;
  ref_num: string;
  amount_paid: number;
  note: string;
  orderType: any;
  lineItems: LineItem[];
  merchant_id: string;
  playlist: any;
  orderId: string;
  api_token: string;
  modificationTotal: number;
  cardNumber: number;
  expMonth: number;
  expYear: number;
  modifiedTime: Date;
  createdTime: Date;
  cvv: string;
  serviceChargeAmount: number;
  cardType: string;
  first6: string;
  last4: number;
  payments: any[];
  email: string;
  taxAmount: number;
  discount: {
    name: string;
    amount: number;
    percentage: number;
  };
  tipAmount: number;


  // square PRoperties
  reference_id: string;
  source: {
    name: string;
  };
  location_id: string;
  line_items: LineItem[];
  created_at: Date;
  version: number;
  paymentURL: string;
  updated_at: Date;
  // state: 'OPEN' | 'COMPLETED' | 'CANCELED' | 'DRAFT';
  total_tax_money: SquareMoney;
  total_money: SquareMoney;
  net_amounts: {
    discount_money: SquareMoney;
    service_charge_money: SquareMoney;
    tax_money: SquareMoney;
    tip_money: SquareMoney;
    total_money: SquareMoney;
  };
  pricing_options: {
    auto_apply_taxes: boolean;
  };
  metadata: {
    fulfilment_state: string
  };
  tenders: [
    {
      id: string,
      location_id: string,
      transaction_id: string,
      created_at: Date,
      amount_money: {
        amount: number,
        currency: string
      },
      type: string,
      payment_id: string
    }
  ];
  fulfillments: [
    {
      metadata: {
        state: string,
        orderType: string
      },
      pickup_details: {
        recipient: {
          display_name: string
        },
        is_curbside_pickup: boolean,
        schedule_type: string,
        prep_time_duration: string,
      },
      type: string
    }
  ];
  fields_to_clear: string[];


  constructor(referenceId?: string, locationId?: string, lineItems?: LineItem[]) {
    this.reference_id = referenceId;
    const dateNow = (new Date()).getTime(); // Unix timestamp (in milliseconds)
    const fifteenMinFromNow = new Date(dateNow + 900000);
    this.fulfillments = [{
      metadata: {
        state: 'CREATED',
        orderType: localStorage.getItem('orderType') ? localStorage.getItem('orderType') : 'DineNGo'
      },
      pickup_details: {
        recipient: {
          display_name: referenceId
        },
        is_curbside_pickup: false,
        prep_time_duration: 'P0DT0H15M15S',
        schedule_type: 'ASAP'
      },
      type: 'PICKUP'
    }];
    this.source = {
      name: 'DineNGo'

    };
    if (this.metadata) {
      this.metadata.fulfilment_state = 'CREATED';
    } else {
      this.metadata = {
        fulfilment_state: 'CREATED'
      };
    }
    this.note = 'DineNGo';
    this.location_id = locationId;
    this.line_items = lineItems;
    this.pricing_options = {
      auto_apply_taxes: true
    };
  }
}

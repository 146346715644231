import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {first} from 'rxjs/operators';
import { AlertService } from 'src/app/_services';
import {SquareApiService} from "../../_services/square-api.service";
import {SquareModifierList} from "../../_models/squareModifierList";
import { Item } from 'src/app/_models/Item';
import { Modifier } from 'src/app/_models/modifier';

@Component({
  selector: 'app-item-options-dialog-component',
  templateUrl: './item-options-dialog-component-square.component.html',
  styleUrls: ['./item-options-dialog-component-square.component.scss']
})
export class ItemOptionsDialogComponentSquare implements OnInit {
  description: string;
  item: Item;
  selectionError = false;
  modifiers_loading = true;
  customMessage: string;
  quantity = 1;

  constructor(private dialogRef: MatDialogRef<ItemOptionsDialogComponentSquare>, @Inject(MAT_DIALOG_DATA) data,
              private alertService: AlertService, private squareApiService: SquareApiService) {
    this.description = data.title;
    this.item = data.item;
  }

  ngOnInit() {
    this.loadAllModifierLists();
  }

  getSelectedMessage(modifierList: SquareModifierList): string {
    if (modifierList.selectionType === 'SINGLE') {
      return '(Select 1)';
    }
    return `(Min: ${modifierList.minSelected === -1 ? 0 : modifierList.minSelected} Max: ${modifierList.maxSelected === -1 ? modifierList.modifiers.length : modifierList.maxSelected})`;
  }

  save() {
    const selectedOptions: Modifier[] = [];
    this.item.modifiers.forEach(modList => {
      modList.modifiers?.forEach(mod => {
        if (mod.selected) {
          selectedOptions.push(mod);
        }
      });
    });
    this.dialogRef.close({
      selectedOptions: selectedOptions,
      note: this.customMessage,
      quantity: this.quantity
    });
  }

  onModifierChange(modifier: Modifier, modifierList: SquareModifierList) {
    if (modifierList.selectionType === 'SINGLE') {
      modifierList.modifiers.forEach(mod => mod.selected = false);
      modifier.selected = true;
      return;
    }

    modifier.selected = !modifier.selected;
  }

  modIsDisabled(modifier: Modifier, modifierList: SquareModifierList): boolean {
    return modifierList.selectionType === 'MULTIPLE'
      && !modifier.selected
      && modifierList
        .modifiers
        ?.filter(mod => mod.selected).length >= (modifierList.maxSelected === -1 ? modifierList.modifiers.length : modifierList.maxSelected);
  }

  orderBtnDisabled(): boolean {
    if (this.quantity <= 0) {
      return true;
    }
    let isDisabled = false;
    this.item.modifiers.forEach(mod => {
      const amountSelected: number = mod.modifiers?.filter(modifier => modifier.selected).length;
      if (amountSelected < mod.minSelected || amountSelected > (mod.maxSelected === -1 ? mod.modifiers?.length : mod.maxSelected)) {
        isDisabled = true;
      }
    });
    return isDisabled;
  }

  close() {
    this.dialogRef.close();
  }

  private loadAllModifierLists() {
    this.squareApiService.getModifierLists(this.item).pipe(first()).subscribe(item => {
        this.item = item;
        this.modifiers_loading = false;
      },
      error => {
        this.alertService.error(error);
      });
  }
}

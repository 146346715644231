import {LineItem} from "../../../src/app/_models/lineItem";

export class BumpedOrderModel {
  id: string;
  orderId: string;
  order: string;
  title: string;
  orgUuid: string;
  hidden: boolean;
  createdDate: Date;
  modifiedTime: Date;
  status: string;
  bumpedItems: LineItem[];

  constructor() {
  }
}

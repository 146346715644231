import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import {AuthenticationService} from '../_services';
import {AESEncryptDecryptService} from '../_services/aesencrypt-decrypt.service';
import * as _ from 'lodash';
import {UtilsService} from '../_services/utils.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private auth: AuthenticationService, private utilsService: UtilsService, private _AESEncryptDecryptService: AESEncryptDecryptService) {
  }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
      let currentUser;
      let qrDevice;
      let customer;
      let token;
      if (localStorage.getItem('currentUser')) {
        currentUser = this.utilsService.getCurrentUser();
        token = currentUser.token;
      } else if (sessionStorage.getItem('currentUser')) {
        qrDevice = this.utilsService.getCurrentUser();
        token = qrDevice.token;
      } else if (localStorage.getItem('Customer')) {
        customer = JSON.parse(this._AESEncryptDecryptService.decrypt(localStorage.getItem('Customer')));
        token = customer.token;
      }

      if (!currentUser && !qrDevice) {
        this.auth.user.subscribe(user => {
          currentUser = user;
          this.updateRequest(token, request, next);
        });
      }
      return this.updateRequest(token, request, next);
    }

  private updateRequest(token, request: HttpRequest<any>, next: HttpHandler) {
    if (!_.isNil(token)) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
          accept: 'application/json'
        }
      });
    }
    return next.handle(request);
  }
}

import {ChangeDetectorRef, Component, HostListener, OnInit} from '@angular/core';
import {User} from '../../../_models';
import {AlertService, AuthenticationService, UserService} from '../../../_services';
import {BehaviorSubject, Observable} from 'rxjs';
import {Item} from '../../../_models/Item';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {Router, RouterOutlet} from '@angular/router';
import {NotifierService} from 'angular-notifier';
import {LineItem} from '../../../_models/lineItem';
import {Category} from '../../../_models/category';
import {CloverApiService} from '../../../_services/cloverApi.service';
import {environment} from '../../../../environments/environment';
import {AESEncryptDecryptService} from '../../../_services/aesencrypt-decrypt.service';
import {isNil} from 'lodash';
import {AtmoicOrderService} from '../../../_services/atomic-order.service';
import {QrPayPopupComponent} from '../../../shared/components/qr-pay-popup/qr-pay-popup.component';
import {CloverOrderService} from '../../../_services/cloverOrder.service';
import {
    NotificationAndImagePopupComponent
} from '../../../shared/components/notification-and-image-popup/notification-and-image-popup.component';
import {SocketioService} from '../../../_services/socketio.service';
import {QrDeviceService} from '../../../_services/qrDevice.service';
import {QRModel} from '../../../_models/qrDevice';
import {Constants} from 'src/app/constants/constants';
import {UtilsService} from '../../../_services/utils.service';

@Component({
    selector: 'app-payment-user',
    templateUrl: './payment-user.component.html',
    styleUrls: ['./payment-user.component.scss']
})
export class PaymentUserComponent implements OnInit {
    readonly Constants = Constants;
    currentUser: User;
    role: String;
    selectedRole: any;
    screenHeight: number;
    private screenHeight$ = new BehaviorSubject<number>(window.innerHeight);
    @HostListener('window:resize', ['$event'])

    lineItems: LineItem[] = [];
    loading = true;
    adminUsers: any;
    adminUsersArray = [];
    private qrDevice: QRModel;

    onResize($event) {
        this.screenHeight$.next($event.target.innerHeight);
    }

    constructor(private dialog: MatDialog,
                private cloverApiService: CloverApiService,
                private userService: UserService,
                private router: Router,
                private ref: ChangeDetectorRef,
                private qrService: QrDeviceService,
                private socketService: SocketioService,
                private utilsService: UtilsService,
                private authService: AuthenticationService,
                private cloverOrderService: CloverOrderService,
                private notifier: NotifierService, private atmoicOrderService: AtmoicOrderService,
                private routerOutlet: RouterOutlet, private alertService: AlertService,
                private _AESEncryptDecryptService: AESEncryptDecryptService) {

        this.role = authService.role;
          this.currentUser = utilsService.getCurrentUser();
          this.selectedRole = this.currentUser.selectedRole;
    }


    ngOnInit() {
        this.setupQRDeviceForPayment();
        this.screenHeight$.subscribe(width => {
            this.screenHeight = width;
        });

        if (this.currentUser.takePayment.cloverDeviceId && this.currentUser.takePayment.cloverDeviceSerial) {
            // this.ccAuth.connect();
            this.cloverApiService.initKioskOnDevice(this.currentUser.takePayment.cloverDeviceId, this.currentUser.takePayment.cloverDeviceSerial).subscribe(() => {
            });
        } else {
            const alreadyAsked = localStorage.getItem('CCPaymentDisabled') === true.toString();
            if (!alreadyAsked) {
                this.showNotification('warning', 'To use a Clover Device to process payments, login as Admin and edit this device to add Device Association.');
                localStorage.setItem('CCPaymentDisabled', true.toString());
            }
        }
        this.getOrdersList();
    }

    getOrdersList(resetList?) {
        if (resetList) {
            this.lineItems = [];
        }
        this.loading = true;
        this.atmoicOrderService.getRecentOpenOrdersList(true).subscribe(response => {
                if (response?.length > 0) {
                    this.lineItems = [...this.lineItems, ...response];
                    this.loading = false;

                }
                this.loading = false;
            },
            error => {
                this.alertService.error(error);
                this.loading = false;
            });
    }

    isCloverConnectedPayment() {
      if (this.currentUser.takePayment.cloverConnectorPayment || (this.currentUser.takePayment.cloverDeviceId && this.currentUser.takePayment.cloverDeviceSerial)) {
            return true;
        } else {
            return false;
        }
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.clear();
        sessionStorage.clear();
        this.authService.logout();
        // console.log('in AuthenticateService logout'); //*MES*

        this.cloverApiService.cloverItemsWithImages = new Observable<Item[]>();
        this.cloverApiService.cloverCategoriesWithImages = new Observable<Category[]>();
        this.router.navigate(['']);

    }

    openQRPaymentRequiredDialog(title: string, message: string, orderId: string) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
            message: message,
            title: title,
            qrUrl: [`${environment.qrOrderURL}qr=` + encodeURIComponent(this._AESEncryptDecryptService.encrypt(this.currentUser.username)) + '&orderId=' + orderId]
        };

        const dialogRef = this.dialog.open(QrPayPopupComponent, dialogConfig);
        setTimeout(() => {
            dialogRef.close();
        }, 30000);
        dialogRef.afterClosed().subscribe(data => {
            this.getOrdersList(true);
        });
    }

    scanToPay(item) {
        this.openQRPaymentRequiredDialog('Scan To Pay', 'Scan To Pay', item.id);
    }

    creditCardPay(item) {
        if (this.selectedRole === 'PaymentsDisplay') {
            this.router.navigate(['payment/addTip',
                {
                    tableUuid: this.currentUser.uuid,
                    tableName: this.currentUser.username,
                    categoryUuid: '',
                    lineItemStatus: 'CreditCardPay',
                    itemTotal: item.total,
                    itemId: item.id
                }]);
        }
    }

    cloverPay(item) {
        // this.ccauth.setAmount(item.total);
        if (this.selectedRole === 'PaymentsDisplay') {
            this.router.navigate(['payment/addTip',
                {
                    tableUuid: this.currentUser.uuid,
                    tableName: this.currentUser.username,
                    categoryUuid: '',
                    lineItemStatus: 'CloverPay',
                    itemTotal: item.total,
                    itemId: item.id
                }]);
        }
    }

    cloverPayV2(item) {
        this.cloverOrderService.payOrderOnDevice(item.id, item.total,
            this.currentUser.takePayment.cloverDeviceId, this.currentUser.takePayment.cloverDeviceSerial, true)
            .subscribe(response => {
            });
        this.openPaymentOnDeviceMessage('../../../assets/clover-devices.png',
            'Complete Payment On Connected Clover Device', '', item.id, item.total);
    }

    openPaymentOnDeviceMessage(imgSrc: string, title: string, message: string, orderId: string, orderTotal: number) {

        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;

        dialogConfig.data = {
            message: message,
            title: title,
            orderId: orderId,
            orderTotal: orderTotal,
            imgSrc: imgSrc
        };

        const dialogRef = this.dialog.open(NotificationAndImagePopupComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(data => {
        });
    }

    setupQRDeviceForPayment() {
        this.qrService.updateQRDevice(this.currentUser).subscribe(response => {
                this.qrDevice = response;
            },
            error => {
                throw new Error(error);
            });
    }

    public showNotification(type: string, message: string): void {
        this.notifier.notify(type, message);
    }
}

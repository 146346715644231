<div class="navigation-details-panel" [ngClass]="{'sidebar-adjust-header': (selectedRole ===Constants.MANAGER ||selectedRole === 'Admin' || selectedRole ===Constants.TABLE || selectedRole ===Constants.SERVER) }">
  <div class="back-button" *ngIf="selectedRole === Constants.KIOSK">
    <i class="material-icons" routerLink="/kiosk">
      arrow_back
    </i>
  </div>
</div>

<div class="parent">
  <form [formGroup]="logoutForm" class="element text-center border border-light p-5" (ngSubmit)="onSubmit()">
    <img class="i1" src="./assets/default-logo.png" alt="logo"/>
    <p class="h4 mb-4">SIGN OUT</p>
    <div *ngIf="selectedRole === Constants.TABLE" style="color: red; padding-bottom: 10px;">
      Tables Must use Admin password or OTP to logout.
    </div>
    <!-- Password -->
    <input formControlName="password" type="password" class="form-control mb-4"
           placeholder="Password"
           [ngClass]="{ 'is-invalid': submitted && f.password.errors }"/>
    <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
      <div *ngIf="f.password.errors.required">Password is required</div>
    </div>

    <!-- Sign out button -->
    <button mdbBtn color="info" block="true" class="my-4" type="submit" [disabled]="loading">Sign out
      <mat-spinner class="spinner-loader" diameter="20" *ngIf="loading"></mat-spinner></button>
    <!-- Error Div -->
    <div *ngIf=error style="color: red">
      Invalid password.
    </div>

  </form>
</div>

import {Component, OnInit, ViewChild} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService, AuthenticationService } from '../../_services/index';
import { User } from '../../_models/index';
import { Image } from '../../_models/image';
import { AESEncryptDecryptService } from '../../_services/aesencrypt-decrypt.service';
import {Category} from '../../_models/category';
import { Constants } from 'src/app/constants/constants';

@Component({
  selector: 'app-reset-password-page',
  styleUrls: ['reset-password.component.scss'],
  templateUrl: 'reset-password.component.html'
})
export class ResetPasswordComponent implements OnInit {

  otp: string;
  codeVerified = false;
  showOtpComponent = true;
  @ViewChild('ngOtpInput', { static: false }) ngOtpInput: any;
  config = { allowNumbersOnly: true, length: 4, isPasswordInput: false, disableAutoFocus: false, placeholder: '*', inputStyles: { width: '50px', height: '50px', }, };
  Constants = Constants;
  email: string;
  alertEnabled = false;
  private code: string;
  private passwordResetEmailMissing: boolean;
  resendCodeDisabled = true;
  private number: string;
  private isAdmin = false;
  private userId: string;
  password: any;
  passwordConfirm: any;
  errorMessage: string;


  constructor(
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private _AESEncryptDecryptService: AESEncryptDecryptService) {

    const url_string = window.location.href;
    const url = new URL(url_string);
  }

  ngOnInit() {
    this.route.paramMap.subscribe( paramMap => {
      this.email = paramMap.get('email');
      this.number = paramMap.get('number');
      this.userId = paramMap.get('id');
      this.isAdmin = paramMap.get('admin') === 'true';
      //Todo: clear query params;
    });
    this.resetRegenerateCodeDisable();
  }

  private resetRegenerateCodeDisable() {
    setTimeout(() => {
      this.resendCodeDisabled = false;
    }, 60000);
  }

  onOtpChange(value) {
    if (value.length === 5) {
      this.verifyToken(value);
    }
  }

  verifyToken(code) {
    this.authenticationService.verifyOTC(code, this.email).subscribe(success => {
      console.log('code Valid');
      this.showOtpComponent = false;
      this.codeVerified = true;
      this.code = code;
    }, err => {
      this.alertEnabled = true;
      this.errorMessage = err.message;
      console.log('code inValid');
    });
  }

  resetPassword() {
    console.log('password is ' + this.password);
    console.log('password confirmation is ' + this.passwordConfirm);
    if (this.password === this.passwordConfirm) {
      this.authenticationService.resetPassword(this.code, this.password, this.userId).subscribe(success => {
        this.router.navigate(['/login']);
        this.showOtpComponent = false;
      }, err => {
        this.alertEnabled = true;
        console.log('Password Reset Failed');
      this.errorMessage = err.message;
        console.error('Error ', err);
      });
    } else {
      this.errorMessage = 'Passwords Don\'t Match';
    }
  }

  regenerateOTC() {
    this.authenticationService.generateOTC(this.email).subscribe(data => {
      this.email = data.email;
      this.userId = data.id;
      this.number = data.number;
      this.resendCodeDisabled = true;
      this.resetRegenerateCodeDisable();
    }, err => {
      this.alertEnabled = true;
      this.errorMessage = err.message;
      console.log('Password Reset Failed');
      console.error('Error ', err);
    });
  }
}

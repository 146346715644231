import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {environment} from '../../environments/environment';
import {OrderHistory} from '../_models/orderHistory';
import {Order} from '../_models/order';
import {Observable} from 'rxjs';
import {BumpedOrderModel} from "../_models/bumpedOrderModel";

@Injectable({ providedIn: 'root' })
export class BumpedOrderService {

  constructor(private http: HttpClient) {
  }

  getAll() {
    const response = this.http.get<BumpedOrderModel[]>(`${environment.apiUrl}/bumpedOrder/getAll`);
    return response;
  }

  getById(id: number) {
    return this.http.get(`${environment.apiUrl}/bumpedOrder/${id}`);
  }

  getByDate(startDate: Date, endDate: Date) {
    return this.http.get<BumpedOrderModel[]>(`${environment.apiUrl}/bumpedOrder/date/${startDate}/${endDate}`);
  }

  bumpOrder(bumpedOrder: BumpedOrderModel) {
    return this.http.post(`${environment.apiUrl}/bumpedOrder/create`,bumpedOrder );
  }

  delete(id: string) {
    return this.http.delete(`${environment.apiUrl}/bumpedOrder/${id}`);
  }

  update(order: BumpedOrderModel) {
    return this.http.put(`${environment.apiUrl}/bumpedOrder/${order.id}`, order);
  }

}


<h2 mat-dialog-title>{{description}}{{selectedState | titlecase}}</h2>
<mat-dialog-content>
  <div class="dialog-options-container">
    <div class="error-message">{{errorMessage}}</div>
    <input *ngIf="selectedState === 'amount'" type="text" step="0.01" class='name-input' id='amountEntry' placeholder="Enter Amount" [ngModel]="inputValue" (ngModelChange)="modelChange($event)">
    <span *ngIf="selectedState === 'percent'" class="percent-input"><input matInput type="number" class="name-input" placeholder="Enter Percent" [(ngModel)]="inputValue">%</span>
  </div>
  <mat-button-toggle-group class="type-toggle" [value]="selectedState" (change)="onChange($event)" name="fontStyle" aria-label="Font Style">
    <mat-button-toggle value="amount">Amount</mat-button-toggle>
    <mat-button-toggle value="percent">Percentage</mat-button-toggle>
  </mat-button-toggle-group>
</mat-dialog-content>
  <div class="button-container">
    <button mdbBtn color="danger" block="true" class="my-4 mr-2 action-button" (click)="close()">Cancel</button>
    <button mdbBtn [disabled]="loading"  color="info" block="true" class="my-4 action-button" (click)="submit()">{{button_text}}</button>
  </div>

import {PaymentLink} from "./paymentLinks";

export class Reservation {
    _id: string;
    orgUuid: string;
    name: string;
    tableLock: boolean;
    assignedEmployeeIds: string[];
    assignedEmployeeUsernames: string[];
    createdEmployeeId:string;
    orderIds: string[];
    note: string;
    tableName: string;
    paymentLinks: PaymentLink[];
    tableId: string;
    time: number;
    createdDate: number;
}

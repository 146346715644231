<mat-toolbar color="primary">
    <div class="navigation-details-panel dark-orange-color">
        <div></div>
        <mat-card-title *ngIf="!error && editCustomer && !this.rewardsSignUpComplete">Customer Information</mat-card-title>
        <mat-card-title *ngIf="promo && this.rewardsSignUpComplete">Sign Up Complete</mat-card-title>
        <mat-card-title *ngIf="error">Something Went Wrong</mat-card-title>
        <div></div>
    </div>
</mat-toolbar>
<div *ngIf="!this.rewardsSignUpComplete && !error" class="register-page-container">
    <form [formGroup]="addressForm" novalidate (ngSubmit)="onSubmit()">
        <mat-card class="shipping-card">
            <mat-card-content>
                <div class="row">
                    <div class="col">
                        <mat-form-field class="full-width">
                            <input matInput placeholder="First name" formControlName="firstName">
                            <mat-error *ngIf="f.firstName.hasError('required')">
                                First name is <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <mat-form-field class="full-width">
                            <input matInput placeholder="Last name" formControlName="lastName">
                            <mat-error *ngIf="f.lastName.hasError('required')">
                                Last name is <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <mat-form-field class="full-width">
                            <input matInput placeholder="email" formControlName="primaryEmail">
                            <mat-error *ngIf="f.primaryEmail.hasError('required')">
                                Email is <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <mat-form-field class="full-width">
                            <input type="password" matInput placeholder="Password" formControlName="password">
                            <mat-error *ngIf="f.lastName.hasError('required')">
                                Last name is <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <mat-form-field formGroupName="address" class="full-width">
                            <textarea matInput placeholder="Address" formControlName="line1"></textarea>
                            <mat-error *ngIf="addressForm.get('address.line1').hasError('required')">
                                Address is <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row" *ngIf="!hasUnitNumber">
                    <div class="col">
                        <button mat-button type="button" (click)="hasUnitNumber = !hasUnitNumber">
                            + Add C/O, Apt, Suite, Unit
                        </button>
                    </div>
                </div>
                <div class="row" *ngIf="hasUnitNumber">
                    <div class="col">
                        <mat-form-field formGroupName="address" class="full-width">
                            <textarea matInput placeholder="Address 2" formControlName="line2"></textarea>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <mat-form-field formGroupName="address" class="full-width">
                            <input matInput placeholder="City" formControlName="city">
                            <mat-error *ngIf="addressForm.get('address.city').hasError('required')">
                                City is <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <mat-form-field formGroupName="address" class="full-width">
                            <mat-select id="state" placeholder="State" formControlName="state">
                                <mat-option *ngFor="let state of states" [value]="state.abbreviation">
                                    {{ state.name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="addressForm.get('address.state').hasError('required')">
                                State is <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <mat-form-field formGroupName="address" class="full-width">
                            <input id="zip" matInput #postalCode maxlength="5" placeholder="Zip Code" type="string"
                                   formControlName="postal_code">
                            <mat-hint align="end">{{postalCode.value.length}} / 5</mat-hint>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <mat-form-field formGroupName="address" class="full-width">
                            <mat-label>Country</mat-label>
                            <select matNativeControl formControlName="country">
                                <option value=""></option>
                                <option *ngFor="let country of countries;" [value]="country.code">{{ country.name }}</option>
                            </select>
                        </mat-form-field>
                    </div>
                </div>
            </mat-card-content>
            <mat-card-actions>
                <button mdbBtn color="info" block="true" class="submit-button">Submit</button>
            </mat-card-actions>
        </mat-card>
    </form>
</div>
<div class="message-container" *ngIf="message">
    <div>{{message}}</div>
</div>

<button class="submit-button" button *ngIf="rewardsSignUpComplete" mdbBtn style="background: #F88D5E; color: white"  block="true" (click)="window.location.assign('https://google.com')">Finish Registration</button>
<button class="submit-button" button *ngIf="error" color="danger" mdbBtn style="color: white"  block="true" (click)="window.location.assign('https://google.com')">Cancel</button>

import {LineItem} from './lineItem';
import {Order} from "./order";

export class PaymentPayload {
  apple_pay: boolean;
  username: string;
  cash_app_pay: boolean;
  google_pay: boolean;
  order: Order;
  createOpenOrder: boolean;
  allow_tipping: boolean;
  redirect_url: string;
  idempotencyKey: string;
  note: string;
  currency: string;
  description: string;
  location_id: string;
  lineItems: LineItem[];
}

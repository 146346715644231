import {NgModule, Type} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {HTTP_INTERCEPTORS, HttpClient} from '@angular/common/http';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
// used to create fake backend
import {AppComponent} from './app.component';
import {routing} from './app.routing';
import {AuthGuard} from './_guards';
import {ErrorInterceptor, JwtInterceptor} from './_helpers';
import {AuthenticationService, UserService} from './_services';
import {MDBBootstrapModule} from 'angular-bootstrap-md';
import {LazyScrollDirective} from './shared/directives/lazy-scroll/lazy-scroll.directive';
import {NotificationService} from './shared/services/notification.service';
import {BrowserNotificationService} from './shared/services/browser-notification.service';
import {CartService} from './_services/cart.service';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {ChartistModule} from 'ng-chartist';
import {ServerRequestService} from './_services/serverRequest.service';
import {GuidedTourService} from 'ngx-guided-tour';
import {SharedModule} from './shared/shared.module';
import {CommonModule, DatePipe} from '@angular/common';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {KioskContainerComponent} from './otherRoles/kiosk/kiosk-container/kiosk-container.component';
import {QrOrderComponent} from './qr-order/qr-order.component';
import {QRCodeModule} from 'angularx-qrcode';
import {CloverAuthRouterModule} from './auth-router/clover-auth-router.module';
import {RatingService} from './_services/ratings.service';
import {AESEncryptDecryptService} from './_services/aesencrypt-decrypt.service';
import {PaymenUserModule} from './otherRoles/paymentUser/payment-user/paymentUser.module';
import {OrderUserModule} from './otherRoles/orderUser/order-user/orderUser.module';
import {SocketioService} from './_services/socketio.service';
import {QrDeviceService} from './_services/qrDevice.service';
import {ToastrModule} from 'ngx-toastr';
import {MatDialogModule} from '@angular/material/dialog';
import {TableSetupComponent} from './sidebar-menu/table/table-setup/table-setup.component';
import {CartServiceSquare} from './_models/cart.service.square';
import {ViewCartSquareComponent} from './otherRoles/order/view-cart-square/view-cart-square.component';
import {NgOtpInputModule} from 'ng-otp-input';
import {NotifierModule, NotifierOptions} from 'angular-notifier';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatSelectModule} from '@angular/material/select';
import {MatRadioModule} from '@angular/material/radio';
import {MatCardModule} from '@angular/material/card';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatIconModule} from '@angular/material/icon';
import {JukeboxSocketioService} from './_services/jukeboxSocketio.service';
import {CartAndOrderingService} from './_services/cartAndOrderingService';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';;
import { HomeComponent } from './otherRoles/home/home.component'
import {NgImageSliderModule} from 'ng-image-slider';
import {_MatMenuDirectivesModule, MatMenuModule} from '@angular/material/menu';

const materialModules = [
    MatToolbarModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    MatRadioModule,
    MatCardModule,
    MatIconModule,
    NgxMaterialTimepickerModule,
    ReactiveFormsModule,
    FormsModule
];

const customNotifierOptions: NotifierOptions = {
    position: {
        horizontal: {
            position: 'right',
            distance: 12
        },
        vertical: {
            position: 'top',
            distance: 75,
            gap: 10
        }
    },
    theme: 'material',
    behaviour: {
        autoHide: 4200,
        onClick: 'hide',
        onMouseover: 'pauseAutoHide',
        showDismissButton: true,
        stacking: 4
    },
    animations: {
        enabled: true,
        show: {
            preset: 'slide',
            speed: 300,
            easing: 'ease'
        },
        hide: {
            preset: 'fade',
            speed: 300,
            easing: 'ease',
            offset: 50
        },
        shift: {
            speed: 300,
            easing: 'ease'
        },
        overlap: 150
    }
};
@NgModule({
    imports: [
        MDBBootstrapModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        SharedModule,
        NgbModule,
        QRCodeModule,
        ChartistModule,
        BrowserModule,
        NotifierModule,
        ToastrModule.forRoot(),
        PaymenUserModule,
        NgOtpInputModule,
        NgImageSliderModule,
        NotifierModule.withConfig(customNotifierOptions),
        OrderUserModule,
        BrowserAnimationsModule,
        CloverAuthRouterModule,
        routing,
        CommonModule,
        NgMultiSelectDropDownModule.forRoot(),
        MatDialogModule,
        ...materialModules,
        _MatMenuDirectivesModule,
        MatMenuModule
    ],
    declarations: [
        AppComponent,
        LazyScrollDirective,
        KioskContainerComponent,
        QrOrderComponent,
        TableSetupComponent
,
        HomeComponent    ],
    providers: [
        AuthGuard,
        AuthenticationService,
        ViewCartSquareComponent,
        UserService,
        AESEncryptDecryptService,
        GuidedTourService,
        CartService,
        CartServiceSquare,
        CartAndOrderingService,
        JukeboxSocketioService,
        DatePipe,
        CloverAuthRouterModule,
        ServerRequestService,
        NotificationService,
        QrDeviceService,
        RatingService,
        BrowserNotificationService,
        SocketioService,
        {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true}

    ],
    bootstrap: [AppComponent]
})

export class AppModule {
}

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

declare module '@angular/core' {

    interface ModuleWithProviders<T = any> {
        ngModule: Type<T>;
    }
}

import {Injectable} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {Reservation} from 'src/app/_models/reservation';
import {Constants} from 'src/app/constants/constants';
import {NotifierService} from 'angular-notifier';
import {User} from '../_models';
import {LineItem} from '../_models/lineItem';
import {Order} from '../_models/order';
import {Customer} from '../_models/customer';
import {QRModel} from '../_models/qrDevice';
import {CartService} from './cart.service';
import {CloverOrderService} from './cloverOrder.service';
import {CloverApiService} from './cloverApi.service';
import {AlertService, AuthenticationService} from '.';
import {AESEncryptDecryptService} from './aesencrypt-decrypt.service';
import {ReservationService} from './reservation.service';
import {CustomerRewardsService} from './customerRewards.service';
import {QrDeviceService} from './qrDevice.service';
import {DeviceDetectorService} from 'ngx-device-detector';
import {AtmoicOrderService} from './atomic-order.service';
import {Discount} from '../_models/discount';
import {NotificationAndImagePopupComponent} from '../shared/components/notification-and-image-popup/notification-and-image-popup.component';
import {environment} from '../../environments/environment';
import {
    NotificationPaymentRequiredQrPopupComponent
} from '../shared/components/notification-payment-required-qr-popup/notification-payment-required-qr-popup.component';
import {Item} from '../_models/Item';
import {Category} from '../_models/category';
import {UtilsService} from './utils.service';

@Injectable({
    providedIn: 'root'
})
export class CartAndOrderingService {
    newSignupDiscount = 10;
    Constants = Constants;
    currentUser: User;
    lineItems: LineItem[] = [];
    loading = false;
    loadingButton = false;
    tableUuid: string;
    tableName: string;
    categoryUuid: string;
    cloverOrder: Order = null;
    orderId: string;
    rewardsRedeemed = false;
    printFired = false;
    rewardsUpdated = false;
    total: number;
    localMerchantId: string;
    rewardsCustomer: Customer;
    storedMerchantId: string;
    storedCloverToken: string;
    columnsToDisplay: string[] = ['Item', 'Price', 'Qty'];
    selectedRole: String;
    orderids: any;
    totalamount: any;
    socket: any;
    data: any;
    qrDevice: QRModel;

    constructor(
        private cartService: CartService,
        private cloverOrderService: CloverOrderService,
        private cloverApiService: CloverApiService,
        private authenticationService: AuthenticationService,
        private router: Router,
        private alertService: AlertService,
        private _AESEncryptDecryptService: AESEncryptDecryptService,
        private activatedRoute: ActivatedRoute,
        private notifier: NotifierService,
        private reservationService: ReservationService,
        private dialog: MatDialog,
        private qrService: QrDeviceService,
        private rewardsService: CustomerRewardsService,
        private deviceService: DeviceDetectorService,
        private atmoicOrderService: AtmoicOrderService,
        private utilsService: UtilsService
    ) {
        this.currentUser = utilsService.getCurrentUser();
        this.selectedRole = this.currentUser?.selectedRole;

        this.initCartFunctions();
    }

    initCartFunctions() {
        this.loadRewards();
        if (this.selectedRole === Constants.KIOSK && this.currentUser.takePayment.devicePaymentEnabled
            && this.currentUser.takePayment.paymentRequired && this.currentUser.takePayment.scanToPayPayment) {
            this.setupQRDeviceForPayment();
        }
        //TODO: Activated route removal
        this.loadAllInCart();
        if (localStorage.getItem('CartItem')) {
            this.lineItems = JSON.parse(this._AESEncryptDecryptService.decrypt(localStorage.getItem('CartItem')));
        }
    }

    loadAllInCart() {
        this.cartService
            .getItems()
            .subscribe(lineItem => {
                    this.lineItems = lineItem;
                    if (this.selectedRole !== Constants.KIOSK) {
                        // this.findUnpaidOrder();
                    } else {
                        this.loading = false;
                        this.loadingButton = false;
                    }
                },
                error => {
                    this.alertService.error(error);
                    this.alertService.error(error);
                    this.loading = false;
                });
    }

    setupQRDeviceForPayment() {
        this.qrService.updateQRDevice(this.currentUser).subscribe(response => {
                this.qrDevice = response;
            },
            error => {
                throw new Error(error);
            });
    }

    submitOrder() {
        this.loadingButton = true;
        const orderType = localStorage.getItem('orderType');

        if ((this.selectedRole === Constants.KIOSK || this.selectedRole === Constants.QR_ORDER)
            && (this.currentUser.takePayment.paymentRequired && this.currentUser.takePayment.devicePaymentEnabled)
            && !this.currentUser.takePayment.cloverConnectorPayment) {
            this.tableName = this.currentUser.firstName;
            this.routeToNextPage(this.currentUser);
            return;
        }
        if (this.selectedRole === Constants.TABLE || this.selectedRole === 'Admin' || this.selectedRole === Constants.MANAGER
            || this.selectedRole === Constants.SERVER ||
            (this.selectedRole === Constants.KIOSK && orderType === Constants.DINE_IN
                && (!this.currentUser.takePayment.cloverConnectorPayment)) || (this.selectedRole === Constants.QR_ORDER
                && orderType === Constants.DINE_IN) && !this.currentUser.takePayment.devicePaymentEnabled) {
            const NewLineItems = [];
            for (let i = 0; i < this.lineItems.length; i++) {
                let newquantity = this.lineItems[i].quantity ? this.lineItems[i].quantity : 1;
                while (newquantity > 0) {
                    this.lineItems[i].image = '';
                    NewLineItems.push(this.lineItems[i]);
                    newquantity--;
                }
            }
            const data = {
                'orderCart': {
                    'employee': {
                        'id': this.currentUser.employeeId
                    },
                    'note': this.setNote(),
                    'lineItems': NewLineItems
                }
            };
            this.findAndUpdateUnpaidCloverOrder(data, true);
        } else {
            this.paymentNotRequiredOrderSubmit();
        }
    }

    websiteCheckout(orderTitle, note?) {
        const NewLineItems = [];
        for (let i = 0; i < this.lineItems.length; i++) {
            let newquantity = this.lineItems[i].quantity ? this.lineItems[i].quantity : 1;
            while (newquantity > 0) {
                this.lineItems[i].image = '';
                NewLineItems.push(this.lineItems[i]);
                newquantity--;
            }
        }
        const data = {
            'orderCart': {
                'employee': {
                    'id': this.currentUser.employeeId
                },
                'note': note ? note : '',
                'lineItems': NewLineItems
            }
        };

        return this.atmoicOrderService.atomicOrderCheckoutV3(data, true, orderTitle).toPromise();
        // .then(response => {
        //     if (response) {
        //         this.orderids = response.id;
        //         this.orderId = response.id;
        //         this.cartService.cloverOrder = response;
        //         this.totalamount = response.total;
        //         this.firePrintAndCompleteOrder(response.id, true);
        //     }
        // });
    }

    findAndUpdateUnpaidCloverOrder(data, completeOrder?) {
        if (this.tableName === undefined || this.tableName === null) {
            this.loading = false;
            this.loadingButton = false;
            console.error('No Table Selected');
            return;
        }

        this.cloverOrderService.getUnpaidOrder(this.tableName)
            .subscribe(order => {
                    if (order.order) {
                        this.cloverOrder = order.order;
                        this.cartService.cloverOrder = (this.cloverOrder);
                        this.orderId = this.cloverOrder.id;
                        this.atmoicOrderService.updateAtomicOrder(this.cloverOrder, this.lineItems).subscribe(updatedOrder => {
                            this.finishOrderAndRoute();
                        });
                    } else {
                        this.atmoicOrderService.atomicOrderCheckoutV3(data, true, this.tableName)
                            .subscribe(response => {
                                if (response) {
                                    this.orderId = response.id;
                                    this.cartService.cloverOrder = response;
                                    this.cloverOrder = response;
                                    this.totalamount = response.total;
                                    this.orderids = response.id;
                                    this.totalamount = response.total;
                                    if (!this.currentUser.takePayment.devicePaymentEnabled &&
                                        this.currentUser.orgSettings.rewards.rewardsEnabled) {
                                        this.firePrintAndCompleteOrderWithRewards(response.id, true);
                                    } else if (!this.currentUser.takePayment.devicePaymentEnabled &&
                                        !this.currentUser.orgSettings.rewards.rewardsEnabled) {
                                        this.firePrintAndCompleteOrderWithRewards(response.id, false);
                                    } else if (this.currentUser.takePayment.devicePaymentEnabled) {
                                        if (this.selectedRole === Constants.KIOSK && this.currentUser.takePayment.paymentRequired &&
                                            this.currentUser.takePayment.cloverConnectorPayment) {
                                            setTimeout(() => {
                                                this.cloverOrderService.payOrderOnDevice(response.id, response.total,
                                                    this.currentUser.takePayment.cloverDeviceId,
                                                    this.currentUser.takePayment.cloverDeviceSerial,
                                                    true).subscribe(() => {
                                                    this.openPaymentOnDeviceMessage('../../../assets/clover-devices.png',
                                                        'Complete Payment On Connected Clover Device', '', this.orderids);
                                                });
                                            }, 1000);
                                        } else {
                                            this.firePrintAndCompleteOrder(response.id);
                                        }
                                    } else {
                                        this.clearDataAndRouteToNextComnponent();
                                    }
                                }
                            });
                    }
                },
                error => {
                    this.alertService.error(error);
                    this.loading = false;
                });

        return;
    }

    firePrintAndFinishOrder() {
        if (((this.selectedRole !== Constants.KIOSK && this.selectedRole !== Constants.QR_ORDER) || ((this.selectedRole === Constants.KIOSK
            || this.selectedRole === Constants.QR_ORDER) && !this.currentUser.takePayment.devicePaymentEnabled)) && !this.printFired) {
            this.cloverOrderService.fireOrder(this.orderId, this.currentUser.orgSettings.featuresEnabled.v1Print).subscribe(data => {
                this.printFired = true;
                this.finishOrderAndRoute();
            });
        } else {
            this.finishOrderAndRoute();
        }
    }

    private finishOrderAndRoute() {
        this.cartService.SetOrderHistoryToCart();
        this.lineItems.forEach(item => {
            this.cartService.removeFromCart(item);
        });
        this.orderPlaced();
    }

    private paymentNotRequiredOrderSubmit() {
        const NewLineItems = [];
        for (let i = 0; i < this.lineItems.length; i++) {
            let newquantity = this.lineItems[i].quantity ? this.lineItems[i].quantity : 1;
            while (newquantity > 0) {
                this.lineItems[i].image = '';
                NewLineItems.push(this.lineItems[i]);
                newquantity--;
            }
        }
        const data = {
            'orderCart': {
                'employee': {
                    'id': this.currentUser.employeeId,
                },
                'note': this.setNote(),
                'lineItems': NewLineItems,
            }
        };
        const todaysDate = new Date().setHours(0, 0, 0, 0);

        if (this.rewardsCustomer?.signUpOfferEnabled && (todaysDate !== this.rewardsCustomer.lastRedeemedDate)) {
            const discount = new Discount();
            discount.name = 'Registration Promo';
            discount.percentage = Number(this.newSignupDiscount);
            this.createAndCompleteAtomicOrder(data, discount);
        } else if ((this.currentUser.orgSettings.rewards.rewardsEnabled &&
            (this.rewardsCustomer?.points >= this.currentUser.orgSettings.rewards?.afterVisits)
            && !this.rewardsRedeemed) && (todaysDate !== this.rewardsCustomer.lastRedeemedDate)) {
            const discount = new Discount();
            discount.name = '$' + Number(this.currentUser.orgSettings.rewards.amountOff) / 100 + ' off after '
                + Number(this.currentUser.orgSettings.rewards.afterVisits) + ' visits';
            discount.amount = Number(this.currentUser.orgSettings.rewards.amountOff);
            this.createAndCompleteAtomicOrder(data, discount);
        } else {
            this.createAndCompleteAtomicOrder(data);
        }
    }

    private createAndCompleteAtomicOrder(data: any, discount?) {
        this.atmoicOrderService.atomicOrderCheckoutV3(data, true, this.currentUser.firstName, discount)
            .subscribe(response => {
                if (response) {
                    this.orderids = response.id;
                    this.orderId = response.id;
                    this.cartService.cloverOrder = response;
                    this.totalamount = response.total;
                    if (this.selectedRole === Constants.KIOSK && this.currentUser.takePayment.paymentRequired &&
                        this.currentUser.takePayment.cloverConnectorPayment) {
                        setTimeout(() => {
                            this.cloverOrderService.payOrderOnDevice(response.id, response.total,
                                this.currentUser.takePayment.cloverDeviceId, this.currentUser.takePayment.cloverDeviceSerial,
                                true).subscribe(() => {
                                this.openPaymentOnDeviceMessage('../../../assets/clover-devices.png',
                                    'Complete Payment On Connected Clover Device', '', this.orderId);
                            });
                        }, 1000);
                    } else {
                        this.firePrintAndCompleteOrder(response.id);
                    }
                }
            });
    }

    firePrintAndCompleteOrder(orderId, disableRoute?) {
        if (!this.currentUser.takePayment.paymentRequired || disableRoute) {
            this.cloverOrderService.fireOrder(orderId, this.currentUser.orgSettings.featuresEnabled.v1Print).subscribe(response => {
                this.processRewards(disableRoute);
            });
        } else {
            this.processRewards(disableRoute);
        }
    }

    firePrintAndCompleteOrderWithRewards(orderId, processRewards) {
        if (!this.currentUser.takePayment.paymentRequired) {
            this.cloverOrderService.fireOrder(orderId, this.currentUser.orgSettings.featuresEnabled.v1Print).subscribe(response => {
                if (processRewards) {
                    this.processRewards();
                } else {
                    this.clearDataAndRouteToNextComnponent();
                }
            });
        } else {
            if (processRewards) {
                this.processRewards();
            } else {
                this.clearDataAndRouteToNextComnponent();
            }
        }
    }

    openPaymentOnDeviceMessage(imgSrc: string, title: string, message: string, orderId: string) {

        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;

        dialogConfig.data = {
            message: message,
            title: title,
            orderId: orderId,
            imgSrc: imgSrc
        };

        const dialogRef = this.dialog.open(NotificationAndImagePopupComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(data => {
            this.loadingButton = false;
        });
    }

    openQRPaymentRequiredDialog(title: string, message: string, orderId: string) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
            message: message,
            title: title,
            orderId: orderId,
            qrUrl: [`${environment.qrOrderURL}qr=` + encodeURIComponent(this._AESEncryptDecryptService.encrypt(this.currentUser.username)) + '&orderId=' + orderId]
        };

        const dialogRef = this.dialog.open(NotificationPaymentRequiredQrPopupComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(data => {
            this.loadingButton = false;
        });
    }

    processRewards(disableRoute?) {
        const todaysDate = new Date().setHours(0, 0, 0, 0);
        if (this.selectedRole === Constants.KIOSK && this.rewardsCustomer?.number > 0 &&
            (todaysDate !== this.rewardsCustomer.lastRedeemedDate)) {
            if (this.rewardsCustomer?.signUpOfferEnabled) {
                const discount = new Discount();
                discount.name = 'Registration Promo';
                discount.percentage = Number(this.newSignupDiscount);
                const total = (this.totalamount - ((this.totalamount * .2)));
                this.rewardsService.redeem(Number(this.rewardsCustomer.number), this.orderId,
                    discount, total, this.currentUser.orgSettings.rewards.afterVisits)
                    .subscribe(response => {
                            if (response) {
                                if (response.notification) {
                                    this.showNotification('warning', response.notification);
                                }
                                this.rewardsRedeemed = true;
                                this.clearDataAndRouteToNextComnponent(disableRoute);
                            }
                        },
                        error => {
                            this.showNotification('error', error);
                            this.clearDataAndRouteToNextComnponent(disableRoute);
                        });
            } else if (this.currentUser.orgSettings.rewards.rewardsEnabled &&
                (this.rewardsCustomer.points < this.currentUser.orgSettings.rewards.afterVisits) && !this.rewardsUpdated) {
                this.rewardsUpdated = true;
                this.rewardsService.checkin(Number(this.rewardsCustomer.number),
                    this.rewardsCustomer.name).subscribe((response) => {
                        if (response) {
                            if (response.notification) {
                                this.showNotification('warning', response.notification);
                            }
                            this.clearDataAndRouteToNextComnponent(disableRoute);
                        }
                    },
                    error => {
                        this.showNotification('error', error);
                        this.clearDataAndRouteToNextComnponent(disableRoute);
                    });
            } else if (this.currentUser.orgSettings.rewards.rewardsEnabled &&
                (this.rewardsCustomer.points >= this.currentUser.orgSettings.rewards.afterVisits) && !this.rewardsRedeemed) {
                const discount = new Discount();
                discount.name = '$' + Number(this.currentUser.orgSettings.rewards.amountOff) / 100 + ' off after '
                    + Number(this.currentUser.orgSettings.rewards.afterVisits) + ' visits';
                discount.amount = Number(this.currentUser.orgSettings.rewards.amountOff);
                const total = this.totalamount + discount.amount;
                this.rewardsService.redeem(Number(this.rewardsCustomer.number), this.orderId,
                    discount, total, this.currentUser.orgSettings.rewards.afterVisits)
                    .subscribe(response => {
                            if (response) {
                                if (response.notification) {
                                    this.showNotification('warning', response.notification);
                                }
                                this.rewardsRedeemed = true;
                                this.clearDataAndRouteToNextComnponent(disableRoute);
                            }
                        },
                        error => {
                            this.showNotification('error', error);
                            this.clearDataAndRouteToNextComnponent(disableRoute);
                        });
            }
        } else {
            this.clearDataAndRouteToNextComnponent(disableRoute);
        }
    }

    orderPlaced() {
        this.rewardsService.customer = new Observable<Customer>();
        this.clearDataAndRouteToNextComnponent();
    }

    private clearDataAndRouteToNextComnponent(disableRoute?) {
        let oldUser;
        if (this._AESEncryptDecryptService.decrypt(localStorage.getItem('oldUser'))) {
            oldUser = JSON.parse(this._AESEncryptDecryptService.decrypt(localStorage.getItem('oldUser')));
        }

        if (oldUser) {
            localStorage.setItem('currentUser', this._AESEncryptDecryptService.encrypt(JSON.stringify(oldUser)));
            localStorage.removeItem('oldUser');
        }
        this.rewardsService.customer = new Observable<Customer>();
        this.cartService.removeAllFromCart();
        this.cartService.removeOrderHistory();
        localStorage.setItem('CartItem', '');
        this.cartService.tip = 0;
        // this.alertService.success('User Created', true);
        this.loadingButton = false;
        this.loading = true;

        if (oldUser && !disableRoute) {
            this.routeToNextPage(oldUser);
        } else if (!disableRoute) {
            this.routeToNextPage(this.currentUser);
        }
    }

    private routeToNextPage(oldUser?) {
        if (this.selectedRole === Constants.TABLE) {
            this.lockTableWithReservation();
        } else if (this.selectedRole === 'Admin' || this.selectedRole === Constants.MANAGER || this.selectedRole === Constants.SERVER) {
            this.lockTableWithReservation();
        } else if (this.selectedRole === Constants.QR_ORDER) {
            if (this.currentUser.takePayment.devicePaymentEnabled) {
                this.router.navigate(['payment/addTip',
                    {
                        tableUuid: this.tableUuid,
                        tableName: oldUser ? oldUser.tableName : this.tableName,
                        categoryUuid: this.categoryUuid
                    }]);
            } else {
                if (localStorage.getItem('orderType') === Constants.DINE_IN) {
                    this.lockTableWithReservation();
                } else {
                    localStorage.setItem('CartItem', this._AESEncryptDecryptService.encrypt(JSON.stringify([])));
                    this.router.navigate(['order/orderConfirmation', {
                        orderId: this.orderId
                    }]);
                }
            }
        } else if (this.selectedRole === Constants.KIOSK) {
            if ((this.currentUser.takePayment.devicePaymentEnabled && (this.currentUser.takePayment.creditCardPayment)
                && !this.currentUser.takePayment.scanToPayPayment)) {
                this.router.navigate(['order/addTip',
                    {
                        tableUuid: this.tableUuid,
                        tableName: this.currentUser.firstName,
                        categoryUuid: this.categoryUuid
                    }]);
            } else if (this.currentUser.takePayment.devicePaymentEnabled && this.currentUser.takePayment.scanToPayPayment
                && this.currentUser.takePayment.paymentRequired) {
                const NewLineItems = [];
                for (let i = 0; i < this.lineItems.length; i++) {
                    let newquantity = this.lineItems[i].quantity ? this.lineItems[i].quantity : 1;
                    while (newquantity > 0) {
                        this.lineItems[i].image = '';
                        NewLineItems.push(this.lineItems[i]);
                        newquantity--;
                    }
                }
                const data = {
                    'orderCart': {
                        'employee': {
                            'id': this.currentUser.employeeId
                        },
                        'note': this.setNote(),
                        'lineItems': NewLineItems
                    }
                };
                this.atmoicOrderService.atomicOrderCheckoutV3(data, true, this.currentUser.firstName).subscribe(response => {
                    if (response) {
                        this.orderids = response.id;
                        this.orderId = response.id;
                        this.cartService.cloverOrder = response;
                        this.totalamount = response.total;
                        this.loading = false;
                        this.openQRPaymentRequiredDialog('Scan & Pay To Finish Order', '', this.orderId);
                    }
                });
            } else {
                if (localStorage.getItem('orderType') === Constants.DINE_IN) {
                    this.lockTableWithReservation();
                } else {
                    localStorage.setItem('CartItem', this._AESEncryptDecryptService.encrypt(JSON.stringify([])));
                    this.router.navigate(['order/orderConfirmation', {
                        orderId: this.orderId
                    }]);
                }
            }
        }
    }

    private loadRewards() {
        this.rewardsService.customer?.subscribe(customer => {
            // console.log('customer ' + JSON.stringify(customer));
            this.rewardsCustomer = customer;
        });
    }


    updateOrderTotal() {
        const newOrder = new Order();
        Object.assign(newOrder, this.cloverOrder);
        const orderTotalUpdated = (Math.round(newOrder.total) - Math.round(this.cartService.getTotalAmount(true)));
        newOrder.total = this.cloverOrder.total + this.cartService.getTotalAmount(true);
        this.cloverOrderService.updateOrder(newOrder).subscribe((response) => {
            this.firePrintAndFinishOrder();
        }, (err) => {
            this.alertService.error(err);
        });
    }

    lockTableWithReservation() {
        const reservation = new Reservation();
        reservation.assignedEmployeeIds = [this.currentUser._id];
        reservation.assignedEmployeeUsernames = [this.currentUser.username];
        reservation.createdEmployeeId = this.currentUser._id;
        reservation.name = this.tableName + 'Assigned To: ' + this.currentUser.firstName ?
            this.currentUser.firstName : this.currentUser.username;
        reservation.orgUuid = this.currentUser.orgSettings.orgUuid;
        reservation.tableLock = true;
        reservation.tableName = this.tableName;
        reservation.tableId = this.tableUuid;
        reservation.time = Date.now();
        reservation.orderIds = [this.orderId];

        this.tableUuid = '';
        this.tableName = '';

        if (this.selectedRole === 'Table') {
            reservation.name = 'Open Order On Table';
            reservation.assignedEmployeeIds = [];
            reservation.tableName = this.currentUser.firstName;
            reservation.tableId = this.currentUser._id;
        } else if (this.selectedRole === Constants.KIOSK || this.selectedRole === Constants.QR_ORDER) {
            reservation.name = 'Open Order On Table';
            reservation.assignedEmployeeIds = [];
        }
        this.reservationService.lockTableToOrder(reservation).subscribe(response => {
            if (this.selectedRole === 'Admin' || this.selectedRole === Constants.MANAGER || this.selectedRole === Constants.SERVER) {
                this.router.navigate(['/sidebar/admin/waiterMenu/serverOrder']);
            } else if (this.selectedRole === Constants.TABLE) {
                localStorage.setItem('CartItem', this._AESEncryptDecryptService.encrypt(JSON.stringify([])));
                this.router.navigate(['/sidebar/order/orderConfirmation', {
                    orderId: this.orderId
                }]);
            } else if (this.selectedRole === Constants.KIOSK || this.selectedRole === Constants.QR_ORDER) {
                localStorage.setItem('CartItem', this._AESEncryptDecryptService.encrypt(JSON.stringify([])));
                this.router.navigate(['order/orderConfirmation', {
                    orderId: this.orderId
                }]);
            }
        }, error => {
            this.router.navigate(['/sidebar/admin/waiterMenu/serverOrder']);
        });
    }

    setNote() {
        // if ((this.selectedRole === 'Admin' || this.selectedRole === Constants.MANAGER || this.selectedRole === Constants.SERVER || this.selectedRole === Constants.TABLE) && localStorage.getItem('orderType') === Constants.DINE_IN) {
        //     let spacesInString = (this.tableName.split(" ").length - 1);
        //     if (this.tableName.includes('-')) {
        //         return this.tableName.split(' -')[0];
        //     } else if (spacesInString == 1 && this.tableName.toLowerCase().includes(Constants.TABLE)) {
        //         return this.tableName;
        //     } else if (spacesInString > 1 && this.tableName.toLowerCase().includes(Constants.TABLE)) {
        //         let index = this.tableName.indexOf(' ', this.tableName.indexOf(' ') + 1);
        //         return this.tableName.substr(0, index);
        //     } else {
        //         return this.tableName.split(' ')[0];
        //     }
        // } else if (this.selectedRole === Constants.QR_ORDER && localStorage.getItem('orderType') === Constants.DINE_IN) {
        //     return this.currentUser.firstName;
        // } else {
        // }
        return '';
    }


    logout() {
        // remove user from local storage to log user out
        localStorage.clear();
        sessionStorage.clear();
        this.authenticationService.logout();
        // console.log('in AuthenticateService logout'); //*MES*

        this.cloverApiService.cloverItemsWithImages = new Observable<Item[]>();
        this.cloverApiService.cloverCategoriesWithImages = new Observable<Category[]>();
        this.router.navigate(['/login']);

    }

    public showNotification = (type: string, message: string): void => {
        this.notifier.notify(type, message);
    };
}

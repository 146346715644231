import {ChangeDetectorRef, Component, HostListener, Input, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {User} from '../../../_models';
import {AlertService, AuthenticationService, UserService} from '../../../_services';
import {BehaviorSubject, Observable} from 'rxjs';
import {Item} from '../../../_models/Item';
import {MatDialog} from '@angular/material/dialog';
import {CartService} from '../../../_services/cart.service';
import {Router, RouterOutlet} from '@angular/router';
import {NotifierService} from 'angular-notifier';
import {Category} from '../../../_models/category';
import {CloverApiService} from '../../../_services/cloverApi.service';
import {AESEncryptDecryptService} from '../../../_services/aesencrypt-decrypt.service';
import {isNil} from 'lodash';
import {AtmoicOrderService} from '../../../_services/atomic-order.service';
import {MatAccordion} from '@angular/material/expansion';
import {SocketioService} from "../../../_services/socketio.service";
import {IDropdownSettings} from "ng-multiselect-dropdown";
import {BumpedOrderService} from "../../../_services/bumpedOrder.service";
import {BumpedOrderModel} from "../../../_models/bumpedOrderModel";
import { Constants } from 'src/app/constants/constants';
import {SquareApiService} from "../../../_services/square-api.service";
import {Order} from "../../../_models/order";
import {UtilsService} from '../../../_services/utils.service';

@Component({
    selector: 'app-order-user',
    templateUrl: './order-user.component.html',
    styleUrls: ['./order-user.component.scss'],
    encapsulation: ViewEncapsulation.None // Add this line
})
export class OrderUserComponent implements OnInit {

    @ViewChild(MatAccordion) accordion: MatAccordion;

    currentUser: User;
    @Input() showMenu = false;
    @Input() showLogin = true;
    @Input() showRegister = false;
    Constants = Constants;
    role: String;
    selectedRole: any;
    screenHeight: number;
    filteredCategories: Category[] = []
    private screenHeight$ = new BehaviorSubject<number>(window.innerHeight);
    filteredItemsId = [];
    customerDisplay = true;
    private allOrders: Order[] = [];
    cardView = true;
    loadingBumpedOrders = true;
    private lastSquareUpdatedOrderState: Order;


    @HostListener('window:resize', ['$event'])

    onResize($event) {
        this.screenHeight$.next($event.target.innerHeight);
    }

    orders: Order[] = [];
    deletedItems: Order[] = [];
    loading = true;
    items: Item[] = [];
    categories: Category[] = [];

    excludedDropdownSettings: IDropdownSettings = {
        singleSelection: false,
        idField: 'id',
        textField: 'name',
        // selectAllText: "Select All",
        // unSelectAllText: "UnSelect All",
        itemsShowLimit: 1,
        allowSearchFilter: true,
        enableCheckAll: false
    };

    constructor(private dialog: MatDialog,
                private cloverApiService: CloverApiService,
                private squareApiService: SquareApiService,
                private userService: UserService,
                private router: Router,
                private ref: ChangeDetectorRef,
                private authService: AuthenticationService,
                private socketService: SocketioService,
                private cartService: CartService,
                private bumpedOrderService: BumpedOrderService,
                private notifier: NotifierService,
                private utilsService: UtilsService,
                private routerOutlet: RouterOutlet, private alertService: AlertService,
                private _AESEncryptDecryptService: AESEncryptDecryptService, private atmoicOrderService: AtmoicOrderService) {
            this.currentUser = utilsService.getCurrentUser();
        this.selectedRole = this.currentUser.selectedRole;
        if (JSON.parse(localStorage.getItem('deletedItems'))?.length > 0) {
            this.deletedItems = (JSON.parse(localStorage.getItem('deletedItems')));
        }

        this.role = authService.role;
    }

    ngOnInit() {
        if(this.currentUser.orgSettings.accountType === Constants.CLOVER) {
            this.setupBumpedOrderWebsocket();
            this.initLocalStorageSettings();
            this.filteredCategories = JSON.parse(localStorage.getItem('filteredCategories'));
            this.getBumpedOrders();
            this.getOrdersList();
            this.getAllCategories();
        } else if (this.currentUser.orgSettings.accountType === Constants.SQUARE) {
            this.getSquareOrdersList();
        }
    }

    private initLocalStorageSettings() {
        if (localStorage.getItem('customerDisplay')) {
            this.cardView = true;
            this.customerDisplay = JSON.parse(localStorage.getItem('customerDisplay')) === true;
        }

        if (localStorage.getItem('cardView') && (localStorage.getItem('customerDisplay') !== 'true')) {
            this.cardView = localStorage.getItem('cardView') === 'true';
        }

        this.screenHeight$.subscribe(width => {
            this.screenHeight = width;
        });
    }

    getAllItems() {
        this.cloverApiService.getAllItemsWithoutImages().subscribe(items => {
            this.items = items;
        })
    }

    getAllCategories() {
        this.cloverApiService.getAllCategories().subscribe(categories => {
            this.categories = categories;
        })
    }

    getOrdersList() {
        this.loading = true;
        this.atmoicOrderService.getRecentOpenOrdersList(false).subscribe(response => {
                if (response) {
                    // for(let i = 0; i < response.length; i++) {
                    if (response.length > 0) {
                        this.orders = [...this.orders, ...response];
                        this.allOrders = [...this.orders];
                        this.socketService.OrdersSubject$.next(this.orders);
                        this.recalculateOrders();
                        this.initFilter();
                        this.loading = false;

                    }
                    this.loading = false;
                    //  }

                } else {
                    this.loading = false;
                }
            },
            error => {
                this.alertService.error(error);
                this.loading = false;
            });
    }

    changeOrderFulfillmentSquare(order: Order, state: string) {
        this.lastSquareUpdatedOrderState = order;
        if(order.metadata?.fulfilment_state) {
            order.metadata.fulfilment_state = state;
        } else {
            order.metadata = {
                fulfilment_state: state
            }
        };

        this.squareApiService.updateOrder(order).subscribe(updated=>{
            let index = this.socketService.bumpedOrders.findIndex(order => order.id === order.id);
            if(index !== -1) {
                this.squareApiService.getOrderById(order.id).subscribe(order =>{
                    this.socketService.bumpedOrders[index] = order;
                    this.socketService.OrdersSubject$.next(this.socketService.orders);
                })
            }
        })
    }

    getSquareOrdersList() {
        this.loading = true;
        let filters= ['OPEN', 'COMPLETED'];
        this.squareApiService.getAllOrders([this.currentUser.locationId],  filters).subscribe(response => {
                if (response) {
                    if (response.length > 0) {
                        this.socketService.orders = [...response];
                        this.socketService.OrdersSubject$.next(this.socketService.orders);
                        }
                    this.loading = false;
                } else {
                    this.loading = false;
                }
            },
            error => {
                this.alertService.error(error);
                this.loading = false;
            });
    }

    getBumpedOrders() {
        this.loadingBumpedOrders = true;
        this.bumpedOrderService.getAll().subscribe(response => {
                console.log('bumped orders ' + JSON.stringify(response));
                if (response) {
                    // this.bumpedOrders = response;
                    this.socketService.bumpedOrders = response;
                    this.socketService.bumpedOrdersSubject$.next(response);
                    this.loadingBumpedOrders = false;

                } else {
                    this.loadingBumpedOrders = false;
                }
            },
            error => {
                this.alertService.error(error);
                this.loadingBumpedOrders = false;
            });
    }

    bumpOrder(order: Order) {
        if(this.currentUser.orgSettings.accountType === Constants.CLOVER) {
            let orderToBump = new BumpedOrderModel();
            orderToBump.orderId = order.id;
            orderToBump.title = order.title ? order.title : order.id;
            orderToBump.order = JSON.stringify(order);
            orderToBump.orgUuid = this.currentUser.orgSettings.orgUuid;
            orderToBump.modifiedTime = order.modifiedTime;
            orderToBump.bumpedItems = order.lineItems;
            this.bumpedOrderService.bumpOrder(orderToBump).subscribe(response => {
                // this.socketService.bumpedOrders.push(response);
                // this.socketService.bumpedOrders = this.bumpedOrders;
                // this.socketService.bumpedOrdersSubject$.next(this.bumpedOrders);
                this.recalculateOrders();
            })
        } else if(this.currentUser.orgSettings.accountType === Constants.SQUARE) {
            this.changeOrderFulfillmentSquare(order, 'READY');
        }
    }


    completeAndHideOrder(order: BumpedOrderModel) {
        order.hidden = true;
        this.bumpedOrderService.update(order).subscribe(response => {
            this.recalculateOrders();
        })
    }


    private recalculateOrders() {
        this.orders = this.allOrders;
        let deletedItems = [];
        let ids = [];
        if (this.socketService.bumpedOrders?.length > 0) {
            ids = [...ids, ...this.filteredItemsId, ...(this.socketService.bumpedOrders).map(bumpedOrder => bumpedOrder.orderId)]
        } else {
            ids = [...ids, ...this.filteredItemsId]
        }
        let updatedDeletedItems = this.orders.filter(order => this.socketService.bumpedOrders?.some(deleted => order.id == deleted.orderId && order.modifiedTime != deleted.modifiedTime && order.createdTime === deleted.createdTime))
        let orderWithNewItem: Order[] = [];
        updatedDeletedItems.forEach(order => {
            // let itemToPush = this.deletedItems.filter(deletedOrder => deletedOrder.id === order.id && order.modifiedTime != deletedOrder.modifiedTime  && order.createdTime === deletedOrder.createdTime)[0];
            let itemToPush = this.socketService.bumpedOrders.filter(deletedOrder => deletedOrder.orderId === order.id && order.modifiedTime != deletedOrder.modifiedTime && order.createdTime === deletedOrder.createdTime)[0].lineItems.map(lineItems => lineItems.id);
            order.lineItems = order.lineItems.filter(lineItem => !itemToPush.includes(lineItem.id));
            orderWithNewItem.push(order);
        })
        this.orders = this.orders.filter((order) => !ids.includes(order.id));
        this.orders = [...this.orders, ...orderWithNewItem];
        this.socketService.OrdersSubject$.next(this.orders);
    }

    undoBump() {
        if(this.currentUser.orgSettings.accountType === Constants.CLOVER) {
            const lastDeleted = this.socketService.bumpedOrders[this.socketService.bumpedOrders.length - 1];
            if (lastDeleted) {
                this.bumpedOrderService.delete(lastDeleted.id).subscribe(response => {
                    this.recalculateOrders();
                })
            }
        } else if(this.currentUser.orgSettings.accountType === Constants.SQUARE) {
            this.lastSquareUpdatedOrderState.version = this.lastSquareUpdatedOrderState.version + 1;
            this.squareApiService.updateOrder(this.lastSquareUpdatedOrderState).subscribe(updatedOrder=>{
                let index  = this.orders.findIndex(order => order.id === this.lastSquareUpdatedOrderState.id);
                if(index != -1) {
                    this.orders[index] = updatedOrder;
                    this.socketService.OrdersSubject$.next(this.orders);
                }
            })
        }
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.clear();
        sessionStorage.clear();
        this.authService.logout();
        // console.log('in AuthenticateService logout'); //*MES*

        this.cloverApiService.cloverItemsWithImages = new Observable<Item[]>();
        this.cloverApiService.cloverCategoriesWithImages = new Observable<Category[]>();
        this.router.navigate(['/login']);
    }

    initFilter() {
        this.filteredCategories = JSON.parse(localStorage.getItem('filteredCategories'));

        if (this.filteredCategories?.length > 0) {
            this.filteredCategories.forEach(filterCategory => {
                this.orders = this.orders.filter((order) => order.lineItems?.some(lineItem => lineItem.categories?.some(category => category.id === filterCategory.id)));
                this.socketService.OrdersSubject$.next(this.orders);
                this.loading = false;
            })
        }
    }

    onFilterCategorySelect(item: any) {
        this.filteredItemsId.push(item.id);
        localStorage.setItem('filteredCategories', JSON.stringify(this.filteredCategories));
        let ids = this.filteredCategories.map(category => category.id);
        this.orders = this.allOrders.filter((order) => order.lineItems?.some(lineItem => lineItem.categories?.some(category => ids.includes(category.id))));
        this.socketService.OrdersSubject$.next(this.orders);
    }

    onFilterCategoryDeSelect(item: any) {
        this.filteredItemsId = this.filteredItemsId.filter((value) => value !== item.id);
        this.filteredCategories = this.filteredCategories.filter((value) => value.id !== item.id);
        localStorage.setItem('filteredCategories', JSON.stringify(this.filteredCategories));
        let ids = this.filteredCategories.map(category => category.id);
        this.orders = this.allOrders.filter((order) => order.lineItems?.some(lineItem => lineItem.categories?.some(category => ids.includes(category.id))));
        if (this.filteredCategories.length < 1) {
            this.orders = this.allOrders;
        }
        this.socketService.OrdersSubject$.next(this.orders);
    }

    titleUpdatefilter(title) {

        if (this.customerDisplay) {
            if (title.includes('.com')) {
                return title.split('.com')[1];
            } else {
                return title
            }
        } else {

            if (title.includes('.com')) {
                return 'DineNGo ' + title.split('.com')[1];
            } else {
                return title
            }
        }
    }

    refreshData() {
        window.location.reload();
    }

    showCustomerModeText() {
        if (this.customerDisplay) {
            return 'Enable Chef Mode'
        } else {
            return 'Enable Customer Mode'
        }
    }

    customerModeChange() {
        this.customerDisplay = !this.customerDisplay;
        localStorage.setItem('customerDisplay', JSON.stringify(this.customerDisplay));
        this.cardView = true
    }

    cardviewMarginTop() {
        if (this.customerDisplay || !this.cardView) {
            return 0;
        } else {
            return '90px';
        }
    }

    displayforListView() {
        if (this.customerDisplay) {
            return 'flex';
        }
    }

    setCardView(activate) {
        this.cardView = activate
        localStorage.setItem('cardView', JSON.stringify(this.cardView));
    }

    private setupBumpedOrderWebsocket() {
        this.socketService.bumpedOrdersSubject$.subscribe(data => {
                this.recalculateOrders();
        });
    }

    setOrderTypeColor(order: Order) {
        switch (order.orderType.label.toLowerCase()) {
            case Constants.CARRYOUT.toLowerCase(): {
                return 'Green';
                break;
            }
            case Constants.DINE_IN.toLowerCase(): {
                return 'Red';
                break;
            }
            default: {
                return 'Black';
                break;
            }
        }
    }
}

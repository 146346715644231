import {Injectable} from '@angular/core';
import {io} from 'socket.io-client';
import {environment} from '../../environments/environment';
import {BehaviorSubject, Observable} from 'rxjs';
import {Customer} from '../_models/customer';
import {CustomerRewardsService} from './customerRewards.service';
import {CartService} from './cart.service';
import {User} from '../_models';
import {CloverOrderService} from './cloverOrder.service';
import {AESEncryptDecryptService} from './aesencrypt-decrypt.service';
import {Router} from '@angular/router';
import {AlertService} from './alert.service';
import {AtmoicOrderService} from './atomic-order.service';
import {CloverWebhook} from '../_models/cloverWebhook';
import * as _ from 'lodash';
import {MatDialog} from '@angular/material/dialog';
import {Discount} from '../_models/discount';
import {GeneralWebhook} from '../_models/generalWebhook';
import {BumpedOrderModel} from '../_models/bumpedOrderModel';
import {Constants} from '../constants/constants';
import {SquareApiService} from './square-api.service';
import { Order } from '../_models/order';
import {UtilsService} from './utils.service';

@Injectable({
    providedIn: 'root'
})
export class SocketioService {
    socket;
    currentUser: User;
    selectedRole;
    orderId;
    merchantId;
    rewardsCustomer: Customer;
    rewardsUpdated = false;
    rewardsRedeemed = false;
    bumpedOrders = [];
    orders =  [];
    OrdersSubject$ =  new BehaviorSubject<Order[]>([]);
    bumpedOrdersSubject$ = new BehaviorSubject<BumpedOrderModel[]>([]);
    squareDeviceId: string;
    deviceAlias: any;


    constructor(private rewardsService: CustomerRewardsService, private cartService: CartService, private router: Router,
                private alertService: AlertService, private cloverOrderService: CloverOrderService, private utilsService: UtilsService,
                private squareApiService: SquareApiService, private atmoicOrderService: AtmoicOrderService, private dialog: MatDialog,
                private _AESEncryptDecryptService: AESEncryptDecryptService) {
            this.currentUser = utilsService.getCurrentUser();
            this.selectedRole = this.currentUser?.selectedRole;
            this.merchantId = this.currentUser.merchantId;
        if ( (this.selectedRole === Constants.KIOSK && this.currentUser.takePayment.devicePaymentEnabled
            && (this.currentUser.takePayment.cloverConnectorPayment || this.currentUser.takePayment.scanToPayPayment)
            && this.currentUser.takePayment.paymentRequired && this.currentUser.merchantId)) {
                this.setupSocketConnection();
        } else if ((this.selectedRole === 'PaymentsDisplay' || this.selectedRole === Constants.ORDERS_DISPLAY
            || (this.selectedRole === Constants.TABLE && this.currentUser.takePayment.devicePaymentEnabled
                && this.currentUser.takePayment.scanToPayPayment))) {
                this.setupSocketConnection();
        } else if (this.currentUser.orgSettings?.accountType && this.selectedRole === Constants.KIOSK) {
            this.setupSocketConnection();
        }

        if (this.currentUser.orgSettings.accountType === Constants.CLOVER ) {
            this.loadRewards();
        }

        this.addListenerListenForOrg();
    }

    setupSocketConnection() {
        this.socket = io(`${environment.apiServerUrl}`);
        if (this.currentUser.orgSettings.accountType === Constants.CLOVER) {
            this.addListenerListenForMerchant();
        } else if (this.currentUser.orgSettings.accountType === Constants.SQUARE) {
            this.addListenerListenForSquareMerchant();
            if (!environment.production) {
                this.addListenerListenForSquareDevWebhookTestMerchant();
            }
        }
    }

    addListenerListenForMerchant() {
        this.socket.on(this.merchantId, (event: CloverWebhook[]) => {
            event.forEach(webhook => {
                if (this.currentUser.takePayment.devicePaymentEnabled && this.currentUser.takePayment.paymentRequired && this.selectedRole === 'Kiosk' && (this.currentUser.takePayment.cloverConnectorPayment || this.currentUser.takePayment.scanToPayPayment) && this.cartService.getCartItems().length > 0) {
                    if (webhook.objectId[0] === 'P') {
                        this.cloverOrderService.getOrder(this.cartService.cloverOrder.id).subscribe(order => {
                            if (order.paymentState === 'PAID' || order.state === 'locked') {
                                this.loadRewards();
                                setTimeout(() => {
                                    if (this.currentUser.orgSettings.rewards.rewardsEnabled) {
                                        this.processRewards(order.total);
                                    } else {
                                        this.firePrintAndRoute(this.cartService.cloverOrder.id);
                                        this.dialog.closeAll();
                                    }
                                }, 500);
                            }
                        });
                    } else if (webhook.objectId[0] === 'O') {
                        // const orderId = webhook.objectId.split(':')[1];
                        // if (orderId === this.cartService.cloverOrder.id) {
                        //     this.cloverOrderService.getOrder(this.cartService.cloverOrder.id).subscribe(order => {
                        //         if (order.paymentState === 'PAID' || order.state === 'locked') {
                        //             this.firePrintAndRoute(orderId);
                        //             this.dialog.closeAll();
                        //         }
                        //     });
                        // }
                    }
                } else if (this.selectedRole === Constants.ORDERS_DISPLAY || this.selectedRole === 'PaymentsDisplay') {
                    this.dialog.closeAll();
                    window.location.reload();
                } else if (this.selectedRole === Constants.TABLE) {
                    this.cloverOrderService.getUnpaidOrder(this.currentUser.username).subscribe(order => {
                        if (!order || _.isNil(order)) {
                            this.dialog.closeAll();
                            window.location.reload();
                        }
                    });
                }
            });
        });
    }

    addListenerListenForOrg() {
        this.socket = io(`${environment.apiServerUrl}`);
        this.socket.on(this.currentUser.orgSettings.orgUuid, (webhook: GeneralWebhook[]) => {
            if (webhook) {
                const parsedWebhook = webhook[this.currentUser.orgSettings.orgUuid];
                this.processOrgWebhook(parsedWebhook);
            }
        });
    }

    firePrintAndRoute(orderId) {
        this.cloverOrderService.fireOrder(orderId, this.currentUser.orgSettings.featuresEnabled.v1Print).subscribe(response => {
            this.rewardsService.customer = new Observable<Customer>();
            this.clearDataAndRouteToNextComnponent();
        });
    }

    private clearDataAndRouteToNextComnponent() {
        let oldUser;
        localStorage.setItem('CartItem', this._AESEncryptDecryptService.encrypt(JSON.stringify([])));

        if (this._AESEncryptDecryptService.decrypt(localStorage.getItem('oldUser'))) {
            oldUser = JSON.parse(this._AESEncryptDecryptService.decrypt(localStorage.getItem('oldUser')));
        }

        if (oldUser) {
            this.utilsService.setCurrentUser(oldUser);
            localStorage.removeItem('oldUser');
        }
        this.rewardsService.customer = new Observable<Customer>();
        this.cartService.removeAllFromCart();
        this.cartService.removeOrderHistory();
        this.cartService.tip = 0;
        // console.log('I\'m continuing');
        this.alertService.success('Order Created and Payment Received', true);
        //  this.cartService.removeAllFromCart();
        this.routeToNextPage(this.currentUser);
    }

    private routeToNextPage(oldUser?) {
        this.router.navigate(['order/orderConfirmation', {
            orderId: this.cartService.cloverOrder.id
        }]);
    }

    processRewards(totalAmount) {
        if (this.selectedRole === 'Kiosk' && this.rewardsCustomer?.number > 0) {
            if (this.currentUser.orgSettings.rewards.rewardsEnabled && (this.rewardsCustomer.points < this.currentUser.orgSettings.rewards.afterVisits) && !this.rewardsUpdated) {
                this.rewardsUpdated = true;
                this.rewardsService.checkin(Number(this.rewardsCustomer.number),
                    this.rewardsCustomer.name).subscribe((response) => {
                        if (response) {
                            this.firePrintAndRoute(this.cartService.cloverOrder.id);
                            this.rewardsUpdated = false;
                            this.dialog.closeAll();
                        }
                    },
                    error => {
                        console.error('error: The Order has been created but failed to apply award');
                        this.rewardsUpdated = false;
                        this.firePrintAndRoute(this.cartService.cloverOrder.id);
                        this.dialog.closeAll();
                    });
            } else if (this.currentUser.orgSettings.rewards.rewardsEnabled && (this.rewardsCustomer.points >= this.currentUser.orgSettings.rewards.afterVisits) && !this.rewardsRedeemed) {
                this.rewardsRedeemed = true;
                const discount = new Discount();
                discount.name = '$' + Number(this.currentUser.orgSettings.rewards.amountOff) / 100 + ' off after '
                    + Number(this.currentUser.orgSettings.rewards.afterVisits) + ' visits';
                discount.amount = Number(this.currentUser.orgSettings.rewards.amountOff);
                const total = totalAmount + discount.amount;
                this.rewardsService.redeemPaidOrder(Number(this.rewardsCustomer.number), discount, this.currentUser.orgSettings.rewards.afterVisits).subscribe(response => {
                        if (response) {
                            this.rewardsRedeemed = false;
                            this.firePrintAndRoute(this.cartService.cloverOrder.id);
                            this.dialog.closeAll();
                        }
                    },
                    error => {
                        this.rewardsRedeemed = false;
                        console.error('error: The Order has been created but failed to apply award');
                        this.firePrintAndRoute(this.cartService.cloverOrder.id);
                        this.dialog.closeAll();
                    });
            }
        } else {
            this.firePrintAndRoute(this.cartService.cloverOrder.id);
            this.dialog.closeAll();
        }
        this.rewardsService.customer = new Observable<Customer>();
    }

    private loadRewards() {
        this.rewardsService.customer?.subscribe(customer => {
            // console.log('customer ' + JSON.stringify(customer));
            this.rewardsCustomer = customer;
        });
    }

    private processOrgWebhook(parsedWebhook: any) {
        if (this.selectedRole === Constants.ORDERS_DISPLAY) {
            if (parsedWebhook.type === 'BumpedOrder' && parsedWebhook.status === 'Created') {
                this.bumpedOrders.push(parsedWebhook.object);
                this.bumpedOrdersSubject$.next(this.bumpedOrders);
            } else if (parsedWebhook.type === 'BumpedOrder' && parsedWebhook.status === 'Deleted') {
                this.bumpedOrders = _.filter(this.bumpedOrders, bumpedOrder => bumpedOrder.id !== parsedWebhook.orderId);
                this.bumpedOrdersSubject$.next(this.bumpedOrders);
            } else if (parsedWebhook.type === 'BumpedOrder' && parsedWebhook.status === 'Updated') {
                const index = _.findIndex(this.bumpedOrders, bumpedOrder => bumpedOrder.id === parsedWebhook.orderId);
                this.bumpedOrders[index] = parsedWebhook.object;
                this.bumpedOrdersSubject$.next(this.bumpedOrders);
            }
        }
        // all users webhook
        if (parsedWebhook.type === 'OrgSettings' && parsedWebhook.status === 'Updated') {
            this.currentUser.orgSettings = parsedWebhook.object;
            this.utilsService.setCurrentUser(this.currentUser);
        }

    }


    // square webhook
    addListenerListenForSquareMerchant() {
        this.socket.on(this.merchantId, (event: any) => {
            [event].forEach(webhook => {
                if (this.selectedRole === 'Kiosk' && this.currentUser.takePayment.scanToPayPayment) {
                    if (webhook.type === 'payment.updated' && webhook.data.object.payment.order_id === this.cartService.orderId) {
                        console.log('order: ' + this.cartService.orderId + ' has been paid');
                        this.dialog.closeAll();
                        this.clearDataAndRouteToNextComnponentSquare();
                    }
                } else if (this.selectedRole === 'Kiosk' && this.currentUser.takePayment.cloverConnectorPayment) {
                    if (webhook.type === 'terminal.checkout.updated' && webhook.data.object.checkout.order_id === this.cartService.orderId && webhook.data.object.device_code.status === 'COMPLETED') {
                        console.log('order: ' + this.cartService.orderId + ' has been paid');
                        this.dialog.closeAll();
                        this.clearDataAndRouteToNextComnponentSquare();
                    }
                } else if (this.selectedRole === 'Table' && this.currentUser.takePayment.scanToPayPayment) {
                    if (webhook.type === 'payment.updated' && webhook.data.object.payment.order_id === this.cartService.orderId) {
                        console.log('order: ' + this.cartService.orderId + ' has been paid');
                        this.dialog.closeAll();
                        this.clearDataAndRouteToNextComnponentSquare();
                    }
                } else if (this.selectedRole === 'Table' && this.currentUser.takePayment.cloverConnectorPayment) {
                    if (webhook.type === 'terminal.checkout.updated' && webhook.data.object.checkout.order_id === this.cartService.orderId && webhook.data.object.device_code.status === 'COMPLETED') {
                        console.log('order id: ' + this.cartService.orderId + ' has been paid');
                        this.dialog.closeAll();
                        this.clearDataAndRouteToNextComnponentSquare();
                    }
                } else if (this.selectedRole === 'Admin' || this.selectedRole === 'Manager') {
                    if (webhook.type === 'device.code.paired' && webhook.data.object.device_code.status === 'PAIRED') {
                        this.squareDeviceId = webhook.data.object.device_code.device_id;
                        this.deviceAlias = webhook.data.object.device_code.name;
                        this.dialog.closeAll();
                    }
                    if ((webhook.type === 'terminal.checkout.updated' || webhook.type === 'payment.updated') && webhook.data.object.checkout.order_id === localStorage.getItem('orderInFocus') && webhook.data.object.device_code.status === 'COMPLETED') {
                        this.squareDeviceId = webhook.data.object.device_code.device_id;
                        this.deviceAlias = webhook.data.object.device_code.name;
                        this.dialog.closeAll();
                    }
                } else if (this.selectedRole === 'OrdersDisplay') {
                    if (webhook.type === 'order.updated') {
                        const index = this.orders.findIndex(order => order.id === webhook.data.object.order_id);
                        if (index !== -1) {
                            this.squareApiService.getOrderById(webhook.data.object.order_id).subscribe(order => {
                                this.orders[index] = order;
                                this.OrdersSubject$.next(this.orders);
                            });
                        }
                    }
                }
            });
        });
    }

    addListenerListenForSquareDevWebhookTestMerchant() {
        this.socket.on('MLQDCYHT32HDD', (event: any) => {
            [event].forEach(webhook => {
                if (this.selectedRole === 'Kiosk' && this.currentUser.takePayment.scanToPayPayment) {
                    if (webhook.type === 'payment.updated' && webhook.data.object.payment.order_id === this.cartService.orderId) {
                        console.log('order: ' + this.cartService.orderId + ' has been paid');
                        this.dialog.closeAll();
                        this.clearDataAndRouteToNextComnponentSquare();
                    }
                } else if (this.selectedRole === 'Kiosk' && this.currentUser.takePayment.cloverConnectorPayment) {
                    if (webhook.type === 'terminal.checkout.updated' && webhook.data.object.checkout.order_id === this.cartService.orderId && webhook.data.object.device_code.status === 'COMPLETED') {
                        console.log('order: ' + this.cartService.orderId + ' has been paid');
                        this.dialog.closeAll();
                        this.clearDataAndRouteToNextComnponentSquare();
                    }
                } else if (this.selectedRole === 'Table' && this.currentUser.takePayment.scanToPayPayment) {
                    if (webhook.type === 'payment.updated' && webhook.data.object.payment.order_id === this.cartService.orderId) {
                        console.log('order: ' + this.cartService.oldTableOrder.id + ' has been paid');
                        this.dialog.closeAll();
                        this.clearDataAndRouteToNextComnponentSquare();
                    }
                } else if (this.selectedRole === 'Table' && this.currentUser.takePayment.cloverConnectorPayment) {
                    if (webhook.type === 'terminal.checkout.updated' && webhook.data.object.checkout.order_id === this.cartService.orderId && webhook.data.object.device_code.status === 'COMPLETED') {
                        console.log('order id: ' + this.cartService.oldTableOrder.id + ' has been paid');
                        this.cartService.orderId = webhook.data.object.checkout.order_id;
                        this.dialog.closeAll();
                        this.clearDataAndRouteToNextComnponentSquare();
                    }
                } else if (this.selectedRole === 'Table' && !this.currentUser.takePayment.cloverConnectorPayment && !this.currentUser.takePayment.scanToPayPayment) {
                    if (webhook.type === 'terminal.checkout.updated' && webhook.data.object.checkout.order_id === this.cartService.orderId && webhook.data.object.device_code.status === 'COMPLETED') {
                        console.log('order id: ' + this.cartService.oldTableOrder.id + ' has been paid');
                        this.deleteSquareOrder();
                    }
                } else if (this.selectedRole === 'Admin' || this.selectedRole === 'Manager') {
                    if (webhook.type === 'device.code.paired' && webhook.data.object.device_code.status === 'PAIRED') {
                        this.squareDeviceId = webhook.data.object.device_code.device_id;
                        this.deviceAlias = webhook.data.object.device_code.name;
                        this.dialog.closeAll();
                    }
                    if ((webhook.type === 'terminal.checkout.updated' || webhook.type === 'payment.updated') && webhook.data.object.checkout.order_id === localStorage.getItem('orderInFocus') && webhook.data.object.device_code.status === 'COMPLETED') {
                        this.deleteSquareOrder();
                    }
                }
            });
        });
    }

    private clearDataAndRouteToNextComnponentSquare() {
        let oldUser;
        localStorage.setItem('CartItem', JSON.stringify([]));

        if (localStorage.getItem('oldUser')) {
            oldUser = JSON.parse(localStorage.getItem('oldUser'));
        }

        if (oldUser) {
            localStorage.setItem('currentUser', this._AESEncryptDecryptService.encrypt(JSON.stringify(oldUser)));
            localStorage.removeItem('oldUser');
        }
        this.cartService.removeAllFromCart();
        if (this.selectedRole !== 'Table') {
            this.cartService.orderId = '';
        }
        this.cartService.oldTableOrder = undefined;
        // console.log('I\'m continuing');
        this.alertService.success('Order Created and Payment Received', true);
        //  this.cartService.removeAllFromCart();
        if (this.selectedRole === 'Kiosk' || this.selectedRole === Constants.QR_ORDER) {
            this.router.navigate(['order/orderConfirmation', {
                orderId: this.cartService.orderId
            }]);
        } else if (this.selectedRole === 'Admin' || this.selectedRole === 'Manager') {
            this.router.navigate(['/sidebar/serverOrder']);
        }
        this.cartService.orderId = '';
    }

    deleteSquareOrder() {
        this.squareApiService.deleteOpenOrder().subscribe(response => {
            this.dialog.closeAll();
            this.clearDataAndRouteToNextComnponentSquare();
        });
    }
}

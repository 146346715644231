<h2 mat-dialog-title>{{description}}</h2>
<mat-dialog-content>
  <div class="dialog-options-container">
    <div class="error-message">{{errorMessage}}</div>
    <ng-multiselect-dropdown
                             [placeholder]="'Move to Table'"
                             [settings]="tablesDropdownSettings"
                             [data]="tables"
                             *ngIf="isMoveTable"
                             (onDeSelect)="onAssignTableDeSelect($event)"
                             (onSelect)="onAssignTableSelect($event)">

    </ng-multiselect-dropdown>
    <ng-multiselect-dropdown
                             [placeholder]="'Assign Employees'"
                             [settings]="employeesDropdownSettings"
                             *ngIf="isEmployeeAssign"
                             [data]="employees"
                             [(ngModel)]="selectedEmployee"
                             (onDeSelect)="onAssignEmployeeDeSelect($event)"
                             (onSelect)="onAssignEmployeeSelect($event)">

    </ng-multiselect-dropdown>
  </div>
</mat-dialog-content>
  <div class="button-container">
    <button mdbBtn color="danger" block="true" class="my-4 mr-2 action-button" (click)="close()">Cancel</button>
    <button mdbBtn [disabled]="loading"  color="info" block="true" class="my-4 right-margin-set action-button" (click)="moveTable()">Update</button>
  </div>

import {Component, OnDestroy, OnInit} from '@angular/core';
import {User} from '../../../_models';
import {ActivatedRoute, Router} from '@angular/router';
import {AlertService} from '../../../_services';
import {LineItem} from '../../../_models/lineItem';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {SquareApiService} from '../../../_services/square-api.service';
import { Subscription} from 'rxjs';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {SquareMoney} from '../../../_models/squareMoney';
import {SquarePaymentPopupComponent} from '../../../shared/components/square-payment-popup/square-payment-popup.component';
import {PaymentPayload} from '../../../_models/paymentPayload';
import * as uuid from 'uuid';
import {SquarePayServiceService} from '../../../_services/square-pay-service.service';
import {AESEncryptDecryptService} from '../../../_services/aesencrypt-decrypt.service';
import {isNil} from "lodash";
import {
  NotificationPaymentRequiredPopupComponent
} from "../../../shared/components/square-notification-payment-required-popup/notification-payment-required-popup.component";
import {CartServiceSquare} from "../../../_models/cart.service.square";
import {CartItem} from "../../../_models/cartItem";
import {Constants} from "../../../constants/constants";
import {Reservation} from "../../../_models/reservation";
import {ReservationService} from "../../../_services/reservation.service";
import {Order} from "../../../_models/order";
import {SocketioService} from "../../../_services/socketio.service";
import {UtilsService} from '../../../_services/utils.service';

@Component({
  selector: 'app-view-cart-square',
  templateUrl: './view-cart-square.component.html',
  styleUrls: ['./view-cart-square.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ViewCartSquareComponent implements OnInit, OnDestroy {
  readonly Constants = Constants;
  currentUser: User;
  cartItems: CartItem[] = [];
  loading = true;
  loadingButton = false;
  tableUuid: string;
  tableName: string;
  locationId: string;
  columnsToDisplay: string[] = ['Name', 'Price', 'Icon'];
  existingOrder: Order;
  calculatedOrder: Order;
  cartItemsSubscription: Subscription;
  private selectedRole: string;
  private orderId: string;
  private reservation: Reservation;

  constructor(
    private cartService: CartServiceSquare,
    private router: Router,
    private alertService: AlertService,
    private reservationService: ReservationService,
    private activatedRoute: ActivatedRoute,
    private socketIOService: SocketioService,
    private dialog: MatDialog,
    private squareApi: SquareApiService,
    private squarePaymentApi: SquarePayServiceService, private _AESEncryptDecryptService: AESEncryptDecryptService, private utilsService: UtilsService
  ) {
      this.currentUser = utilsService.getCurrentUser();
      this.selectedRole = this.currentUser?.selectedRole;

    if (isNil(this.currentUser)) {
      this.currentUser = JSON.parse(this._AESEncryptDecryptService.decrypt(sessionStorage.getItem('currentUser')));
      this.selectedRole = this._AESEncryptDecryptService.decrypt(sessionStorage.getItem(Constants.SELECTED_ROLE));
    }
    this.socketIOService.setupSocketConnection();
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
        this.tableUuid = params['tableUuid'] || this.currentUser._id;
        this.tableName = params['tableName'] || this.currentUser.firstName;
        this.locationId = params['locationId'] || this.currentUser.locationId;
            if(params['orderId']) {
              this.orderId = params['orderId'];
            }
      },
      error => {
        this.alertService.error(error);
        this.loading = false;
      });
    if((!this.isOrderType(Constants.CARRYOUT) || this.selectedRole === Constants.TABLE)) {
      this.checkForExistingOrder();
    } else {
      this.loadAllInCart();
    }
  }

  checkForExistingOrder() {
    this.reservationService.getReservationForTable(this.tableUuid).subscribe(reservation => {
      this.reservation = reservation;
      this.squareApi.getOrderById(this.reservation?.orderIds[0]).subscribe(order=>{
        this.existingOrder = order;
        this.orderId = order.id;
        this.loadAllInCart();
      })
    });
  }

  loadAllInCart() {
    this.cartItemsSubscription = this.cartService
      .getItems()
      .subscribe(cartItems => {
          if (!cartItems.length) {
            this.calculatedOrder = null;
          }
          this.cartItems = cartItems;
          this.loading = false;
          if (this.cartItems.length) {
            this.calculateOrder();
          }
        },
        error => {
          this.alertService.error(error);
          this.loading = false;
        });
  }

  calculateOrder() {
    this.loading = true;
    const orderRequest = this.buildOrder();

    this.squareApi.calculateOrder(orderRequest).subscribe(order => {
      this.calculatedOrder = order;
      this.loading = false;
    }, err => {
      this.alertService.error(err);
      this.loading = false;
    });
  }

  submitOrder() {
    console.log('submit clicked');
    if (this.selectedRole === Constants.KIOSK) {
      this.tableName = this.currentUser.firstName;
    }
    const items = this.buildLineItems();

    if ((this.selectedRole === Constants.ADMIN || this.selectedRole === Constants.MANAGER || this.selectedRole === Constants.SERVER || this.selectedRole === Constants.TABLE || ((this.selectedRole === Constants.QR_ORDER || this.selectedRole === Constants.QR_ORDER) && localStorage.getItem('orderType') === Constants.DINE_IN)) && !this.currentUser.takePayment.devicePaymentEnabled) {
      if(this.orderId) {
        this.squareApi.getOrderById(this.orderId).subscribe(oldOrder => {
          if (oldOrder) {
            oldOrder.line_items = items;
            this.squareApi.updateOrder(oldOrder).subscribe(updatedOrder => {
              this.routeToNextComponent();
            })
          } else {
            this.createOrderAndReservationWithoutPayment();
          }
        })
      } else {
        this.createOrderAndReservationWithoutPayment();
      }
    }  else if (this.currentUser.takePayment.cloverConnectorPayment) {
      this.squareApi.createOrder(this.buildOrder()).subscribe((response: any) => {
        this.cartService.orderId = response.id;
        this.openSquarePaymentDialog('Finish Payment on Connected Device', 'Please finish payment on connected terminal to complete order.', '', response.id, this.calculatedOrder.total_money, response.source.name );
      }, err => {
        this.alertService.error(err);
      });
    } else {
      this.createNewOrderWithPaymentLink(items);
    }
  }

  buildOrder(): Order {
    let lineItems = [];
    if (this.existingOrder) {
      lineItems = this.existingOrder.line_items.map(lineItem => ({
        uid: lineItem.uid,
        'catalog_object_id': lineItem.catalog_object_id,
        quantity: lineItem.quantity,
        note: lineItem.note,
        modifiers: lineItem.modifiers?.map(mod => ({'catalog_object_id': mod.catalog_object_id})),
        metadata: {'existing': 'true'}
      }));
    }

    this.cartItems.forEach(cartItem => lineItems.push(new LineItem(cartItem)));

    return new Order(this.tableName, this.locationId, lineItems);
  }

  buildUpdateOrder(): Order {
    return {
      id: this.existingOrder.id,
      version: this.existingOrder.version,
      line_items: this.cartItems.map(cartItem => new LineItem(cartItem))
    } as Order;
  }

  buildLineItems() {
    const lineItems = [];

    this.cartItems.forEach(cartItem => lineItems.push(new LineItem(cartItem)));

    return lineItems;
  }

  orderPlaced() {
    this.alertService.success('Order Placed', true);
    this.cartService.removeAllFromCart();

    if (this.selectedRole === 'Table') {
      this.router.navigate(['/sidebar/table/orderHistory']);
    } else if (this.selectedRole === 'Admin' || this.selectedRole === 'Manager') {
      this.router.navigate(['sidebar/admin/dashboard']);
    } else if (this.selectedRole === 'Kiosk') {
      if (this.currentUser.takePayment) {
        this.router.navigate(['payment/addTip',
          {
            tableUuid: this.tableUuid,
            tableName: this.tableName,
          }]);
      } else {
        this.router.navigate(['/kiosk']);
      }
    }
  }

  ngOnDestroy() {
    this.cartItemsSubscription?.unsubscribe();
  }

  openPaymentRequiredDialog(title: string, message: string, qrUrl: string, payLinkId: string) {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      payLinkId: payLinkId,
      message: message,
      title: title,
      qrUrl: qrUrl
    };

    const dialogRef = this.dialog.open(NotificationPaymentRequiredPopupComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {
      this.loadingButton = false;
    });
  }

  openSquarePaymentDialog(title: string, message: string, imgSrc: string, orderId: string, orderTotal: SquareMoney, sourceName: string) {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      orderId: orderId,
      message: message,
      title: title,
      sourceName:sourceName,
      imgSrc: imgSrc,
      orderTotal: orderTotal
    };

    const dialogRef = this.dialog.open(SquarePaymentPopupComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {
      this.loadingButton = false;
    });
  }
  lockTableWithReservation(){
    let reservation = new Reservation();
    reservation.assignedEmployeeIds = [this.currentUser._id];
    reservation.assignedEmployeeUsernames = [this.currentUser.username];
    reservation.createdEmployeeId = this.currentUser._id;
    reservation.name = this.tableName +'Assigned To: ' +this.currentUser.firstName ? this.currentUser.firstName : this.currentUser.username;
    reservation.orgUuid = this.currentUser.orgSettings.orgUuid;
    reservation.tableLock = true;
    reservation.tableName = this.tableName;
    reservation.tableId = this.tableUuid;
    reservation.time = Date.now();
    reservation.orderIds = [this.orderId];

    this.tableUuid = '';
    this.tableName = '';

    if(this.selectedRole === "Table") {
      reservation.name = 'Open Order On Table';
      reservation.assignedEmployeeIds = [];
      reservation.tableName = this.currentUser.firstName;
      reservation.tableId =  this.currentUser._id;
    } else if (this.selectedRole === Constants.KIOSK || this.selectedRole === Constants.QR_ORDER) {
      reservation.name = 'Open Order On Table';
      reservation.assignedEmployeeIds = [];
    }
    this.reservationService.lockTableToOrder(reservation).subscribe(response =>{
      this.routeToNextComponent();
    }, error => {
      this.routeToNextComponent();
    })
  }

  private createOrderAndReservationWithoutPayment() {
    this.squareApi.createOrder(this.buildOrder()).subscribe((response: any) => {
      this.cartService.orderId = response.id;
      this.orderId = response.id;
      this.lockTableWithReservation()
    }, err => {
      this.alertService.error(err);
    });
  }


  private createNewOrderWithPaymentLink(items: any[]) {
    const paymentPayload = new PaymentPayload();
    paymentPayload.lineItems = items;
    paymentPayload.location_id = this.currentUser.locationId;
    paymentPayload.allow_tipping = true;
    paymentPayload.apple_pay = true;
    paymentPayload.google_pay = true;
    paymentPayload.order = this.buildOrder();
    paymentPayload.cash_app_pay = true;
    paymentPayload.description = 'Payment for Order';
    paymentPayload.currency = this.currentUser.orgSettings.currency_code ? this.currentUser.orgSettings.currency_code : 'USD';
    paymentPayload.note = 'DineNGo' + this.currentUser.username;
    paymentPayload.idempotencyKey = uuid.v4();
    if (!this.currentUser.role.includes('Kiosk') && !this.currentUser.role.includes(Constants.QR_ORDER)) {
      paymentPayload.createOpenOrder = true;
      paymentPayload.username = this.currentUser.username;
    }
    // paymentPayload.redirect_url = 'https://www.google.com';

    this.squarePaymentApi.createPaymentLink(paymentPayload).subscribe(response => {
      this.cartService.orderId = response.payment_link.order_id;
        this.openPaymentRequiredDialog('Scan to Pay and Finish Order', '', response.payment_link.url, response.payment_link.id);
    });
  }

  private routeToNextComponent() {
    this.cartItems = [];
    this.existingOrder = null;
    this.calculatedOrder = null;
    this.cartService.removeAllFromCart();
    if (this.selectedRole === Constants.ADMIN || this.selectedRole === Constants.MANAGER || this.selectedRole === Constants.SERVER) {
      this.router.navigate(['/sidebar/admin/waiterMenu/serverOrder']);
    } else if (this.selectedRole === Constants.TABLE) {
      localStorage.setItem('CartItem', this._AESEncryptDecryptService.encrypt(JSON.stringify([])));
      this.router.navigate(['/sidebar/order/orderConfirmation', {
        orderId: this.orderId
      }]);
    } else if (this.selectedRole === Constants.KIOSK) {
      localStorage.setItem('CartItem', this._AESEncryptDecryptService.encrypt(JSON.stringify([])));
      this.router.navigate(['order/orderConfirmation', {
        orderId: this.orderId
      }]);
    }else if (this.selectedRole === Constants.QR_ORDER) {
      if (this.currentUser.takePayment.devicePaymentEnabled && this.currentUser.takePayment.cloverConnectorPayment) {
        throw console.error('CLOVER CONNECTED PAYMENT IS DISABLED FOR QR ORDERS');
      } else {
          localStorage.setItem('CartItem', this._AESEncryptDecryptService.encrypt(JSON.stringify([])));
          this.router.navigate(['order/orderConfirmation', {
            orderId: this.orderId
          }]);
        }
    }
  }

  isOrderType(orderType) {
    if (localStorage.getItem('orderType') === orderType) {
      return true;
    } else {
      return false;
    }
  }
}

import {Component, OnInit} from '@angular/core';
import {AESEncryptDecryptService} from '../../_services/aesencrypt-decrypt.service';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {User} from '../../_models';
import { Constants } from 'src/app/constants/constants';
import {UtilsService} from '../../_services/utils.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
    imageObject: Array<object> = [
        {
            image: '../../../../assets/dinengo-annotated/order-annotated.jpeg',
            thumbImage: '../../../../assets/dinengo-annotated/order-annotated.jpeg',
        },
        {
            image: '../../../../assets/dinengo-annotated/games-annotated.jpeg',
            thumbImage: '../../../../assets/dinengo-annotated/games-annotated.jpeg',
        },
        {
            image: '../../../../assets/dinengo-annotated/table-layout-annotated.jpeg',
            thumbImage: '../../../../assets/dinengo-annotated/table-layout-annotated.jpeg',
        },
        {
            image: '../../../../assets/dinengo-annotated/games-annotated.jpeg',
            thumbImage: '../../../../assets/dinengo-annotated/rewards.jpg',
        },
    ];

    testimonials: any = [
        {
            name: 'Snowflake Tea House',
            imageUrl: 'https://drivenpayments.com/wp-content/uploads/2024/08/snowflake-logo-1.webp',
            testimonial: 'I was looking for a way to add a kiosk to my restaurant and was surprises by the high overhead costs of the kiosk. ' +
                'That is until I came across DineNGo. I signed up, contacted support for free setup and I was up and running in less than a weeks.' +
                ' Low the rates & the kiosk.'
        },
        {
            name: 'Sultana Lounge Cafe',
            imageUrl: 'https://drivenpayments.com/wp-content/uploads/2024/08/sultana-logo.webp',
            testimonial: 'We chose this app because it offered a table ordering solution with games. ' +
                'Being a lounge, most of our customers love board games and card games while they enjoy their hookah or wait for their food.' +
                ' Switched to them for payment processing and love the low rates!'
        },
        {
            name: 'Murray In A Hurry',
            imageUrl: 'https://drivenpayments.com/wp-content/uploads/2024/08/murrays-logoi.webp',
            testimonial: 'The software has a lot of features but the support team works to get you setup and running for free.' +
                ' If you a kiosk for your restaurant, I would highly recommend DineNGo.'
        },
        {
            name: 'MAZAJ RESTO CAFE',
            imageUrl: 'https://drivenpayments.com/wp-content/uploads/2024/08/murrays-logoi.webp',
            testimonial: 'Let me tell you this is a great all in one app for restaurant owners.\n\nPower outage or clover device down,' +
                ' this app can be used with hotspot on any device to manage orders and collect payment which saved us on a lot of occasions.' +
                '\n\nWe have our servers use their phones and tablets for ordering and the order prints automatically without having to fire' +
                ' print which saves us a lot of time.'
        }

    ];
    selectedTestimonial = this.testimonials[0];
    currentUser: User;
    Constants: Constants;

    constructor(private _AESEncryptDecryptService: AESEncryptDecryptService,
                private location: Location,
                private router: Router, private utilsService: UtilsService) {
        this.currentUser = utilsService.getCurrentUser();
    }

    ngOnInit(): void {
        this.setTestimonialScroll();
        this.performUrlParamsInit();
    }

    private performUrlParamsInit() {
        const url_string = window.location.href;
        const url = new URL(url_string);

        this.deleteQueryParameterFromCurrentRoute();

        if (((url.searchParams.get('email') || url.searchParams.get('username')) && url.searchParams.get('password')) ||( Boolean(url.searchParams.get('mobile')) === true && this.currentUser?.role)) {
            this.router.navigate(['/home'], {queryParamsHandling: 'preserve'});
        } else {
            this.deleteQueryParameterFromCurrentRoute();
        }
    }

    setTestimonialScroll() {
        let currentIndex = this.testimonials.findIndex((testimonial) => testimonial.name === this.selectedTestimonial.name);
        setTimeout(() => {
            if (currentIndex === this.testimonials.length - 1) {
                currentIndex = 0;
                this.selectedTestimonial = this.testimonials[currentIndex];
                this.setTestimonialScroll();
            } else {
                currentIndex++;
                this.selectedTestimonial = this.testimonials[currentIndex];
                this.setTestimonialScroll();
            }
        }, 5000);
    }

    deleteQueryParameterFromCurrentRoute() {
        // this.location.replaceState('./order');
        this.location.replaceState(window.location.href.split('?')[0].split(window.location.href.split('/')[2])[1]);
    }
}

import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import { Order } from '../_models/order';
import { CartItem } from './cartItem';

@Injectable()
export class CartServiceSquare {
  private lineItemsInCartSubject: BehaviorSubject<CartItem[]> = new BehaviorSubject([]);
  private lineItemsInCart: CartItem[] = [];
  orderId: string; //used for terminal pay link id
  oldTableOrder: Order; //used for table previous order.

  constructor() {
    this.lineItemsInCartSubject.subscribe(_ => this.lineItemsInCart = _);
  }

  public addToCart(cartItem: CartItem) {
    this.lineItemsInCartSubject.next([...this.lineItemsInCart, cartItem]);
  }

  public cloneItem(uid: string) {
    const cartItem: CartItem = this.lineItemsInCart.find(li => li.uid === uid);
    this.addToCart(new CartItem(cartItem.item, cartItem.selectedModifiers, cartItem.note, cartItem.quantity));
  }

  public getItems(): Observable<CartItem[]> {
    return this.lineItemsInCartSubject;
  }

  public removeFromCart(uid: string) {
    this.lineItemsInCartSubject.next(this.lineItemsInCart.filter(_ => _.uid !== uid));
  }

  public removeAllFromCart() {
    this.lineItemsInCart = [];
    this.lineItemsInCartSubject.next([...this.lineItemsInCart]);
  }

}


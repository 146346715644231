<div class="navigation-details-panel dark-orange-color" >
  <div><i class="back-icon"
          class="material-icons arrow-back-icon" routerLink="/home">arrow_back</i></div>
  <h2>Register</h2>
  <div><i class="back-icon"
          class="material-icons arrow-back-icon" routerLink="/home"></i></div>
</div>
<div class="register-page-container">
  <div class="form-container form-container-no-padding">
    <alert></alert>
    <div class="logo-image-container">
      <img (click)="selectImage.click()" class="profile-image-logo" [src]="profileImage ? profileImage : './assets/noImageAvailable.png'">
    </div>
    <div class="upload-btn-wrapper">
<!--      <button class="btn-upload">{{ 'Add Logo' | translate }}</button>-->
      <input #selectImage type="file" name="myfile" id="myFile" accept="image/*" (change)="getImageUrl()"/>
    </div>
    <div class="upload-btn-wrapper platform-buttons-container">
      <label class="platform-title">Platform:</label>
      <mat-radio-group [(ngModel)]="platform">
        <mat-radio-button class="platform-value" value="Clover">
          <span class="platform-label">Clover</span>
        </mat-radio-button>
        <mat-radio-button class="platform-value" value="Square">
          <span class="platform-label">Square</span>
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">

      <div class="name-form-container">
        <mat-form-field  appearance="outline" class="white-backgrounded-field example-full-width ">
          <input matInput placeholder="First Name" formControlName="firstName"
                 [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }">
          <mat-error *ngIf="f.firstName.errors">
            First Name is <strong>required</strong>
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="white-backgrounded-field example-full-width">
          <input matInput placeholder="Last Name" formControlName="lastName"
                 [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }">
          <mat-error *ngIf="f.lastName.errors">
            Last Name is <strong>required</strong>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="name-form-container">
        <mat-form-field appearance="outline" class="white-backgrounded-field example-full-width">
          <input matInput placeholder="Organization Name" formControlName="organization"
                 [ngClass]="{ 'is-invalid': submitted && f.organization.errors }">
          <mat-error *ngIf="f.organization.errors">
            Organization Name is <strong>required</strong>
          </mat-error>
        </mat-form-field>

        <div appearance="outline" class="uniqueOrgIdent">
          <input matInput placeholder="Abbreviation of business. Examples: dss (login kiosk@dss.com) " (keydown)="onlyAlphabets($event)" (keydown.space)="$event.preventDefault()" pattern="[A-Za-z]" (change)="uniqueOrgChange()" [ngModelOptions]="{standalone: true}" [(ngModel)]="uniqueOrgIdent"
                 [ngClass]="{ 'is-invalid': uniqueOrgError }">
          <mat-error class="error-uniqueOrg" *ngIf="uniqueOrgError">
            Abbreviation of the organization Name is <strong>required.</strong>
            Examples: dss, cfa, murrayslilburn, etc.
          </mat-error>
        </div>
      </div>

      <mat-form-field appearance="outline" class="white-backgrounded-field example-full-width">
        <input matInput placeholder="Phone Number" matInput id="phoneNum" type="number" pattern="[0-9]*" formControlName="phoneNumber"
               [ngClass]="{ 'is-invalid': submitted && f.phoneNumber.errors }">
        <mat-error *ngIf="f.phoneNumber.errors">
          Phone Number is <strong>required</strong>
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="white-backgrounded-field example-full-width">
        <input matInput placeholder="Email" formControlName="username"
               [ngClass]="{ 'is-invalid': submitted && f.username.errors }">
        <mat-error *ngIf="f.username.errors">
          Email is <strong>required</strong>
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="white-backgrounded-field example-full-width">
        <input matInput type="password" placeholder="Password" formControlName="password"
               [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
        <mat-error *ngIf="f.password.errors">
          Password is <strong>required</strong>
        </mat-error>
      </mat-form-field>
      <div class="form-group">
        <button mdbBtn color="danger" class="my-4 button-font" [routerLink]="['']">Cancel</button>
        <button mdbBtn color="info" block="true" class="my-4 button-font" type="submit" [disabled]="loading">Register
          <img *ngIf="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="/></button>
      </div>
    </form>

  </div>
</div>

import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CloverOrderService} from '../../../_services/cloverOrder.service';
import {User} from '../../../_models';
import {AESEncryptDecryptService} from '../../../_services/aesencrypt-decrypt.service';
import {AlertService, UserService} from '../../../_services';
import {Router} from '@angular/router';
import {Constants} from '../../../constants/constants';
import {QrDeviceService} from '../../../_services/qrDevice.service';
import {QRModel} from '../../../_models/qrDevice';
import {environment} from '../../../../environments/environment';
import {CloverApiService} from '../../../_services/cloverApi.service';
import {Category} from '../../../_models/category';
import {IDropdownSettings} from 'ng-multiselect-dropdown';
import {FormControl, Validators} from '@angular/forms';
import {TakePayment} from '../../../_models/takePayment';
import {QRCodeComponent} from 'angularx-qrcode';
import {UtilsService} from '../../../_services/utils.service';

@Component({
    selector: 'app-notification-popup',
    templateUrl: './QR-code-login-popup.html',
    styleUrls: ['./QR-code-login-popup.component.scss']
})
export class QRCodeLoginPopupComponent implements OnInit {

    @ViewChild('qrcode', {static : true}) qrcode: QRCodeComponent;

    username = new FormControl('', [Validators.required]);

    description: string;
    message: string;
    currentUser: User;
    currentTableName: string;
    errorMessage: string;
    selectedRole: string;
    paymentEnabled = false;
    loading = false;
    assignedTables = false;
    inputValue = '';
    table: User;
    button_text = 'Submit';
    popupType = 'qr';
    siteURL = `https://dinengo.net`;
    siteAutoLoginURL = `https://dinengo.net/login?`;
    websiteUrl: string;
    qrUrl = `https://dinengo.net/cloverAuth/cloverAuth?qr=`;
    tableUsername = '';
    tablePassword = Math.random().toString(36).slice(-8);
    cloverCategories: Category[];
    excludedCategories: any[] = [];
    selectedCategories: any[] = [];

    excludedDropdownSettings: IDropdownSettings = {
        singleSelection: false,
        idField: 'name',
        textField: 'name',
        itemsShowLimit: 2,
        allowSearchFilter: true,
        enableCheckAll: false
    };

    constructor(private dialogRef: MatDialogRef<QRCodeLoginPopupComponent>, private cloverOrderService: CloverOrderService,
                private alertService: AlertService, private userService: UserService, private router: Router,
                private cloverAPIService: CloverApiService, private utilsService: UtilsService, private qrService: QrDeviceService,
                private _AESEncryptDecryptService: AESEncryptDecryptService,
                @Inject(MAT_DIALOG_DATA) data) {
        this.description = data.title;
        this.message = data.message;
        this.button_text = data.button_text;
        this.currentTableName = data.currentTableName;
        this.currentUser = utilsService.getCurrentUser();
        this.selectedRole = this.currentUser?.selectedRole;
        this.excludedCategories = this.currentUser.excludedCategories.length > 0 ? this.currentUser.excludedCategories : [];
        this.tableUsername = 'qr' + this.currentUser.orgSettings.uniqueOrgIdent;
        if (!environment.production) {
            this.siteURL = `https://dine-n-go-dev.herokuapp.com`;
            this.siteAutoLoginURL = 'https://dine-n-go-dev.herokuapp.com/login?';
            this.qrUrl = `https://dine-n-go-dev.herokuapp.com/cloverAuth/cloverAuth?qr=`;
        }
    }
    readonly Constants = Constants;

    ngOnInit(): void {
        if (this.currentUser.orgSettings.accountType === Constants.CLOVER) {
            this.loadAllCategories();
        }
        this.generateQrCode();
    }

    private loadAllCategories() {
        this.cloverAPIService.getAllCategories().subscribe(category => {
            this.cloverCategories = category;
            this.loading = false;
            this.selectedCategories = this.getSelectedCategoriesList(this.excludedCategories ? this.excludedCategories : [], this.cloverCategories);
            this.excludedCategories = this.getSelectedCategoriesList(this.excludedCategories ? this.excludedCategories : [], this.cloverCategories).map( cloverCategory =>  cloverCategory.name);
        });
    }

    generateQrCode(update?, closeDialog?): void {
        this.loading = true;
        this.currentUser = this.utilsService.getCurrentUser();

        const qrDevice = new User();
        qrDevice.uuid = this.currentUser.uuid;
        qrDevice.username = 'qr' + this.currentUser.orgSettings.uniqueOrgIdent;
        qrDevice.orgSettings = this.currentUser.orgSettings;
        qrDevice.orgUuid = this.currentUser.orgSettings.orgUuid;

        const qrData = new QRModel();
        qrData.username = 'qr' + this.currentUser.orgSettings.uniqueOrgIdent;
        qrData.orgUuid = qrDevice.orgUuid;
        qrData.attachedUserId = this.currentUser._id;
        qrData.merchantId = this.currentUser.merchantId;
        qrData.accountType = this.currentUser.orgSettings.accountType;
        qrData.role = Constants.QR_ORDER;
        qrData.excludedCategories = this.excludedCategories;

        const takePayment = new TakePayment();
        takePayment.cloverConnectorPayment = false;
        takePayment.scanToPayPayment = false;
        takePayment.paymentRequired = this.paymentEnabled;
        takePayment.devicePaymentEnabled = this.paymentEnabled;
        takePayment.creditCardPayment = this.paymentEnabled;
        qrData.takePayment = takePayment;
        qrData.update = update;

        this.qrService.getOrCreateQrDevice(qrData, this.tablePassword).subscribe(response => {
                this.loading = false;
                this.tableUsername = response.username;
                this.siteAutoLoginURL = this.siteAutoLoginURL + 'email=' + this.tableUsername + '&password=' + this.tablePassword;
                this.paymentEnabled = response.takePayment?.paymentRequired;
                this.excludedCategories = response.excludedCategories;
                this.qrUrl =  `${environment.qrOrderURL}qr=`
                    + encodeURIComponent(this._AESEncryptDecryptService.encrypt(qrData.username));
                this.websiteUrl = 'https://' + this.currentUser.orgSettings.uniqueOrgIdent.substring(this.currentUser.orgSettings.uniqueOrgIdent.indexOf('@') + 1, this.currentUser.orgSettings.uniqueOrgIdent.indexOf('.com')) + `${environment.websiteSubdomainUrl}`; // this.qrUrl + '&website=true';

                if (closeDialog) {
                    this.popupType = 'qr';
                    // this.dialogRef.close();
                }
            },
            error => {
                throw new Error(error);
                this.loading = false;
            });
}
    getSelectedCategoriesList(currentCategoriesList: any, optionsList: any) {
        const selectedOptions = [];
        currentCategoriesList?.map((name) => {
            const x = optionsList.filter((option: any, ind: number) => option.name === name);
            selectedOptions.push(...x);
        });
        return selectedOptions;
    }


    onExcludeCategorySelect(item: any) {
        const selectedItem = this.cloverCategories.filter((value) => value.name === item.name)[0];
        if (!this.excludedCategories.includes(selectedItem.name)) {
            this.excludedCategories.push(selectedItem.name.toString());
        }
    }

    onExcludeCategoryDeSelect(item: any) {
        const exists = this.excludedCategories.includes(item.name);
        if (exists) {
            this.excludedCategories = this.excludedCategories.filter((name: string) => name !== item.name);
        }
    }

    submit() {
        this.dialogRef.close();
    }

    goToLink(url: string) {
        window.open(url, '_blank');
    }

    // get getItems() {
    getItems() {
        // return this.dropdownList.reduce((acc, curr) => {
        //     acc[curr.item_id] = curr;
        //     return acc;
        // }, {});
        // TODO: see what code above does
     }
    saveAsImage(parent) {
        let parentElement = null;

            // fetches base 64 data from canvas
            parentElement = parent.qrcElement.nativeElement
                .querySelector('canvas')
                .toDataURL('image/png');

        if (parentElement) {
            // converts base 64 encoded image to blobData
            const blobData = this.convertBase64ToBlob(parentElement);
            // saves as image
            const blob = new Blob([blobData], { type: 'image/png' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            // name of the file
            link.download = 'DineNGo QR Code';
            link.click();
        }
    }

    private convertBase64ToBlob(Base64Image: string) {
        // split into two parts
        const parts = Base64Image.split(';base64,');
        // hold the content type
        const imageType = parts[0].split(':')[1];
        // decode base64 string
        const decodedData = window.atob(parts[1]);
        // create unit8array of size same as row data length
        const uInt8Array = new Uint8Array(decodedData.length);
        // insert all character code into uint8array
        for (let i = 0; i < decodedData.length; ++i) {
            uInt8Array[i] = decodedData.charCodeAt(i);
        }
        // return blob image after conversion
        return new Blob([uInt8Array], { type: imageType });
    }

}

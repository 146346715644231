<!--<h2 mat-dialog-title  class="title-header">{{description}}</h2>-->
<mat-dialog-content class="popover-container">
    <div class="dialog-options-container">
        <mat-button-toggle-group [(ngModel)]="popupType" aria-label="Font Style">
            <mat-button-toggle value="website">Website Ordering</mat-button-toggle>
            <mat-button-toggle value="qr">QR Code</mat-button-toggle>
            <mat-button-toggle value="settings">Settings</mat-button-toggle>
        </mat-button-toggle-group>
<!--      <p>Chosen value is {{popupType}}</p>-->
        <div class="error-message">{{errorMessage}}</div>
    </div>
    <div class="text-container" *ngIf="popupType === 'settings'">
        <form class="example-form">
            <div class="select-container">
             <mat-slide-toggle [ngModelOptions]="{standalone: true}" [(ngModel)]="paymentEnabled">Payment Enabled: {{paymentEnabled}}</mat-slide-toggle>
            </div>
            <div class="toggle-selector">
                <ng-multiselect-dropdown
                        [placeholder]="'Excluded Categories'"
                        [settings]="excludedDropdownSettings"
                        [data]="cloverCategories"
                        [ngModelOptions]="{standalone: true}"
                        [(ngModel)]='selectedCategories'
                        (onDeSelect)="onExcludeCategoryDeSelect($event)"
                        (onSelect)="onExcludeCategorySelect($event)"
                        multiple>
                    <ng-template #optionsTemplate let-item let-option="option" let-id="id"
                                 let-isSelected="isSelected">
                        <!--                    {{getItems[id].item_text}}-->
                    </ng-template>
                </ng-multiselect-dropdown>
            </div>
            <div class="note-text"><span class="note-label">Note:</span> To Enable and Disable Rewards, Change it under Organization Settings by tapping the settings icon in the sidebar menu.</div>
            <br>
            <div class="note-text"><span class="note-label">Note:</span> Payment is enabled by default for website login.</div>
        </form>
    </div>
    <div class="text-container-qr" *ngIf="popupType === 'qr'">
        <qrcode #qrcode
                [qrdata]="qrUrl"
                [allowEmptyString]="true"
                [attr.ariaLabel]="'QR Code image with the following content...'"
                [cssClass]="'center'"
                [colorDark]="'#000000ff'"
                [colorLight]="'#ffffffff'"
                [elementType]="'canvas'"
                [errorCorrectionLevel]="'L'"
                [attr.imageSrc]="currentUser.orgSettings.image ? currentUser.orgSettings.image : './assets/default-logo.png'"
                [attr.imageHeight]="75"
                [attr.imageWidth]="75"
                [margin]="4"
                [scale]="1"
                [title]="'Scan To Order'"
                [width]="250"
        ></qrcode>
        <div class="download-button-container">
        <button mdbBtn [disabled]="loading"  *ngIf="popupType === 'qr'" block="true" class="download-button" (click)="saveAsImage(qrcode)">Download QR</button>
        </div>
        <div class="hr">
            <span> OR </span>
        </div>
        <br><br>
        <h1 class="header-title">Show QR Code On A Device</h1>
        <div class="creds-container">
            <div class="general-padding">Auto login URL: <span class="link creds" (click)="goToLink(siteAutoLoginURL)">{{siteAutoLoginURL}}</span></div>
            <div class="general-padding">URL: <span class="link creds" (click)="goToLink(siteURL)">{{siteURL}}</span></div>
            <div class="general-padding">Username: <span class="creds">{{tableUsername}}</span> </div>
            <div class="general-padding">One Time Password: <span class="creds"> &nbsp; {{tablePassword}} &nbsp; &nbsp; <b style="font-weight: bold;">OR</b>  &nbsp; &nbsp; Admin's Password</span>
                &nbsp; &nbsp;
            </div>
        </div>
    </div>
    <div class="text-container-qr" *ngIf="popupType === 'website'">
        <p class="website-instructions"><span class="instructions-header">Instructions: </span>To add ordering through your website, add the link to the order button on your site. Upon clicking the link below, customers will be routed to the ordering screen. </p>
        <div class="creds-container">
            <div class="general-padding">Website URL: <span class="link creds" (click)="goToLink(websiteUrl)">{{websiteUrl}}</span></div>
        </div>
    </div>
</mat-dialog-content>
  <div class="button-container">
    <button mdbBtn [disabled]="loading" *ngIf="popupType !== 'settings'" color="danger" block="true" class="my-4 action-button" (click)="submit()">{{button_text}}</button>
    <button mdbBtn [disabled]="loading"  *ngIf="popupType === 'settings'" block="true" class="my-4 action-button orange-button" (click)="generateQrCode(true, true)">Update QR</button>
  </div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {SharedModule} from '../../../shared/shared.module';
import {PaymentUserComponent} from './payment-user.component';
import {AuthGuard} from '../../../_guards';

const routes: Routes = [
  // {
  //   path: '',
  //   component: PaymentUserComponent
  // }
  {
    path: 'paymentUser',
    component: PaymentUserComponent,
    canActivate: [AuthGuard]
  },
];


@NgModule({
  declarations: [PaymentUserComponent],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(routes)
  ]
})
export class PaymenUserModule { }

<div class="loader-container" *ngIf="loading">
    <img class="loading-gif" src="../assets/Coffeeloader.svg" alt="loading">
</div>
<div class="dialog-container" *ngIf="!loading">
    <div class="dialog-header" [ngClass]="{'header-container' : modifierGroups[0]?.modifiers.length > 0}">
        <div (click)="close()" class="dialog-close-button-container">
            <span class="material-icons dialog-close-button">cancel</span>
        </div>
        <div class="inline-flex" [ngClass]="{'display-block' : smallScreen}">
            <div class="img-qty-container">
                <img class="item-image" [src]="item.imageData ? item.imageData : '../../../../assets/kiosk-background-6.png'">
                <div class="qty-selector-container">
                    <div class="form-check-inline">
                        <button class="btn qty-selector-button-disabled" (click)="minus()"><i
                                class="material-icons">remove</i></button>
                    </div>
                    <div class="form-check-inline">
                        <input type="text" class="form-control" [(ngModel)]="quantity">
                    </div>
                    <div class="form-check-inline">
                        <button class="btn qty-selector-button-enabled" (click)="plus()"><i
                                class="material-icons">add</i>
                        </button>
                    </div>
                </div>
            </div>
            <br *ngIf="smallScreen">
            <div class="info-container">
                <h2 mat-dialog-title>{{description}}</h2>
                <mat-error *ngIf="selectionError">Please select the required options</mat-error>
                <div class="selectors" *ngIf="modifierGroups.length > 0">
                    <div class="square-unselected" [ngClass]="{'background-white': smallScreen}"></div>
                    <span class="pics"> NOT Selected &nbsp;&nbsp;  </span>
                    <div class="square-selected"></div>
                    <span class="pics"> Selected </span>
                </div>
            </div>
        </div>
    </div>
    <br>
    <mat-dialog-content [ngClass]="{'dialog-light-orange-background' : true}">

        <br>
        <div class="loader-container" *ngIf="modifiers_loading">
            <mat-spinner class="spinner-loader" diameter="20" *ngIf="modifiers_loading"></mat-spinner>
        </div>
        <div *ngIf="!modifiers_loading">
            <div *ngFor="let modifierGroup of modifierGroups; let i = index" class="col-width mb-3 ml-4">
                <h3 class="header1">{{modifierGroup.name}}
                    <span class="minMax" *ngIf="modifierGroup.minRequired > 0">(Min {{modifierGroup.minRequired}}
                        )</span>
                    <span class="minMax" *ngIf="modifierGroup.maxAllowed > 0">
        (Max {{modifierGroup.maxAllowed}})</span>
                </h3><br>
                <div class="row options-container">

                    <div *ngFor="let modifier of modifierGroup.modifiers" class="modifier">
                        <mat-button-toggle #toggleBtn class="checkbox-content row" [attr.data-cy]="modifier.name"
                                           class=".mat-button-toggle"
                                           (change)="onItemSelect(i, modifier, $event, modifierGroup.maxAllowed)"
                                           [ngClass]="{'background-white': true, 'disabled-background': selectedOptions.length >= modifierGroup.maxAllowed}">
                            <!-- [disabled]="modifierGroup.selected >= modifierGroup.maxAllowed && !modifier.checked"> -->
                            {{modifier.name}} &nbsp;&nbsp;&nbsp; + ${{(modifier.price / 100).toFixed(2)}}
                        </mat-button-toggle>
                    </div>
                </div>
            </div>
        </div>
        <br>
        <h3 class="header2">Custom Message</h3>
        <div class="dialog-options-container">
            <textarea id="custom-message-input"
                      style="width: 100%; height: 100px; border-radius: 15px; padding: 15px;"></textarea>
        </div>
    </mat-dialog-content>


    <mat-dialog-actions class="footer" align="end">
<!--        <div class="button-container"-->
<!--             *ngIf="this.selectedRole === Constants.TABLE || (!currentUser.receiptView || ((selectedRole !== Constants.KIOSK) && (!receiptViewPreferenceSet && (selectedRole !== 'Admin' && selectedRole !== 'Manager' && selectedRole !== Constants.SERVER))))"-->
<!--             id="addtoCart">-->
<!--            <button (click)="addToCart()" mdbBtn color="info" style="font-weight: bold" block="true"-->
<!--                    class="my-4 right-margin-set">{{ 'items.addMoreItems' | translate }}</button>-->
<!--            <button mdbBtn block="true" data-cy="checkout" class="my-4 add-to-cart-button"-->
<!--                    (click)="addAndRouteToCart()">{{ 'button.checkout' | translate }}</button>-->
<!--        </div>-->
        <div class="button-container"
             id="addtoCart"> <!--*ngIf="(currentUser.receiptView && (selectedRole === Constants.KIOSK || (selectedRole =='Admin' || (selectedRole =='Manager' || (selectedRole ==Constants.SERVER) && receiptViewPreferenceSet)))) || !(this.selectedRole === Constants.TABLE || (!currentUser.receiptView || ((selectedRole !== Constants.KIOSK) && (!receiptViewPreferenceSet && (selectedRole !== 'Admin' && selectedRole !== 'Manager' && selectedRole !== Constants.SERVER)))))"-->
            <button (click)="addToCart()" mdbBtn color="info" style="font-weight: bold" block="true"
                    class="my-4 right-margin-set">{{ 'items.addToCart' | translate }}</button>
        </div>
    </mat-dialog-actions>
</div>

import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import * as CryptoJS from 'crypto-js';
import {isNil} from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class AESEncryptDecryptService {

  secretKey = `${environment.cryptoSecret}`.toString();
  constructor() { }

  encrypt(value?: string): string {
    if (!isNil(value)) {
      return CryptoJS.AES.encrypt(value, this.secretKey.trim()).toString();
    }
  }

  decrypt(textToDecrypt?) {
    if (!isNil(textToDecrypt)) {
    return CryptoJS.AES.decrypt(textToDecrypt, this.secretKey.trim()).toString(CryptoJS.enc.Utf8);
  }
  }
}
